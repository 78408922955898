/** @jsxImportSource @emotion/react */
import { css } from '@emotion/react';
import { useFormikContext } from 'formik';

import Text from 'components/Text';
import { GRAY } from 'styles/colors';
import SaveDraft from 'screens/AddCampaign/HeaderCampaignInfo/SaveDraft';

const styles = css`
  box-sizing: border-box;
  display: flex;
  width: 100%;
  align-items: center;
  gap: 30px;

  .header {
    flex: 1 1 0;
    color: ${GRAY};
    text-align: left;
    margin: 0;
  }

  .mainTitle {
    margin: 0;
  }

  .mainTitleInfo {
    margin: 0.5rem 0 0 0;
  }
`;

const headingData = [
  {
    headingText: 'Tipo de campaña',
    headingInfoText:
      'Selecciona el tipo de beneficio que ofrecerás en esta Campaña.',
    tip: 'Recuerda que una oferta atractiva se traduce en más Feekers y mejores stories para tu negocio.',
  },
  {
    headingText: 'Número de asistentes',
    headingInfoText: 'Define la convocatoria de tu campaña.',
  },
  {
    headingText: 'Descripción de la campaña',
    headingInfoText:
      'Define un título para publicar tu campaña, y las condiciones que los Feekers deben cumplir para validarla.',
  },
  {
    headingText: 'Actividad de la campaña',
    headingInfoText:
      'Define la duración y horarios en que tu campaña estará disponible para los Feekers.',
  },
];
export default function HeaderCampaignInfo() {
  const { values } = useFormikContext();
  return (
    <header css={styles}>
      <div className="header">
        <Text className="mainTitle" fontSize={18} fontWeight="600">
          {headingData[values.step].headingText}
        </Text>
        <Text
          className="mainTitleInfo"
          fontSize={12}
          lineHeight={14.4}
        >
          {headingData[values.step].headingInfoText}
          {!!headingData[values.step].tip && (
            <>
              <br />
              <b>Tip: </b>
              {headingData[values.step].tip}
            </>
          )}
        </Text>
      </div>
      <SaveDraft
        values={values}
        isSaveDraftDisabled={values.step < 2 || !values.title}
      />
    </header>
  );
}
