/** @jsxImportSource @emotion/react */
import PropTypes from 'prop-types';
import { css } from '@emotion/react';
import { useEffect, useState } from 'react';
import { useNavigate } from 'react-router-dom';
import { useDispatch, useSelector } from 'react-redux';

import APIClient from 'redux/api';
import { ROUTES } from 'constants';
import Text from 'components/Text';
import Jcb from 'assets/images/Jcb.svg';
import Amex from 'assets/images/Amex.svg';
import Visa from 'assets/images/Visa.svg';
import Diners from 'assets/images/Diners.svg';
import Discover from 'assets/images/Discover.svg';
import Unionpay from 'assets/images/Unionpay.svg';
import { PURPLE_FEEK, WHITE } from 'styles/colors';
import { setCardByCustomer } from 'redux/entities';
import Mastercard from 'assets/images/Mastercard.svg';
import AccordionStatus from 'components/AccordionStatus';
import PrimaryButton from 'components/buttons/PrimaryButton';
import ModalPaymentMethod from 'components/AccordionProfilePaymentSettings/ModalPaymentMethod';

const style = css`
  .button {
    width: 100%;
    height: 30px;
    margin-top: 12px;
    padding: 8px;
    marginblock: 12px;
    border: 1px ${PURPLE_FEEK} solid;
    borderradius: 8px;
    margin-bottom: 12px;
  }
  .card-number {
    margin-top: 12px;
    display: flex;
    gap: 10px;
  }

  .label {
    line-height: 140%;
  }
`;

function AccordionProfilePaymentSettings({
  statusIcon = false,
  customer,
  className,
}) {
  const [showModalPaymentMethod, setShowModalPaymentMethod] =
    useState(false);
  const navigate = useNavigate();
  const dispatch = useDispatch();
  const selectedCustomerId = useSelector(
    (state) => state.app.selectedCustomerId,
  );
  const cards = useSelector((state) => state.entities.cards);
  const card = cards[selectedCustomerId];

  useEffect(() => {
    APIClient.getCustomerCard(selectedCustomerId)
      .then(({ data }) =>
        dispatch(
          setCardByCustomer({
            card: data,
            customerId: selectedCustomerId,
          }),
        ),
      )
      .catch((e) => console.log(e));
  }, [selectedCustomerId]);

  const onCreateCard = async (card) => {
    closePaymentModal();
    dispatch(
      setCardByCustomer({
        customerId: selectedCustomerId,
        card: card,
      }),
    );
  };

  const closePaymentModal = () => {
    setShowModalPaymentMethod(false);
  };

  const cardIcons = {
    visa: Visa,
    amex: Amex,
    discover: Discover,
    mastercard: Mastercard,
    diners: Diners,
    jcb: Jcb,
    unionpay: Unionpay,
  };

  return (
    <div css={style}>
      <ModalPaymentMethod
        show={showModalPaymentMethod}
        handleClose={closePaymentModal}
        onSave={onCreateCard}
        title="Agrega Campañas extra a tu cuenta."
      />

      <AccordionStatus
        status={statusIcon ? !!customer?.plan_id : undefined}
        title="Método de Pago y facturación"
        className={className}
        content={
          <>
            {card && card.last4 && (
              <>
                <Text fontWeight={600}>Método de Pago</Text>
                <div className="card-number">
                  <Text>•••• •••• •••• {card?.last4}</Text>
                  <img src={cardIcons[card.brand]}></img>
                </div>
              </>
            )}

            <PrimaryButton
              color={WHITE}
              textColor={PURPLE_FEEK}
              textProps={{ fontSize: 14, fontWeight: 400 }}
              label={'Editar Método de Pago'}
              className="button"
              onClick={(e) => {
                e.stopPropagation();
                setShowModalPaymentMethod(true);
              }}
            />
            <Text className="label" fontWeight={600}>
              Facturación
            </Text>
            {customer?.billing && (
              <>
                <Text className="label">
                  Rázon Social: {customer?.billing?.name}
                </Text>
                <Text className="label">
                  RFC: {customer?.billing?.rfc}
                </Text>
              </>
            )}

            <PrimaryButton
              color={WHITE}
              textColor={PURPLE_FEEK}
              textProps={{ fontSize: 14, fontWeight: 400 }}
              label={'Editar datos de Facturación'}
              onClick={() => navigate(ROUTES.BILLING)}
              className="button"
            />
          </>
        }
      />
    </div>
  );
}

AccordionProfilePaymentSettings.propTypes = {
  statusIcon: PropTypes.bool,
  customer: PropTypes.object,
  showAddOn: PropTypes.bool,
  className: PropTypes.string,
};

export default AccordionProfilePaymentSettings;
