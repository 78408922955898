/** @jsxImportSource @emotion/react */
import { useState } from 'react';
import PropTypes from 'prop-types';
import { css } from '@emotion/react';
import { Bar } from 'react-chartjs-2';
import {
  Legend,
  Tooltip,
  ArcElement,
  BarElement,
  LinearScale,
  CategoryScale,
  Chart as ChartJS,
} from 'chart.js';

import {
  GRAY,
  WHITE,
  GRAY_LIGHT,
  PURPLE_FEEK,
  PURPLE_ACTIVE_DARK,
} from 'styles/colors';
import Text from 'components/Text';
import Button from 'components/buttons/Button';
import { constants } from 'screens/Dashboard/constants';

const style = css`
  display: grid;
  grid-template-rows: 32px 1fr;
  padding: 25px 20px;
  gap: 14px;
  height: 100%;

  .filters {
    box-sizing: border-box;
    display: flex;
    align-items: center;
    justify-content: space-evenly;
    height: 32px;
    width: fit-content;
    padding: 5px;
    background-color: ${GRAY_LIGHT};
    border-radius: 4px;

    .button {
      display: flex;
      align-items: center;
      height: 100%;
      border-radius: 4px;
      padding: 0 15px;

      &.--selected {
        background-color: ${WHITE};
        & > * {
          font-weight: bold;
        }
      }
    }
  }

  .chart {
    position: relative;
  }
`;

ChartJS.register(
  Legend,
  Tooltip,
  ArcElement,
  BarElement,
  LinearScale,
  CategoryScale,
);

function DailyActivityChart({ data }) {
  const [type, setType] = useState('impressions');

  return (
    <div css={style}>
      <div className="filters">
        <Button
          onClick={() => setType('impressions')}
          className={`button ${
            type === 'impressions' ? '--selected' : ''
          }`}
        >
          <Text fontSize={12}>Impresiones</Text>
        </Button>
        <Button
          onClick={() => setType('stories')}
          className={`button ${
            type === 'stories' ? '--selected' : ''
          }`}
        >
          <Text fontSize={12}>Historias</Text>
        </Button>
      </div>
      <div className="chart">
        <Bar
          options={{
            maintainAspectRatio: false,
            font: { family: 'Roobert' },
            plugins: {
              tooltip: {
                backgroundColor: WHITE,
                displayColors: false,
                cornerRadius: 4,
                titleColor: GRAY,
                bodyColor: GRAY,
                bodyFont: { size: 12, family: 'Roobert' },
                titleFont: { size: 12, family: 'Roobert' },
                titleMarginBottom: 0,
                borderColor: GRAY,
                borderWidth: 1,
                callbacks: {
                  title: (tooltipItem) =>
                    tooltipItem[0].formattedValue +
                    (type === 'impressions'
                      ? ' impresiones'
                      : ' historias'),
                  label: (labelItem) =>
                    constants.daysOfTheWeek[labelItem.dataIndex],
                },
              },
              legend: {
                display: false,
              },
            },
          }}
          data={{
            labels: constants.daysOfTheWeek.map((day) =>
              day.substring(0, 3),
            ),
            datasets: [
              {
                data: Object.values(data?.[type] || []),
                backgroundColor: [PURPLE_ACTIVE_DARK],
                hoverBackgroundColor: [PURPLE_FEEK],
                borderRadius: {
                  topLeft: 4,
                  topRight: 4,
                },
                borderWidth: 0,
              },
            ],
          }}
        />
      </div>
    </div>
  );
}

DailyActivityChart.propTypes = {
  data: PropTypes.shape({
    stories: PropTypes.objectOf(PropTypes.number),
    impressions: PropTypes.objectOf(PropTypes.number),
  }),
};

export default DailyActivityChart;
