/** @jsxImportSource @emotion/react */
import { css } from '@emotion/react';
import { Link } from 'react-router-dom';

import { ROUTES } from 'constants';
import Text from 'components/Text';
import { GRAY } from 'styles/colors';

const styles = css`
  position: relative;
  flex: 1 0 auto;
  display: flex;
  flex-direction: column;
  justify-content: end;
  margin-block-start: 40px;

  .infoFooterInnerWrapper {
    position: sticky;
    bottom: 62px;

    .infoFooterLinksContainer {
      margin-block-start: 15px;
      display: flex;
      gap: 25px;

      .infoFooterLink {
        color: ${GRAY};
        font-size: 15px;
      }
    }
  }
`;

export default function FooterLegalTexts() {
  return (
    <footer css={styles}>
      <div className="infoFooterInnerWrapper">
        <Text
          fontSize={15}
          fontWeight="700"
          className="infoFooterHeading"
        >
          Información
        </Text>

        <div className="infoFooterLinksContainer">
          <Link
            className="infoFooterLink"
            to={ROUTES.TERMS_AND_CONDITIONS}
          >
            Términos y condiciones
          </Link>

          <Link className="infoFooterLink" to={ROUTES.POLICY}>
            Aviso de privacidad
          </Link>
        </div>
      </div>
    </footer>
  );
}
