/** @jsxImportSource @emotion/react */
import PropTypes from 'prop-types';
import { css } from '@emotion/react';

import {
  WHITE,
  BLACK,
  GRAY_DARK,
  GRAY_LIGHT,
  PURPLE_FEEK,
  GRADIENT_PURPLE,
} from 'styles/colors';
import Icon from 'components/Icon';
import Text from 'components/Text';
import { useSelector } from 'react-redux';
import { MEDIA_QUERIES } from 'styles/constants';
import { ReactComponent as Zap } from 'assets/icons/Zap.svg';
import { ReactComponent as Group } from 'assets/icons/Group.svg';
import { ReactComponent as Couple } from 'assets/icons/Couple.svg';
import { ReactComponent as Nightlife } from 'assets/icons/Nightlife.svg';
import { ReactComponent as GiftSmall } from 'assets/icons/GiftSmall.svg';
import { ReactComponent as MagicWand } from 'assets/icons/MagicWand.svg';
import { ReactComponent as Individual } from 'assets/icons/Individual.svg';
import { ReactComponent as ArrowDiscount } from 'assets/icons/ArrowDiscount.svg';
import { ReactComponent as ArrowPercentage } from 'assets/icons/ArrowPercentage.svg';

const icons = {
  'En grupo': Group,
  'En pareja': Couple,
  Individual: Individual,
  GIFT: GiftSmall,
  FULL_ACCOUNT: MagicWand,
  MONETARY_DISCOUNT: ArrowDiscount,
  DISCOUNT_PERCENTAGE: ArrowPercentage,
};

function CampaignPreviewCard({ values, className }) {
  const { image, title, type, participation } = values;
  const selectedCustomerId = useSelector(
    (state) => state.app.selectedCustomerId,
  );
  const customers = useSelector((state) => state.entities.customers);
  const selectedCustomer = customers[selectedCustomerId]?.customer;
  const campaignTypes = useSelector(
    (state) => state.entities,
  ).campaignTypes;

  const styles = css`
    border-radius: 15px;
    background-color: ${WHITE};
    box-sizing: border-box;
    margin: 0;
    padding: 0;
    box-shadow: 0px 0px 25px rgba(0, 0, 0, 0.15);
    aspect-ratio: 100/66;
    max-width: 420px;
    min-height: 200px;

    &:where(*) {
      box-sizing: border-box;
      margin: 0;
      padding: 0;
    }

    .campaignImage {
      min-height: 100px;
      border-radius: 15px 15px 0 0;
      ${image
        ? `background-image: url(${image});
          background-position: top;
          background-repeat: no-repeat;
          background-size: cover;`
        : `background-color: ${GRAY_LIGHT}`}
    }

    .campaignInfoContainer {
      display: flex;
      gap: 24px;
      padding: 15px;

      > div {
        display: flex;
        flex: 1 1 auto;
        flex-direction: column;
        justify-content: space-between;

        :first-of-type {
          align-items: center;
          flex-basis: 15%;
        }
        :nth-of-type(2) {
          flex-basis: 60%;
        }
        :last-of-type {
          flex-basis: min-content;
        }

        > div:last-of-type {
          margin-block-start: 20px;
        }
        div:not(:last-of-type) {
          padding-block-end: 5px;
        }
      }
    }

    .smallIcon {
      margin: 0 5px 0 0;
    }

    .bigIcon {
      display: flex;
      align-items: center;
      svg {
        margin: 0 9px 0 0;
      }
    }

    .businessName {
      text-transform: uppercase;
      letter-spacing: 0.2em;
    }

    .campaignTitle {
      overflow-wrap: anywhere;
    }

    .places {
      margin: 0;
      padding: 4px 14px;
      border-radius: 10px;
      background: ${GRADIENT_PURPLE};
    }

    ${MEDIA_QUERIES.mobile} {
      aspect-ratio: unset;
      max-width: unset;
      width: 100%;

      .campaignImage {
        min-height: 100px;
        aspect-ratio: 3/1;
      }
    }
  `;

  return (
    <article css={styles} className={className}>
      <div className="campaignImage"></div>
      <div className="campaignInfoContainer">
        <div>
          <Icon icon={Nightlife} color={PURPLE_FEEK} size={50} />
          <Text color={PURPLE_FEEK} fontSize={12} fontWeight="600">
            <Icon icon={Zap} color={PURPLE_FEEK} size={12} />
            300
          </Text>
        </div>
        <div>
          <div>
            {selectedCustomer?.name && (
              <Text
                color={GRAY_DARK}
                fontSize={12}
                fontWeight="400"
                className="businessName"
              >
                {selectedCustomer?.name}
              </Text>
            )}
            <Text
              fontSize={20}
              lineHeight={22}
              fontWeight="600"
              color={BLACK}
              className="campaignTitle"
            >
              {title}
            </Text>
          </div>
        </div>
        <div>
          <div>
            <Text
              color={GRAY_DARK}
              fontSize={12}
              fontWeight="600"
              className="bigIcon"
            >
              <Icon
                icon={icons[participation] || Couple}
                color={GRAY_DARK}
                size={20}
              />
              {participation}
            </Text>
            <Text
              color={GRAY_DARK}
              fontSize={12}
              fontWeight="600"
              className="bigIcon"
            >
              <Icon
                icon={icons[type] || GiftSmall}
                color={GRAY_DARK}
                size={20}
              />
              {campaignTypes[type]?.name}
            </Text>
          </div>
          <Text
            color={WHITE}
            fontSize={12}
            fontWeight="600"
            className="places"
            align="center"
          >
            10&nbsp;lugares
          </Text>
        </div>
      </div>
    </article>
  );
}

CampaignPreviewCard.propTypes = {
  values: PropTypes.object.isRequired,
  className: PropTypes.string,
};

export default CampaignPreviewCard;
