/** @jsxImportSource @emotion/react */
import moment from 'moment';
import { useState } from 'react';
import PropTypes from 'prop-types';
import { css } from '@emotion/react';
import { useNavigate } from 'react-router-dom';

import { ROUTES } from 'constants';
import Text from 'components/Text';
import { PURPLE_FEEK, WHITE } from 'styles/colors';
import AccordionStatus from 'components/AccordionStatus';
import PrimaryButton from 'components/buttons/PrimaryButton';
import ModalAddOnPurchase from 'components/ModalAddOnPurchase';

const style = css`
  .label {
    margin-bottom: 12px;
    line-height: 140%;
  }

  .primary-button {
    width: 100%;
    height: 30px;
    padding: 8px;
    margin-top: 12px;
    border: 1px solid ${PURPLE_FEEK};
  }
`;

function PaymentButton() {
  const navigate = useNavigate();
  return (
    <PrimaryButton
      textProps={{ fontSize: 14, fontWeight: 400 }}
      className="primary-button"
      label="Obtener un plan"
      onClick={() => {
        navigate(ROUTES.PAYMENT);
      }}
    />
  );
}

function PlacesAvailableText({ places }) {
  return (
    <Text className="label">
      Te quedan <b>{places}</b> campañas disponibles este periodo.
    </Text>
  );
}

function AccordionProfilePlan({
  statusIcon = false,
  customer,
  showAddOn = false,
  className,
}) {
  const navigate = useNavigate();
  const [showModalAddonPurchase, setShowModalAddonPurchase] =
    useState(false);
  return (
    <>
      {showAddOn && customer?.plan_id && (
        <ModalAddOnPurchase
          show={showModalAddonPurchase}
          handleClose={() => setShowModalAddonPurchase(false)}
          title="Agrega Campañas extra a tu cuenta."
        />
      )}

      <AccordionStatus
        status={statusIcon ? !!customer?.plan_id : undefined}
        title="Plan de suscripción"
        className={className}
        content={
          <div css={style}>
            {!customer?.plan_id ? (
              <>
                <Text>
                  Actualmente no cuenta con un plan de suscripción
                  activo.
                </Text>
                <PaymentButton />
              </>
            ) : customer?.suscription_active ? (
              <>
                <Text className="label">
                  Periodo actual{' '}
                  <b>
                    {moment(customer?.current_period_start).format(
                      'DD MMMM [de] YYYY',
                    )}
                  </b>{' '}
                  al{' '}
                  <b>
                    {moment(customer?.current_period_end).format(
                      'DD MMMM [de] YYYY',
                    )}
                  </b>
                  .
                </Text>
                <PlacesAvailableText places={customer?.places} />
                <PrimaryButton
                  color={WHITE}
                  textColor={PURPLE_FEEK}
                  label={'Cambiar Plan'}
                  onClick={() => navigate(ROUTES.PAYMENT)}
                  textProps={{ fontSize: 14, fontWeight: 400 }}
                  className="primary-button"
                />
                {showAddOn && (
                  <PrimaryButton
                    label={'Comprar campañas'}
                    color={WHITE}
                    onClick={(e) => {
                      e.stopPropagation();
                      setShowModalAddonPurchase(true);
                    }}
                    className="primary-button"
                    textColor={PURPLE_FEEK}
                    textProps={{ fontSize: 14, fontWeight: 400 }}
                  />
                )}
              </>
            ) : (
              <>
                <Text className="label">
                  Actualmente no cuentas con un plan de suscripción
                  activo. Tu periodo actual termina el{' '}
                  <b>
                    {moment(customer?.current_period_end).format(
                      'DD MMMM [de] YYYY',
                    )}
                  </b>
                  .
                </Text>
                <PlacesAvailableText places={customer?.places} />
                <PaymentButton />
              </>
            )}
          </div>
        }
      />
    </>
  );
}

AccordionProfilePlan.propTypes = {
  statusIcon: PropTypes.bool,
  customer: PropTypes.object,
  showAddOn: PropTypes.bool,
  className: PropTypes.string,
};

PlacesAvailableText.propTypes = {
  places: PropTypes.number,
};

export default AccordionProfilePlan;
