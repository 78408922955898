/** @jsxImportSource @emotion/react */
import { useEffect } from 'react';
import { css } from '@emotion/react';
import { useSelector } from 'react-redux';
import { useDispatch } from 'react-redux';
import { useNavigate } from 'react-router-dom';

import APIClient from 'redux/api';
import { ROUTES } from 'constants';
import Icon from 'components/Icon';
import Text from 'components/Text';
import { selectCustomer } from 'redux/app';
import Button from 'components/buttons/Button';
import { WHITE, GRAY_DEFAULT } from 'styles/colors';
import { setCategories, setCustomers } from 'redux/entities';
import LinearProgressBar from 'components/LinearProgressBar';
import PixelSortedBg1 from 'assets/images/PixelSortedBg1.png';
import feekLogoOverBackground from 'utils/feekLogoOverBackground';
import BusinessCategoriesSelector from 'components/BusinessCategoriesSelector';
import { ReactComponent as ArrowLeftCircle } from 'assets/icons/ArrowLeftCircle.svg';

const styles = css`
  display: flex;
  justify-content: center;
  align-items: center;
  width: 100%;
  min-height: 100vh;
  ${feekLogoOverBackground(PixelSortedBg1)};
  padding-block-start: 70px;
  box-sizing: border-box;

  & * {
    box-sizing: border-box;
  }

  .icon {
    position: absolute;
    top: 58px;
    left: 64px;
  }

  .mainForm {
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
    padding: 60px 80px;
    background: ${WHITE};
    width: 640px;
    border-radius: 16px;
    position: relative;

    .buttonBack {
      position: absolute;
      top: 46px;
      left: 28px;
    }

    .ProgressBar {
      margin-top: 30px;
      width: 100%;

      .label {
        margin-bottom: 10px;
      }
    }
  }
`;

function RegistrationBussinesUnit() {
  const navigate = useNavigate();
  const customers = useSelector((state) => state.entities.customers);
  const selectedCustomerId = useSelector(
    (state) => state.app.selectedCustomerId,
  );
  const selectedCustomer = customers[selectedCustomerId]?.customer;
  const categories = useSelector(
    (state) => state.entities.categories,
  );
  const dispatch = useDispatch();

  useEffect(() => {
    APIClient.getCategories()
      .then(({ data }) => dispatch(setCategories(data)))
      .catch((e) => console.error(e));

    APIClient.getCustomers()
      .then(({ data }) => {
        const customers = data.reduce(
          (prev, crr) => ({ ...prev, [crr.customer._id]: crr }),
          {},
        );
        if (!(selectedCustomerId in customers)) {
          dispatch(selectCustomer(data[0].customer._id));
        }
        dispatch(setCustomers(customers));
      })
      .catch((e) => console.error(e));
  }, []);

  return (
    <div css={styles}>
      <main className="mainForm">
        <Button
          className="buttonBack"
          onClick={() => navigate(ROUTES.REGISTRATION_PROFILE)}
        >
          <Icon
            icon={ArrowLeftCircle}
            size={30}
            color={GRAY_DEFAULT}
          />
        </Button>

        <BusinessCategoriesSelector
          buttonText="Siguiente"
          title="¿Cuál es el giro de tu negocio?"
          onSuccess={() =>
            navigate(ROUTES.REGISTRATION_PROFILE_SUCESS)
          }
          categories={categories}
          customer={selectedCustomer}
        />

        <div className="ProgressBar">
          <Text
            fontSize={10}
            fontWeight="600"
            align="right"
            className="label"
          >
            Paso 2 de 3
          </Text>
          <LinearProgressBar progress={66} />
        </div>
      </main>
    </div>
  );
}

export default RegistrationBussinesUnit;
