/** @jsxImportSource @emotion/react */
import { css } from '@emotion/react';
import { useNavigate } from 'react-router-dom';

import { ROUTES } from 'constants';
import Card from 'components/Card';
import Text from 'components/Text';
import SplashBg from 'assets/images/SplashBg.png';
import FeekLogoWorld from 'assets/images/FeekLogoWorld.png';
import PrimaryButton from 'components/buttons/PrimaryButton';
import LinearProgressBar from 'components/LinearProgressBar';
import feekLogoOverBackground from 'utils/feekLogoOverBackground';

const styles = css`
  width: 100%;
  height: 100vh;
  display: flex;
  justify-content: center;
  align-items: center;
  ${feekLogoOverBackground(SplashBg)}

  .icon {
    position: absolute;
    top: 58px;
    left: 64px;
  }

  .data {
    display: flex;
    gap: 20px;
    flex-direction: column;
    align-items: center;
    padding: 50px;
  }

  .cardContainer {
    width: 33%;
  }

  .feekLogoWorld {
    width: 380px;
  }

  @media (max-width: 1600px) {
    .cardContainer {
      width: 40%;
    }
  }

  @media (max-width: 1200px) {
    .cardContainer {
      width: 70%;
    }
  }

  @media (max-width: 740px) {
    .cardContainer {
      width: 90%;
    }
  }

  .progressBar {
    .label {
      margin-bottom: 10px;
    }
    width: 100%;
  }
`;

function RegistrationCustomerSuccess() {
  const navigate = useNavigate();
  const handleGoToHome = () => {
    navigate(ROUTES.HOME);
  };

  return (
    <div css={styles}>
      <div className="cardContainer">
        <Card size="large">
          <div className="data">
            <img
              draggable={false}
              src={FeekLogoWorld}
              className="feekLogoWorld"
              alt="feek-logo"
            />
            <Text
              fontSize={22}
              align="center"
              fontWeight="600"
              className="title"
            >
              ¡Felicidades!
            </Text>
            <Text fontSize={14} align="center">
              Ahora eres socio <b>Feek</b>, puede comenzar a
              configurar tus primeras campañas
            </Text>
            <PrimaryButton
              label="Comenzar"
              onClick={handleGoToHome}
            />
            <div className="progressBar">
              <Text fontSize={12} align="right" className="label">
                Paso 3 de 3
              </Text>
              <LinearProgressBar progress={100} />
            </div>
          </div>
        </Card>
      </div>
    </div>
  );
}

export default RegistrationCustomerSuccess;
