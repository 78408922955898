/** @jsxImportSource @emotion/react */
import PropTypes from 'prop-types';
import { css } from '@emotion/react';
import { useSelector } from 'react-redux';

import Text from 'components/Text';
import { WHITE } from 'styles/colors';
import Modal from 'components/modals/Modal';
import PrimaryButton from 'components/buttons/PrimaryButton';
import FeekerLookingReport from 'assets/images/FeekerLookingReport.png';

const styles = css`
  box-sizing: border-box;
  display: flex;
  flex-direction: column;
  align-items: center;
  gap: 24px;
  width: 450px;
  padding: 40px;
  background-color: ${WHITE};
  border-radius: 8px;

  & > * {
    box-sizing: border-box;
  }

  .image {
    width: 190px;
    height: 140px;
    margin-bottom: 20px;
  }
`;

function RequestInvitationToCustomerModal({ isOpen, handleClose }) {
  const email = useSelector((state) => state.app.email);

  return (
    <Modal visible={isOpen} onRequestClose={handleClose}>
      <div css={styles}>
        <Text
          fontSize={24}
          align="center"
          fontWeight="bold"
          lineHeight={32}
        >
          ¿Deseas vincular un Negocio ya existente?
        </Text>
        <Text fontSize={16} lineHeight={24} align="center">
          Contacta al administrador del Negocio y solicita que te den
          acceso a tu correo electrónico
          <strong>{' ' + email}</strong>
        </Text>
        <img
          src={FeekerLookingReport}
          alt="Feeker looking report"
          className="image"
        />
        <PrimaryButton
          strokeVariant
          label="Cerrar"
          onClick={handleClose}
        />
      </div>
    </Modal>
  );
}

RequestInvitationToCustomerModal.propTypes = {
  handleClose: PropTypes.func,
  isOpen: PropTypes.bool,
};

export default RequestInvitationToCustomerModal;
