/** @jsxImportSource @emotion/react */
import { css } from '@emotion/react';
import { useFormikContext } from 'formik';

import {
  WHITE,
  GRAY_LIGHT,
  PURPLE_FEEK,
  GRAY_LIGHTER,
  GRADIENT_PURPLE,
} from 'styles/colors';
import Text from 'components/Text';
import Icon from 'components/Icon';
import Button from 'components/buttons/Button';
import { MEDIA_QUERIES } from 'styles/constants';
import { ReactComponent as Group } from 'assets/icons/Group.svg';
import { ReactComponent as Couple } from 'assets/icons/Couple.svg';
import { ReactComponent as Individual } from 'assets/icons/Individual.svg';

const styles = css`
  display: flex;
  flex-direction: row;
  justify-content: center;
  flex-wrap: nowrap;
  gap: 43px;

  .card {
    display: grid;
    grid-template-rows: 160px auto;
    grid-template-columns: 1fr;
    justify-content: center;
    align-content: start;
    min-height: 286px;
    border: solid 1px ${GRAY_LIGHT};
    border-radius: 8px;
    margin: 0;
    cursor: pointer;
    width: 100%;
    max-width: 230px;
    background-color: ${WHITE};
  }

  .participantsWrapper {
    position: relative;
    z-index: 0;
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: start;
    gap: 17px;
    padding: 38px 18px 18px;
    border-bottom: solid 1px ${GRAY_LIGHTER};
    background: ${GRADIENT_PURPLE};
    border-radius: 7px 7px 0 0;
    transition: all 0.3s;
  }
  .participantsWrapper::after {
    background: ${WHITE};
    transition: all 0.3s;
    content: '';
    position: absolute;
    z-index: -1;
    top: 0;
    width: 100%;
    height: 100%;
    border-radius: 7px 7px 0 0;
  }

  .participants {
    display: none;
  }

  .cardInnerTitle {
    margin-block-end: 10px;
  }

  .description {
    padding: 18px;
  }

  .card.selected .participantsWrapper {
    padding: 18px;
  }
  .card.selected .participantsWrapper::after {
    background: transparent;
  }
  .card.selected .participantsWrapper div:first-of-type {
    color: ${WHITE};
  }
  .card.selected .participantsWrapper svg path {
    fill: ${WHITE};
  }
  .card.selected .participantsWrapper .participants {
    display: initial;
  }

  ${MEDIA_QUERIES.mobile} {
    flex-wrap: wrap;
  }
`;

function Step2Participation() {
  const { values, setFieldValue } = useFormikContext();

  const participation = [
    {
      title: 'Individual',
      icon: Individual,
      participants: 'Individuales',
      description: {
        title: 'Feeker solo',
        body: 'Para darle atención personalizada al Feeker.',
      },
    },
    {
      title: 'En pareja',
      icon: Couple,
      participants: 'En Pareja',
      description: {
        title: 'Feeker + invitado',
        body: 'Para compartir con una persona especial.',
      },
    },
    {
      title: 'En grupo',
      icon: Group,
      participants: 'En Grupo',
      description: {
        title: 'Feeker + grupo',
        body: 'Para disfrutar acompañados en grupos de 3 o más.',
      },
    },
  ];

  return (
    <>
      <div css={styles}>
        {participation.map((item) => {
          return (
            <Button
              onClick={() =>
                setFieldValue('participation', item.title)
              }
              key={item.title}
              className={
                values.participation === item.title
                  ? 'card selected'
                  : 'card'
              }
            >
              <div className="participantsWrapper">
                <Text
                  align="center"
                  fontSize={16}
                  fontWeight="600"
                  lineHeight={19}
                >
                  {item.title}
                </Text>

                <Icon
                  size={35}
                  color={PURPLE_FEEK}
                  icon={item.icon}
                />

                <Text
                  align="center"
                  fontSize={13}
                  fontWeight="400"
                  lineHeight={17}
                  className="participants"
                  color={WHITE}
                >
                  Tienes <b>10</b> lugares
                  <Text
                    align="center"
                    fontSize={13}
                    fontWeight="400"
                    lineHeight={17}
                    color={WHITE}
                  >
                    <b>{item.participants}</b> disponibles
                  </Text>
                </Text>
              </div>

              <div className="description">
                <Text
                  align="center"
                  fontSize={14}
                  fontWeight="700"
                  lineHeight={20}
                  className="cardInnerTitle"
                >
                  {item.description.title}
                </Text>
                <Text
                  align="center"
                  fontSize={14}
                  fontWeight="400"
                  lineHeight={20}
                >
                  {item.description.body}
                </Text>
              </div>
            </Button>
          );
        })}
      </div>
    </>
  );
}

export default Step2Participation;
