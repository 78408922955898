import axios from 'axios';

const API =
  process.env.REACT_APP_ENV_URL || 'https://feek-back.herokuapp.com/';

class APIClient {
  client;

  constructor() {
    this.client = axios.create({
      baseURL: API,
      timeout: 10000,
      headers: {
        'Accept-language': 'es',
        Authorization: `Bearer ${localStorage.getItem('token')}`,
      },
    });

    this.client.interceptors.response.use(
      (response) => {
        return Promise.resolve(response);
      },
      (error) => {
        if (
          401 === error.response.status &&
          window.location.pathname !== '/' &&
          window.location.pathname !== '/login'
        ) {
          this.unsetAuthenticatedInstance();
        }
        return Promise.reject(
          new Error(
            error.response?.data?.message ||
              error?.response?.statusText ||
              error?.message ||
              'Hubo un error inesperado',
          ),
        );
      },
    );
  }

  setAuthenticatedInstance(token) {
    localStorage.setItem('token', token);
    this.client.defaults.headers.Authorization = `Bearer ${token}`;
  }

  unsetAuthenticatedInstance() {
    localStorage.removeItem('token');
    localStorage.removeItem('userId');
    window.location.pathname = '/login';
    this.client.defaults.headers.Authorization = undefined;
  }

  updateUser(userId, payload) {
    return this.client.put(`users/${userId}`, payload);
  }

  getRoles() {
    return this.client.get('roles/roles-partners');
  }

  signUpUserByEmail(payload) {
    return this.client.post('users', payload);
  }

  signUpUserByPhone(payload) {
    return this.client.post('user/phone', payload);
  }

  verifyCode(payload) {
    return this.client.post('users/verifications', payload);
  }

  sendVerifyCode(payload) {
    return this.client.post('users/verifications/resend', payload);
  }

  login(payload) {
    return this.client.post('auth/login', payload);
  }

  loginByGoogle(payload) {
    return this.client.post('auth/google-autentication', payload);
  }

  sendForgotPasswordVerificationCode({ key_user }) {
    return this.client.post('auth/reset-password-send', {
      key_user,
      device: 'WEB',
    });
  }

  forgotPasswordValidateCode(payload) {
    return this.client.post('auth/reset-password-validate', payload);
  }

  resetPassword(payload) {
    return this.client.post('auth/reset-password', payload);
  }

  createCampaign(payload) {
    return this.client.post('campaigns', payload);
  }

  updateCampaign(campaign_id, payload) {
    return this.client.put(`campaigns/${campaign_id}`, payload);
  }

  getCampaignTypes() {
    return this.client.get('campaigns-types');
  }

  createCustomer(payload) {
    return this.client.post('customers', payload);
  }

  updateCustomer(payload, customerId) {
    return this.client.put(`customers/${customerId}`, payload);
  }

  getCategories(payload) {
    return this.client.get('categories/get-all', payload);
  }

  getCustomerCard(customerId) {
    return this.client.get(
      `payments/credits-cards/${customerId}/default`,
    );
  }

  getProfile() {
    return this.client.get('profile');
  }

  getCustomers() {
    return this.client.get('users/customers/all');
  }

  getCustomer(customerId) {
    return this.client.get(`customers/${customerId}`);
  }

  uploadFile(payload) {
    const data = new FormData();
    data.append('file', payload.file);
    const folder = 'feek-dev';
    if (payload.isCustomerLogo) {
      folder.concat('customer/logo');
    }

    if (payload.isCustomerImage) {
      folder.concat('customer/image');
    }

    data.append('folder', folder);

    return this.client.post('s3/upload', data, {
      headers: {
        'Content-Type': 'multipart/form-data',
      },
    });
  }

  uploadCampaignFile(file) {
    const data = new FormData();
    data.append('file', file);
    data.append(
      'folder',
      `temp/${process.env.NODE_ENV || 'development'}`,
    );

    return this.client.post('s3/upload', data, {
      headers: {
        'Content-Type': 'multipart/form-data',
      },
    });
  }

  getPlans() {
    return this.client.get('plans');
  }

  deleteCollaborator(customerId, collaboratorId) {
    return this.client.delete('collaborator', {
      data: {
        collaborator_id: collaboratorId,
        customer_id: customerId,
      },
    });
  }

  unlinkCustomer(customer_id) {
    return this.client.delete(
      `collaborator/unlink/customer/${customer_id}`,
    );
  }

  createCollaborator(payload) {
    return this.client.post('collaborator', payload);
  }

  changePasswordCollaborator(payload) {
    return this.client.post('collaborator/change_password', payload);
  }

  validateInvitCollaborator(accountInvite) {
    return this.client.post('collaborator/validate_account_invite', {
      account_invite: accountInvite,
    });
  }

  updateCollaborator(payload) {
    return this.client.put('collaborator', payload);
  }

  inviteCollaborator(payload) {
    return this.client.post('collaborator/invite', payload);
  }

  respondCustomerInvitation(customerId, accepted) {
    return this.client.post('collaborator/invitation_update', {
      customer_id: customerId,
      accepted: accepted,
    });
  }

  getCampaignsByCustomer(customerId, payload) {
    return this.client.get(
      `campaigns-feekers/${customerId}`,
      payload?.search && {
        params: { search: payload.search },
      },
    );
  }

  downloadInvoice(invoiceId) {
    return this.client.get(
      `payments/customer/invoice-payment/${invoiceId}`,
      {
        responseType: 'arraybuffer',
      },
    );
  }

  getPaymentHistory(customerId, page, limit = 10) {
    return this.client.get(`payments/report/${customerId}`, {
      params: {
        page,
        limit,
      },
    });
  }

  suscriptionPayment(payload) {
    return this.client.post('payments/subscriptions', payload);
  }

  getSpeiToBePayed(payload) {
    const { stripeProductId, customerId, coupon } = payload;

    return this.client.get('payments/get-spei-to-be-payed', {
      params: {
        coupon,
        customer_id: customerId,
        stripe_product_id: stripeProductId,
      },
    });
  }

  /**
   * @deprecated This function will not be used anymore,
   * Use getValidateCoupon instead to get the coupon validated according to the plan selected
   */
  getCoupon(coupon) {
    return this.client.get(`payments/get-coupon/${coupon}`);
  }

  getValidatedCoupon(coupon, productId) {
    return this.client.get(
      `v2/payments/get-coupon?coupon=${coupon}&stripe_product_id=${productId}`,
    );
  }

  saveCreditCardMethod(payload) {
    return this.client.post('payments/credit-cards', payload);
  }

  updateBilling(customerId, payload) {
    return this.client.put(
      `customers/billing/${customerId}`,
      payload,
    );
  }

  deleteCampaign(campaignId) {
    return this.client.delete(`campaigns/${campaignId}`);
  }

  getAnalytics(payload) {
    const { customerId, ...rest } = payload;
    const params = new URLSearchParams();
    Object.entries(rest).forEach((param) =>
      params.append(param[0], param[1]),
    );
    return this.client.get(`analytics/dashboard/${customerId}`, {
      params,
    });
  }

  syncUpInstagramCustomer(payload) {
    return this.client.post('customers/instagram/sync-up', payload);
  }

  getAddOns(payload) {
    return this.client.get(`add-ons?customer_id=${payload}`);
  }

  postAddOns(payload) {
    return this.client.post('payments/add-ons', payload);
  }

  cancelSuscription(customerId) {
    return this.client.patch(
      `payments/cancel/subscription/${customerId}`,
    );
  }

  changeSubscription(payload) {
    return this.client.patch('payments/change/subscription/', {
      plan_id: payload.planId,
      customer_id: payload.customerId,
    });
  }
}

export default new APIClient();
