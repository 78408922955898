/** @jsxImportSource @emotion/react */
import { css } from '@emotion/react';
import 'react-toastify/dist/ReactToastify.css';
import { ToastContainer } from 'react-toastify';

const styles = css`
  width: 80vw;

  .Toastify__toast {
    border-radius: 8px;
  }
`;

function CustomToastContainer({ ...rest }) {
  return (
    <ToastContainer css={styles} position="bottom-center" {...rest} />
  );
}

export default CustomToastContainer;
