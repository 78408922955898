export const formatPrice = (price) => {
  return (
    '$' +
    (price > 1000
      ? Math.floor(price / 1000) + ',' + price.toString().slice(1)
      : price)
  );
};

export const formatLocation = (location) => {
  let addresString = location?.street ?? '';
  if (location?.street) {
    if (location?.exterior_number) {
      addresString += ` #${location?.exterior_number}`;
    }
    if (location.suburb) {
      addresString += `, ${location.suburb}`;
    }
    if (location.cp) {
      addresString += `, C.P. ${location.cp}`;
    }
    if (location.city) {
      addresString += `, ${location.city}`;
    }
    if (location.state) {
      addresString += `, ${location.state}`;
    }
  }
  return addresString;
};

export const convertGooglePlaceToAddress = (googlePlace) => {
  const placeSchema = {
    street_number: 'short_name',
    route: 'long_name',
    sublocality_level_1: 'long_name',
    premise: 'long_name',
    locality: 'long_name',
    administrative_area_level_1: 'long_name',
    postal_code: 'short_name',
    country: 'long_name',
  };

  const address = {};

  googlePlace.forEach((element) => {
    element.types.forEach((elementType) => {
      if (placeSchema[elementType]) {
        address[elementType] = element[placeSchema[elementType]];
      }
    });
  });
  return address;
};
