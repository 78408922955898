/** @jsxImportSource @emotion/react */
import { useState } from 'react';
import { css } from '@emotion/react';
import { toast } from 'react-toastify';
import { useNavigate } from 'react-router-dom';
import { useDispatch, useSelector } from 'react-redux';

import APIClient from 'redux/api';
import Card from 'components/Card';
import { ROUTES } from 'constants';
import Text from 'components/Text';
import { setUser } from 'redux/app';
import { GRAY } from 'styles/colors';
import handleError from 'utils/handleError';
import PrimaryButton from 'components/buttons/PrimaryButton';
import feekLogoOverBackground from 'utils/feekLogoOverBackground';
import TurbulentFlowColorsBg from 'assets/images/TurbulentFlowColorsBg.png';
import SplittedNumericalInput from 'components/inputs/SplittedNumericalInput';

const styles = css`
  width: 100%;
  height: 100vh;
  display: flex;
  justify-content: center;
  align-items: center;
  ${feekLogoOverBackground(TurbulentFlowColorsBg)}

  .icon {
    position: absolute;
    top: 58px;
    left: 64px;
  }

  .data {
    display: flex;
    flex-direction: column;
    align-items: center;
    padding: 50px;
    gap: 30px;

    .input {
      border-bottom: 1px solid ${GRAY};
      padding-bottom: 3px;
    }
  }
`;

function RegistrationVerificationPhoneCode() {
  const [code, setCode] = useState('');
  const dispatch = useDispatch();
  const navigate = useNavigate();
  const [isLoading, setIsLoading] = useState(false);
  const { phone, password } = useSelector((state) => state.app);

  const handleResendCode = async () => {
    setIsLoading(true);
    try {
      const response = await APIClient.sendVerifyCode({
        key_validate: phone,
      });
      if (response.status === 201) {
        toast.success('Código enviado con éxito');
        setIsLoading(false);
      }
    } catch (error) {
      setIsLoading(false);
      handleError(error);
    }
  };

  const handleVerifyCode = async (code) => {
    setIsLoading(true);
    try {
      const response = await APIClient.verifyCode({
        key_validate: password,
        code,
      });
      if (response.status === 201) {
        if (response.data?.valid === true) {
          const res = await APIClient.login({
            key_user: phone,
            password,
          });
          if (res.status === 201) {
            APIClient.setAuthenticatedInstance(res.data.access_token);

            dispatch(setUser(res.data.user));

            navigate(ROUTES.REGISTRATION_PROFILE);
          }
        }
      }
      setIsLoading(false);
    } catch (e) {
      setIsLoading(false);
      handleError(e);
    }
  };

  const handleInputChange = (code) => {
    setCode(code);
    if (code.length === 6) handleVerifyCode(code);
  };

  return (
    <div css={styles}>
      <Card>
        <div className="data">
          <Text fontSize={24} fontWeight="bold">
            Confirma tu número telefónico
          </Text>
          <Text fontSize={14} align="center">
            Introduce el código que enviamos a tu teléfono {phone} via
            SMS para confirmar tu registro.
          </Text>

          <SplittedNumericalInput
            autoFocus
            length={6}
            value={code}
            className="input"
            disabled={isLoading}
            onChange={handleInputChange}
          />
          <Text fontSize={14}>¿No recibiste el código?</Text>
          <PrimaryButton
            strokeVariant
            disabled={isLoading}
            label="Reenviar código"
            onClick={handleResendCode}
          />
        </div>
      </Card>
    </div>
  );
}

export default RegistrationVerificationPhoneCode;
