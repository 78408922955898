/** @jsxImportSource @emotion/react */
import PropTypes from 'prop-types';
import { css } from '@emotion/react';

import {
  WHITE,
  GRAY_LIGHTEST,
  GRADIENT_INSTAGRAM,
  PURPLE_B,
} from 'styles/colors';
import Icon from 'components/Icon';
import Text from 'components/Text';
import Button from 'components/buttons/Button';
import { ReactComponent as Instagram } from 'assets/icons/Instagram.svg';

const InstagramButton = (props) => {
  const {
    showIcon = true,
    className,
    iconClassName,
    labelClassName,
    onClick,
    label,
    cliendId = '531961008591092',
    // ! All redirectUris should be allowed first on meta developers console
    redirectUri = window.location.href,
    scope = 'user_profile,user_media',
    ...rest
  } = props;

  function handleClick(event) {
    onClick?.(event);
    window.location.href = `https://api.instagram.com/oauth/authorize?client_id=${cliendId}&redirect_uri=${redirectUri}&scope=${scope}&response_type=code`;
  }

  const styles = css`
    box-sizing: border-box;
    display: flex;
    align-items: center;
    justify-content: center;
    gap: 10px;
    border-radius: 12px;
    width: 100%;
    height: 40px;
    padding: 0 20px;
    background: ${GRADIENT_INSTAGRAM} ${PURPLE_B};
  `;

  return (
    <Button
      onClick={handleClick}
      css={styles}
      className={className}
      {...rest}
    >
      {showIcon && (
        <Icon
          size={15}
          icon={Instagram}
          color={!rest.disabled ? WHITE : GRAY_LIGHTEST}
          className={iconClassName}
        />
      )}
      <Text
        className={labelClassName}
        color={!rest.disabled ? WHITE : GRAY_LIGHTEST}
        fontSize={15}
      >
        {label}
      </Text>
    </Button>
  );
};

InstagramButton.propTypes = {
  ...Button.propTypes,
  onClick: PropTypes.func,
  showIcon: PropTypes.bool,
  className: PropTypes.string,
  iconClassName: PropTypes.string,
  labelClassName: PropTypes.string,
  label: PropTypes.string.isRequired,
  cliendId: PropTypes.string,
  redirectUri: PropTypes.string,
  scope: PropTypes.oneOf([
    'user_profile',
    'user_media',
    'user_profile,user_media',
    'user_media,user_profile',
  ]),
};

export default InstagramButton;
