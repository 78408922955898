import { configureStore, combineReducers } from '@reduxjs/toolkit';

import appReducer from 'redux/app';
import entitiesReducer from 'redux/entities';

const reducers = {
  app: appReducer,
  entities: entitiesReducer,
};

const rootReducer = combineReducers(reducers);

const resettableRootReducer = (state, action) => {
  if (action.type === 'app/logout') {
    return rootReducer(undefined, action);
  }
  return rootReducer(state, action);
};

export const store = configureStore({
  reducer: resettableRootReducer,
});
