/** @jsxImportSource @emotion/react */
import { css } from '@emotion/react';

import PropTypes from 'prop-types';
import * as Colors from 'styles/colors';

const Icon = (props) => {
  const { icon, className, size = 20, color = Colors.GRAY } = props;

  const styles = css`
    flex-shrink: 0;
    width: ${size}px;
    height: ${size}px;
    margin: 0;
    padding: 0;

    circle,
    ellipse,
    line,
    path,
    polygon,
    polyline,
    rect {
      fill: ${color};
    }
  `;

  let Icon = icon;

  return <Icon css={styles} className={className} />;
};

Icon.propTypes = {
  className: PropTypes.string,
  size: PropTypes.number,
  icon: PropTypes.object,
  color: PropTypes.oneOf(Object.values(Colors)),
};

export default Icon;
