/** @jsxImportSource @emotion/react */
import moment from 'moment';
import { useEffect } from 'react';
import { css } from '@emotion/react';
import { toast } from 'react-toastify';
import { useSearchParams } from 'react-router-dom';
import { useDispatch, useSelector } from 'react-redux';

import APIClient from 'redux/api';
import Text from 'components/Text';
import Icon from 'components/Icon';
import Avatar from 'components/Avatar';
import { selectCustomer } from 'redux/app';
import handleError from 'utils/handleError';
import { MEDIA_QUERIES } from 'styles/constants';
import { GRAY_LIGHT, PURPLE_FEEK } from 'styles/colors';
import FooterLegalTexts from 'components/FooterLegalTexts';
import { setCategories, setCustomers } from 'redux/entities';
import { ReactComponent as Star } from 'assets/icons/Star.svg';
import AccordionProfilePlan from 'components/AccordionProfilePlan';
import AccordionProfileImage from 'components/AccordionProfileImage';
import AccordionProfileAddress from 'components/AccordionProfileAddress';
import AccordionProfileCategories from 'components/AccordionProfileCategories';
import AccordionProfileInstagramSync from 'components/AccordionProfileInstagramSync';
import AccordionProfilePaymentSettings from 'components/AccordionProfilePaymentSettings';

const styles = css`
  display: flex;
  flex-direction: column;
  flex: 1 0 auto;
  padding: 30px 40px;

  & :where(*) {
    box-sizing: border-box;
  }

  .mainHeader {
    display: flex;
    margin-block-end: 40px;
    gap: 50px;
    align-items: center;

    .mainHeaderText {
      display: flex;
      flex-direction: column;
      justify-content: space-evenly;
      height: 100%;

      & > *:not(:first-of-type) {
        margin-top: 15px;
      }
      .planContainer {
        display: flex;
        align-items: center;
        gap: 8px;
      }
    }
  }

  .accordionsContainer {
    display: grid;
    grid-template-columns: 1fr 1fr 1fr;
    gap: 30px;
    align-items: self-start;
  }

  ${MEDIA_QUERIES.mobile} {
    padding: 40px 24px;

    .mainHeader {
      margin-block-end: 40px;
      gap: 13px;

      .mainHeaderText {
        flex: 0 1 auto;

        .registrationDate {
          font-size: 11px;
        }

        & > *:not(:first-of-type) {
          margin: 0;
        }

        .planContainer {
          gap: 5px;
          font-size: 14px;

          svg {
            height: 12px;
            width: 12px;
          }
        }

        & > :nth-of-type(2) {
          font-weight: 500;
          font-size: 25px;
        }
      }
    }

    .accordionsContainer {
      grid-template-columns: 1fr;
      gap: 20px;
    }
  }
`;

function BusinessProfile() {
  const dispatch = useDispatch();
  const [searchParams, setSearchParams] = useSearchParams();

  const customers = useSelector((state) => state.entities.customers);
  const selectedCustomerId = useSelector(
    (state) => state.app.selectedCustomerId,
  );
  const selectedCustomer = customers[selectedCustomerId]?.customer;
  const categories = useSelector(
    (state) => state.entities.categories,
  );

  useEffect(() => {
    APIClient.getCategories()
      .then(({ data }) => dispatch(setCategories(data)))
      .catch((e) => console.log(e));
  }, []);

  useEffect(() => {
    const code = searchParams.get('code');
    if (!code || !selectedCustomerId) return;

    APIClient.syncUpInstagramCustomer({
      instagram_token: code,
      customer_id: selectedCustomerId,
      redirect_uri: window.location.href.slice(
        0,
        window.location.href.indexOf('?'),
      ),
    })
      .then(() => {
        toast.success('Instagram vinculado con éxito.');
        APIClient.getCustomers().then(({ data }) => {
          const customers = data.reduce(
            (prev, crr) => ({ ...prev, [crr.customer._id]: crr }),
            {},
          );
          if (!(selectedCustomerId in customers)) {
            dispatch(selectCustomer(data[0].customer._id));
          }
          dispatch(setCustomers(customers));
        });
      })
      .catch(handleError)
      .finally(() => setSearchParams(''));
  }, [searchParams, selectedCustomerId]);

  return (
    <div css={styles}>
      <header className="mainHeader">
        <Avatar
          image={selectedCustomer?.image}
          name={selectedCustomer?.name}
          size={130}
          sizeMobile={65}
        />

        <div className="mainHeaderText">
          {selectedCustomer?.plan_id ? (
            selectedCustomer?.suscription_active ? (
              <Text className="planContainer" fontSize={20}>
                <Icon color={PURPLE_FEEK} icon={Star} size={16} />
                {`Plan ${selectedCustomer?.plan_id?.name}`}
              </Text>
            ) : (
              <Text className="planContainer" fontSize={20}>
                <Icon color={GRAY_LIGHT} icon={Star} size={16} />
                Suscripción inactiva
              </Text>
            )
          ) : (
            <Text className="planContainer" fontSize={20}>
              <Icon color={GRAY_LIGHT} icon={Star} size={16} />
              Aún no has contratado un plan
            </Text>
          )}

          <Text fontSize={40} fontWeight="600">
            {selectedCustomer?.name}
          </Text>
          <Text
            fontSize={14}
            fontWeight="400"
            className="registrationDate"
          >
            Registrado desde el{' '}
            {moment(selectedCustomer?.created_at).format(
              'DD MMMM [de] YYYY',
            )}
          </Text>
        </div>
      </header>

      <div className="accordionsContainer">
        <AccordionProfileAddress customer={selectedCustomer} />

        <AccordionProfilePlan
          customer={selectedCustomer}
          showAddOn={true}
        />

        <AccordionProfilePaymentSettings
          customer={selectedCustomer}
        />

        <AccordionProfileImage
          customer={selectedCustomer}
          imageType="image"
        />

        <AccordionProfileImage
          customer={selectedCustomer}
          imageType="background_image"
        />

        <AccordionProfileCategories
          categories={categories}
          customer={selectedCustomer}
        />

        <AccordionProfileInstagramSync customer={selectedCustomer} />
      </div>

      <FooterLegalTexts />
    </div>
  );
}

export default BusinessProfile;
