/** @jsxImportSource @emotion/react */
import { useState } from 'react';
import { css } from '@emotion/react';
import { useDispatch, useSelector } from 'react-redux';
import { useLocation, useNavigate } from 'react-router-dom';

import {
  GRAY,
  BLACK,
  WHITE,
  PURPLE_FEEK,
  GRAY_LIGHTER,
} from 'styles/colors';
import { ROUTES } from 'constants';
import Text from 'components/Text';
import Icon from 'components/Icon';
import Avatar from 'components/Avatar';
import Button from 'components/buttons/Button';
import { MEDIA_QUERIES } from 'styles/constants';
import { ReactComponent as X } from 'assets/icons/X.svg';
import { closeSideMenu, logout, toggleSideMenu } from 'redux/app';
import { ReactComponent as LogOut } from 'assets/icons/LogOut.svg';
import DropDownLabelAndIcon from 'components/DropDownLabelAndIcon';
import AddCampaignButton from 'components/buttons/AddCampaignButton';
import { ReactComponent as Individual } from 'assets/icons/Individual.svg';
import { useProfileCompletedStatus } from 'hooks/useProfileCompletedStatus';
import { ReactComponent as HamburguerButton } from 'assets/icons/HamburguerButton.svg';

const styles = css`
  display: flex;
  align-items: center;
  gap: 25px;
  background-color: ${WHITE};
  height: 96px;
  border-bottom: ${GRAY_LIGHTER};
  border-bottom-style: solid;
  border-bottom-width: 1px;
  box-shadow: 0px 0px 10px rgba(0, 0, 0, 0.15);
  padding: 33px 40px;
  box-sizing: border-box;
  position: relative;
  z-index: 75;

  * {
    box-sizing: border-box;
  }

  .leftSideWrapper {
    flex-grow: 1;

    .titleContainer {
      display: flex;

      .exitButton {
        display: flex;
        gap: 20px;
        align-items: center;
        flex: 0 0 max-content;
      }

      .titleText {
        flex: 1 0 auto;
      }
    }

    &.mobile {
      display: none;
    }
  }

  .profileDropDown {
    display: flex;
    align-items: center;
    justify-content: space-evenly;
    width: 93px;
    height: 56px;
    background: ${WHITE};
    border: 1px solid ${GRAY_LIGHTER};
    box-sizing: border-box;
    border-radius: 8px;
    cursor: pointer;
  }

  .addCampaignButtonWrapperHeader {
    flex: 0 0 max-content;
  }

  ${MEDIA_QUERIES.mobile} {
    padding: 20px 22px;
    width: 100vw;
    height: 62px;
    box-shadow: 0px 0px 25px rgba(0, 0, 0, 0.15);
    position: sticky;
    top: 0;

    .leftSideWrapper {
      display: none;

      &.mobile {
        display: flex;
        gap: 11px;
        align-items: center;
      }
    }

    .addCampaignButtonWrapperHeader {
      display: none;
    }

    .profileDropDown {
      width: 81px;
      height: 42px;

      .avatar {
        width: 30px;
        height: 30px;
        font-size: 14px !important;
      }
    }
  }
`;

const stylesDropDownUserMenu = css`
  .content {
    box-shadow: 0px 0px 25px ${BLACK}25;
  }

  .profileDropDownMenu {
    display: flex;
    flex-direction: column;
    width: max-content;
    padding: 12px 10px;
    gap: 10px;

    .dropDownHeader {
      display: flex;
      align-items: center;
      justify-content: center;
      gap: 12px;
      margin-block-end: 12px;
    }

    .buttonContainer {
      display: flex;
      align-items: center;
      min-width: max-content;
      padding: 7px 15px;
      border-radius: 4px;

      svg {
        margin-inline-end: 12px;
      }
    }
    .buttonContainer:hover {
      background-color: ${GRAY_LIGHTER};
    }
  }
`;

const roles = {
  customer: 'Dueño',
  customerAdmin: 'Administrador',
  customerSupervisor: 'Supervisor',
  customerViewer: 'Espectador',
};

function Header() {
  const navigate = useNavigate();
  const dispatch = useDispatch();
  const { name, selectedCustomerId } = useSelector(
    (state) => state.app,
  );
  const { isProfileCompleted } = useProfileCompletedStatus();
  const customers = useSelector((state) => state.entities.customers);
  const selectedCustomer = customers[selectedCustomerId]?.customer;
  const selectedCustomerRole = customers[selectedCustomerId]?.role;

  const [isProfileDropDownOpen, setIsProfileDropDownOpen] =
    useState(false);

  const goToProfileSettings = () => {
    setIsProfileDropDownOpen(false);
    navigate(ROUTES.PROFILE);
    dispatch(closeSideMenu());
  };

  const handleLogout = () => {
    setIsProfileDropDownOpen(false);
    dispatch(logout());
  };

  const titles = {
    [ROUTES.ADD_CAMPAIGNS]: (
      <div className="titleContainer">
        <Button
          onClick={() => navigate(ROUTES.CAMPAIGNS)}
          className="exitButton"
        >
          <Icon icon={X} color={GRAY} size={10} />
          Salir
        </Button>
        <Text
          fontSize={20}
          type="inline"
          className="titleText"
          align="center"
        >
          Crear campaña
        </Text>
      </div>
    ),

    [ROUTES.BUSINESS_PROFILE]: 'Perfil Negocio',

    [ROUTES.CAMPAIGNS]: 'Campañas',

    [ROUTES.COLLABORATORS]: 'Colaboradores',

    [ROUTES.DASHBOARD]: 'Analíticas',

    [ROUTES.HOME]: (
      <Text fontSize={20}>
        Hola,{' '}
        <strong>
          {selectedCustomer?.name ||
            selectedCustomer?.instagramUserName}
        </strong>
      </Text>
    ),

    [ROUTES.PROFILE]: 'Perfil de usuario',

    [ROUTES.PAYMENT_HISTORY]: 'Historial de Pagos',

    [ROUTES.BILLING]: 'Facturación',
  };
  const { pathname } = useLocation();

  return (
    <>
      <div css={styles}>
        <div className="leftSideWrapper">
          {typeof titles[pathname] === 'string' ? (
            <Text fontSize={20}>{titles[pathname]}</Text>
          ) : (
            titles[pathname]
          )}
        </div>

        <Button
          onClick={() => dispatch(toggleSideMenu())}
          className="leftSideWrapper mobile"
        >
          <Text fontSize={15} fontWeight="700">
            {name}
          </Text>
          <Icon icon={HamburguerButton} color={GRAY} size={13} />
        </Button>

        {pathname === ROUTES.CAMPAIGNS && (
          <AddCampaignButton
            isProfileCompleted={isProfileCompleted}
            places={selectedCustomer?.places}
            wrapperClassName="addCampaignButtonWrapperHeader"
          />
        )}

        <DropDownLabelAndIcon
          className="profileDropDown"
          isOpen={isProfileDropDownOpen}
          onOpen={() => setIsProfileDropDownOpen(true)}
          onClose={() => setIsProfileDropDownOpen(false)}
          leftIcon={<Avatar size={39} sizeMobile={30} name={name} />}
          childrenWrapperCSS={stylesDropDownUserMenu}
        >
          <div className="profileDropDownMenu">
            <header className="dropDownHeader">
              <Avatar size={39} sizeMobile={30} name={name} />
              <span>
                <Text fontSize={20} fontWeight="500">
                  {name}
                </Text>
                <Text fontSize={10} fontWeight="500">
                  {roles[selectedCustomerRole]}
                </Text>
              </span>
            </header>
            <Button
              onClick={goToProfileSettings}
              className="buttonContainer"
            >
              <Icon size={16} icon={Individual} color={PURPLE_FEEK} />
              <Text fontSize={16} fontWeight="500" type="inline">
                Perfil
              </Text>
            </Button>
            <Button
              onClick={handleLogout}
              className="buttonContainer"
            >
              <Icon size={14} icon={LogOut} color={PURPLE_FEEK} />
              <Text fontSize={16} fontWeight="500" type="inline">
                Cerrar sesión
              </Text>
            </Button>
          </div>
        </DropDownLabelAndIcon>
      </div>
    </>
  );
}

export default Header;
