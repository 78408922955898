/** @jsxImportSource @emotion/react */
import { css } from '@emotion/react';

import Text from 'components/Text';
import { WHITE } from 'styles/colors';
import feekLogoOverBackground from 'utils/feekLogoOverBackground';
import TurbulentFlowColorsBg from 'assets/images/TurbulentFlowColorsBg.png';

const styles = css`
  display: flex;
  min-height: 100vh;
  width: 100%;
  justify-content: center;
  align-items: center;
  ${feekLogoOverBackground(TurbulentFlowColorsBg)}
`;

function NotFound() {
  return (
    <div css={styles}>
      <Text color={WHITE} fontSize={40} fontWeight="bold">
        404 pagina no encontrada...
      </Text>
    </div>
  );
}

export default NotFound;
