const ROUTES = {
  HOME: '/home',
  SHARE: '/share/:router',
  CAMPAIGNS: '/campaigns',
  COLLABORATORS: '/collaborators',
  LOGIN: '/login',
  REGISTRATION: '/registration',
  PROFILE: '/profile',
  NOT_FOUND: '/404',
  FORGOT_PASSWORD: '/forgotPassword',
  SHARE_PARAMS: '/share/:router/:param',
  REGISTRATION_VERIFICATION_CODE_EMAIL:
    '/RegistrationVerificationEmailCode',
  REGISTRATION_VERIFICATION_CODE_PHONE:
    '/RegistrationVerificationPhoneCode',
  REGISTRATION_PROFILE: '/registrationProfile',
  REGISTRATION_PROFILE_SUCESS: '/registrationSuccess',
  REGISTRATION_BUSINESS_UNIT: '/registrationBussinesUnit',
  PAYMENT: '/payment',
  PAYMENT_HISTORY: '/paymentHistory',
  DASHBOARD: '/dashboard',
  ADD_CAMPAIGNS: '/addCampaign',
  COLLABORATOR_NEW_PASSWORD: '/newPasswordCollaborator',
  POLICY: '/policy',
  TERMS_AND_CONDITIONS: '/termsAndConditions',
  SUBSCRIPTION_SUCCESS: '/paymentSuccess',
  BUSINESS_PROFILE: '/businessProfile',
  BILLING: '/billing',
};

export default ROUTES;
