/** @jsxImportSource @emotion/react */
import * as Yup from 'yup';
import { useState } from 'react';
import { css } from '@emotion/react';
import { Form, Formik } from 'formik';
import { useDispatch } from 'react-redux';
import { useNavigate } from 'react-router-dom';

import APIClient from 'redux/api';
import { ROUTES } from 'constants';
import Text from 'components/Text';
import { setUser } from 'redux/app';
import handleError from 'utils/handleError';
import Button from 'components/buttons/Button';
import { PURPLE_FEEK, WHITE } from 'styles/colors';
import GoogleButton from 'components/buttons/GoogleButton';
import { FormTextInput } from 'components/inputs/TextInput';
import PrimaryButton from 'components/buttons/PrimaryButton';
import { FormPhoneInput } from 'components/inputs/PhoneInput';
import feekLogoOverBackground from 'utils/feekLogoOverBackground';
import { FormPasswordInput } from 'components/inputs/PasswordInput';
import TurbulentFlowColorsBg from 'assets/images/TurbulentFlowColorsBg.png';

const styles = css`
  min-height: 100vh;
  display: flex;
  justify-content: center;
  align-items: center;
  padding: 50px;
  ${feekLogoOverBackground(TurbulentFlowColorsBg)};

  .formContainer {
    box-sizing: border-box;
    display: flex;
    align-items: center;
    flex-direction: column;
    width: 502px;
    height: min-content;
    background-color: ${WHITE};
    box-shadow: 0px 0px 25px rgba(0, 0, 0, 0.15);
    border-radius: 15px;
    padding: 50px 60px;
  }

  .usePhoneButton {
    text-decoration: underline;
  }

  .loginButtonsContainer {
    display: flex;
    flex-direction: column;
    gap: 15px;
    width: 100%;
    margin-top: 30px;
  }

  .title {
    margin-bottom: 30px;
  }

  .headerContainer {
    display: flex;
    position: absolute;
    top: 10px;
    width: 100%;
    height: 50px;
    justify-content: flex-end;
    align-items: center;
  }

  .registrationText {
    margin-right: 20px;
  }

  .buttonRegistrationContainer {
    display: flex;
    top: 10px;
    width: 150px;
    right: 10px;
  }

  .forgotPasswordBtn {
    margin-top: 15px;
  }

  .createAccount {
    height: 40px;
    width: 130px;
    border-radius: 15px;
  }
`;

const formInitialValuesPhone = {
  phone: '',
  password: '',
};

const formInitialValuesEmail = {
  email: '',
  password: '',
};

const formValidationEmailSchema = Yup.object({
  email: Yup.string()
    .email('El email es inválido')
    .required('El email es requerido'),

  password: Yup.string().required('La contraseña es requerida'),
});

const formValidationPhoneSchema = Yup.object({
  phone: Yup.string()
    .min(10, 'El télefono tiene que tener al menos 10 numeros')
    .required('El télefono es requerido'),

  password: Yup.string().required('La contraseña es requerida'),
});

function Login() {
  const dispatch = useDispatch();
  const navigate = useNavigate();
  const [usePhone, setUsePhone] = useState(false);

  const handleLogin = async (googleData) => {
    if ('access_token' in googleData) {
      try {
        const response = await APIClient.loginByGoogle({
          token: googleData.access_token,
        });
        if (response.status === 201) {
          localStorage.setItem('userId', response.data.data.user._id);
          APIClient.setAuthenticatedInstance(
            response.data.data.access_token,
          );
          dispatch(setUser(response.data.data.user));
          navigate(ROUTES.HOME);
        }
      } catch (e) {
        handleError(e);
      }
    }
  };

  const handleSignIn = async (values, actions) => {
    actions.resetForm({ values });
    actions.setSubmitting(true);

    try {
      const response = await APIClient.login({
        key_user: usePhone
          ? values.phone
          : values.email.toLowerCase().trim(),
        password: values.password,
      });
      if (response.status === 201) {
        localStorage.setItem('userId', response.data.user._id);
        APIClient.setAuthenticatedInstance(
          response.data.access_token,
        );
        dispatch(setUser(response.data.user));
        navigate(ROUTES.HOME);
      }
    } catch (e) {
      handleError(e);
      actions.setSubmitting(false);
    }
  };

  return (
    <div css={styles}>
      <div className="headerContainer">
        <Text
          fontSize={12}
          color={WHITE}
          className="registrationText"
        >
          ¿No tienes cuenta?
        </Text>

        <div className="buttonRegistrationContainer">
          <PrimaryButton
            label={'Regístrate'}
            onClick={() => navigate(ROUTES.REGISTRATION)}
            strokeVariant
            className="createAccount"
            fontSize={13}
          />
        </div>
      </div>

      <Formik
        validateOnMount
        onSubmit={handleSignIn}
        initialValues={
          usePhone ? formInitialValuesPhone : formInitialValuesEmail
        }
        validationSchema={
          usePhone
            ? formValidationPhoneSchema
            : formValidationEmailSchema
        }
      >
        {(formik) => {
          return (
            <Form className="formContainer">
              <Text className="title" fontSize={24} fontWeight="bold">
                ¿Ya tienes cuenta? Inicia sesión
              </Text>

              {usePhone ? (
                <FormPhoneInput name="phone" />
              ) : (
                <FormTextInput placeholder="Email" name="email" />
              )}

              <FormPasswordInput
                placeholder="Contraseña"
                name="password"
              />

              <Button
                onClick={() => {
                  setUsePhone((crr) => !crr);
                  formik.handleReset();
                }}
              >
                <Text
                  align="center"
                  fontSize={14}
                  fontWeight={600}
                  lineHeight={16.8}
                  color={PURPLE_FEEK}
                  className="usePhoneButton"
                >
                  {usePhone
                    ? 'Ingresar con email'
                    : 'Ingresar con teléfono'}
                </Text>
              </Button>

              <div className="loginButtonsContainer">
                <PrimaryButton
                  label={'Iniciar sesión'}
                  onClick={formik.submitForm}
                  disabled={!formik.isValid || formik.isSubmitting}
                />

                <GoogleButton
                  onSuccess={handleLogin}
                  onFailure={handleLogin}
                  text={'Iniciar sesión con Google'}
                />
              </div>

              <Button
                className="forgotPasswordBtn"
                onClick={() => navigate(ROUTES.FORGOT_PASSWORD)}
              >
                <Text
                  fontWeight="700"
                  fontSize={10}
                  lineHeight={14.4}
                  color={PURPLE_FEEK}
                  align="center"
                >
                  Olvidé mi contraseña
                </Text>
              </Button>
            </Form>
          );
        }}
      </Formik>
    </div>
  );
}

export default Login;
