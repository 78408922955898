/** @jsxImportSource @emotion/react */
import PropTypes from 'prop-types';
import { css } from '@emotion/react';

import Text from 'components/Text';
import { MEDIA_QUERIES } from 'styles/constants';
import { GRAY_LIGHT, WHITE } from 'styles/colors';
import BlueLuminescenceBg from 'assets/images/BlueLuminescenceBg.png';

function Avatar({ size, sizeMobile, image, name, ...restProps }) {
  const avatarImage = image || BlueLuminescenceBg;

  const LogoStyle = css`
    flex-shrink: 0;
    display: flex;
    justify-content: center;
    align-items: center;
    aspect-ratio: 1/1;
    width: ${size}px;
    height: ${size}px;
    background: url(${avatarImage});
    background-size: cover;
    border: 1px solid ${GRAY_LIGHT};
    border-radius: 50%;

    ${MEDIA_QUERIES.mobile} {
      width: ${sizeMobile || size}px;
      height: ${sizeMobile || size}px;
      font-size: ${(sizeMobile || size) * 0.466}px;
    }
  `;

  return (
    <Text
      fontSize={size * 0.466}
      fontWeight="600"
      color={WHITE}
      lineHeight={0}
      css={LogoStyle}
      {...restProps}
    >
      {!image && (name?.slice(0, 2).toUpperCase() || '')}
    </Text>
  );
}

Avatar.propTypes = {
  size: PropTypes.number.isRequired,
  sizeMobile: PropTypes.number,
  image: PropTypes.oneOfType([PropTypes.string, PropTypes.element]),
  bgColor: PropTypes.string,
  fontColor: PropTypes.string,
  name: PropTypes.string,
};

export default Avatar;
