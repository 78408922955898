/** @jsxImportSource @emotion/react */
import * as Yup from 'yup';
import {
  Form,
  useFormik,
  FormikProvider,
  useFormikContext,
} from 'formik';
import {
  useStripe,
  useElements,
  CardNumberElement,
} from '@stripe/react-stripe-js';
import { useState } from 'react';
import { ROUTES } from 'constants';
import PropTypes from 'prop-types';
import { css } from '@emotion/react';
import { useNavigate } from 'react-router-dom';
import { useDispatch, useSelector } from 'react-redux';

import APIClient from 'redux/api';
import {
  RED,
  GRAY,
  GREEN,
  WHITE,
  GRAY_DARK,
  GRAY_LIGHT,
  PURPLE_FEEK,
  GREEN_LIGHT,
  GRAY_LIGHTEST,
  GRADIENT_BLUE,
  GRADIENT_PURPLE,
} from 'styles/colors';
import Text from 'components/Text';
import { formatPrice } from 'utils/format';
import { selectCustomer } from 'redux/app';
import handleError from 'utils/handleError';
import { setCustomers } from 'redux/entities';
import Button from 'components/buttons/Button';
import { FormTextInput } from 'components/inputs/TextInput';
import { FormCardInput } from 'components/inputs/CardInput';
import PrimaryButton from 'components/buttons/PrimaryButton';
import { FormCheckboxInput } from 'components/inputs/CheckBox';

const style = css`
  box-sizing: border-box;
  display: flex;
  gap: 30px;
  width: 100%;
  max-width: 930px;
  flex-direction: column;

  & > * {
    box-sizing: border-box;
  }

  .card {
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
    width: 100%;
    border-radius: 15px;
    background-color: ${WHITE};
    box-shadow: 0px 4px 25px rgba(0, 0, 0, 0.15);
  }

  .divider {
    width: 100%;
    border-top: 1px solid ${GRAY};

    & > div:first-of-type {
      margin-top: 25px;
    }
  }

  .folder {
    flex: 2;
    box-sizing: border-box;
    .buttons {
      display: flex;
      gap: 8px;

      .button {
        opacity: 1;
        padding: 15px 20px;
        background: linear-gradient(
          180deg,
          ${WHITE} 50%,
          ${GRAY_LIGHT} 100%
        );
        border-bottom: 0.5px solid ${GRAY_LIGHT};
        border-top-left-radius: 12px;
        border-top-right-radius: 12px;

        &.--selected {
          background: ${WHITE};
          border-bottom: unset;
        }
      }
    }

    .form {
      box-sizing: border-box;
      padding: 50px;
      align-items: flex-start;
      border-top-left-radius: unset;

      .mb {
        margin-bottom: 20px;
      }

      .row {
        width: 100%;

        .label {
          margin-bottom: 10px;
        }
      }

      .grid {
        display: grid;
        grid-template-columns: repeat(2, 1fr);
        gap: 16px;
      }

      .termsBtn {
        display: inline;
        margin-left: 5px;
      }

      .ticket {
        box-sizing: border-box;
        display: grid;
        grid-template-columns: 160px 1fr;
        width: 100%;
        padding: 15px;
        background-color: ${GRAY_LIGHTEST};
        border-radius: 16px;
        border: 1px solid ${GRAY_LIGHT};
        row-gap: 10px;
        margin: 20px 0;
      }
    }
  }

  .checkout {
    flex: 1;
    height: fit-content;
    min-width: 320px;
    padding: 20px;
    gap: 20px;

    .price {
      box-sizing: border-box;
      display: flex;
      flex-direction: column;
      padding: 20px 14px;
      gap: 10px;
      border-radius: 6px;
      width: 100%;
      background: ${GRADIENT_BLUE};
    }

    .row {
      display: flex;
      justify-content: space-between;
      width: 100%;
    }

    .inputWrapper {
      width: 156px;
      display: flex;
      height: 40px;

      .couponInput {
        &.--valid {
          border-color: ${GREEN};
          background-color: ${GREEN_LIGHT};
        }

        height: 40px;
        border-radius: 8px 0 0 8px;
      }

      .disabled {
        opacity: 0.5;
      }

      .button {
        flex-shrink: 0;
        width: 36px;
        height: 40px;
        display: flex;
        justify-content: center;
        align-items: center;
        border-top-right-radius: 16px;
        border-bottom-right-radius: 16px;
        background: ${GRADIENT_PURPLE};

        & > * {
          margin-top: -5px;
        }
      }
    }
  }

  @media screen and (min-width: 769px) {
    flex-direction: row;
  }
`;

const paymentMethods = {
  CARD: {
    buttonLabel: 'Pago con Tarjeta',
    component: () => {
      const navigate = useNavigate();
      const { values } = useFormikContext();

      return (
        <>
          <Text
            align="center"
            fontSize={24}
            fontWeight="bold"
            className="row mb"
          >
            Checkout
          </Text>
          <div className="row">
            <Text fontSize={16} className="label">
              Nombre en tarjeta
            </Text>
            <FormTextInput name="cardName" />
          </div>
          <div className="row">
            <Text fontSize={16} className="label">
              Número de tarjeta
            </Text>
            <FormCardInput
              type="number"
              placeholder="XXXX XXXX XXXX XXXX"
              name="isCardNumberEmpty"
            />
          </div>
          <div className="row grid">
            <div>
              <Text fontSize={16} className="label">
                Fecha de expiración
              </Text>
              <FormCardInput
                type="expiry"
                placeholder="MM/AA"
                name="isCardExpiryEmpty"
              />
            </div>
            <div>
              <Text fontSize={16} className="label">
                CVV
              </Text>
              <FormCardInput
                type="cvc"
                placeholder="• • •"
                name="isCardCvcEmpty"
              />
            </div>
          </div>
          <FormCheckboxInput
            name="isPrivacyAccepted"
            wrapperClassName="row"
            label={
              <Text fontSize={16} type="inline">
                Acepto los
                <Button
                  className="termsBtn"
                  onClick={() =>
                    navigate(ROUTES.TERMS_AND_CONDITIONS)
                  }
                >
                  <Text
                    color={PURPLE_FEEK}
                    fontSize={16}
                    type="inline"
                  >
                    Términos y Condiciones
                  </Text>
                </Button>
              </Text>
            }
          />
          <FormCheckboxInput
            wrapperClassName="row"
            name="billable"
            label="Quiero facturar mi compra "
            labelProps={{ fontSize: 16 }}
          />
          {values.billable && (
            <div className="divider">
              <div className="row">
                <Text fontSize={16} className="label">
                  Correo electrónico
                </Text>
                <FormTextInput name="email" />
              </div>
              <div className="row">
                <Text fontSize={16} className="label">
                  Nombre o Razón Social
                </Text>
                <FormTextInput name="name" />
              </div>
              <div className="row">
                <Text fontSize={16} className="label">
                  RFC
                </Text>
                <FormTextInput name="rfc" />
              </div>
              <Text className="mb" fontSize={16} color={GRAY_LIGHT}>
                Domicilio Fiscal
              </Text>
              <div className="row grid">
                <div>
                  <Text fontSize={16} className="label">
                    Calle
                  </Text>
                  <FormTextInput name="street" />
                </div>
                <div className="column grid">
                  <div>
                    <Text fontSize={16} className="label">
                      Número Ext.
                    </Text>
                    <FormTextInput
                      type="number"
                      name="exterior_number"
                    />
                  </div>
                  <div>
                    <Text fontSize={16} className="label">
                      Número Int.
                    </Text>
                    <FormTextInput
                      type="number"
                      name="interior_number"
                      placeholder="Opcional"
                    />
                  </div>
                </div>
              </div>
              <div className="row grid">
                <div>
                  <Text fontSize={16} className="label">
                    Ciudad / Localidad
                  </Text>
                  <FormTextInput name="city" />
                </div>
                <div>
                  <Text fontSize={16} className="label">
                    Código Postal
                  </Text>
                  <FormTextInput type="number" name="cp" />
                </div>
              </div>
              <div className="row grid">
                <div>
                  <Text fontSize={16} className="label">
                    Estado / Provincia
                  </Text>
                  <FormTextInput name="state" />
                </div>
                <div>
                  <Text fontSize={16} className="label">
                    País
                  </Text>
                  <FormTextInput name="country" />
                </div>
              </div>
            </div>
          )}
        </>
      );
    },
  },
  SPEI: {
    buttonLabel: 'Pago con SPEI',
    component: ({ bank, amount, clabe, reference }) => {
      const navigate = useNavigate();
      return (
        <>
          <Text
            align="center"
            fontSize={24}
            fontWeight="bold"
            className="row mb"
          >
            Ficha de Pago con SPEI
          </Text>
          <div>
            <Text fontSize={16} lineHeight={24} className="label">
              Por favor lleva a cabo la transferencia desde el portal
              de tu banco usando los siguientes datos.
              <br />
              <br />
              Da click en el botón
              <strong> Generar Ficha de Pago</strong> para generar los
              datos de transferencia SPEI.
            </Text>
          </div>
          <div className="ticket">
            <Text fontSize={16} fontWeight="bold">
              Cantidad
            </Text>
            <Text fontSize={16} lineHeight={19}>
              {amount ? formatPrice(amount) : '—'}
            </Text>
            <Text fontSize={16} fontWeight="bold">
              CLABE
            </Text>
            <Text fontSize={16} lineHeight={19}>
              {clabe || '—'}
            </Text>
            <Text fontSize={16} fontWeight="bold">
              Banco
            </Text>
            <Text fontSize={16} lineHeight={19}>
              {bank || '—'}
            </Text>
            <Text fontSize={16} fontWeight="bold">
              Referencia
            </Text>
            <Text fontSize={16} lineHeight={19}>
              {reference || '—'}
            </Text>
          </div>
          <FormCheckboxInput
            name="isPrivacyAccepted"
            wrapperClassName="row"
            label={
              <Text fontSize={16} type="inline">
                Acepto los
                <Button
                  className="termsBtn"
                  onClick={() =>
                    navigate(ROUTES.TERMS_AND_CONDITIONS)
                  }
                >
                  <Text
                    color={PURPLE_FEEK}
                    fontSize={16}
                    type="inline"
                  >
                    Términos y Condiciones
                  </Text>
                </Button>
              </Text>
            }
          />
          <Text fontSize={13} lineHeight={20} color={GRAY_DARK}>
            Los fines de semana y día festivos no se procesan pagos
            via SPEI. Consulta los términos de tu banca online.
          </Text>
        </>
      );
    },
  },
};

const formInitialValues = {
  coupon: '',
  cardName: '',
  isCardNumberEmpty: true,
  isCardCvcEmpty: true,
  isCardExpiryEmpty: true,
  isPrivacyAccepted: true,
  billable: false,
  email: '',
  name: '',
  rfc: '',
  street: '',
  exterior_number: '',
  interior_number: '',
  city: '',
  cp: '',
  state: '',
  country: '',
  paymentMethod: 'CARD',
};

const formValidationSchema = Yup.object().shape({
  cardName: Yup.string().required('Campo requerido '),
  isCardNumberEmpty: Yup.bool().oneOf([false], 'Campo requerido'),
  isCardCvcEmpty: Yup.bool().oneOf([false], 'Campo requerido'),
  isCardExpiryEmpty: Yup.bool().oneOf([false], 'Campo requerido'),
  isPrivacyAccepted: Yup.bool().oneOf([true], 'Campo requerido'),
  email: Yup.string().when('billable', {
    is: true,
    then: Yup.string()
      .required('Campo requerido')
      .email('El email es inválido'),
  }),
  name: Yup.string().when('billable', {
    is: true,
    then: Yup.string().required('Campo requerido'),
  }),
  rfc: Yup.string().when('billable', {
    is: true,
    then: Yup.string()
      .required('Campo requerido')
      .min(12, 'Introduzca al menos 12 caracteres')
      .max(13, 'Introduzca maximo 13 caracteres'),
  }),
  street: Yup.string().when('billable', {
    is: true,
    then: Yup.string().required('Campo requerido'),
  }),
  exterior_number: Yup.string().when('billable', {
    is: true,
    then: Yup.string().required('Campo requerido'),
  }),
  city: Yup.string().when('billable', {
    is: true,
    then: Yup.string().required('Campo requerido'),
  }),
  cp: Yup.string().when('billable', {
    is: true,
    then: Yup.string().required('Campo requerido'),
  }),
  state: Yup.string().when('billable', {
    is: true,
    then: Yup.string().required('Campo requerido'),
  }),
  country: Yup.string().when('billable', {
    is: true,
    then: Yup.string().required('Campo requerido'),
  }),
});

function Checkout({ plan }) {
  const [stripeCoupon, setStripeCoupon] = useState(null);
  const [speiDataToBePayed, setSpeiDataToBePayed] = useState(null);
  const selectedCustomerId = useSelector(
    (state) => state.app.selectedCustomerId,
  );
  const navigate = useNavigate();
  const elements = useElements();
  const stripe = useStripe();
  const dispatch = useDispatch();

  const handleGetSpeiToBePayed = async () => {
    formik.setSubmitting(true);
    try {
      const res = await APIClient.getSpeiToBePayed({
        stripeProductId: plan.stripe_product_id,
        customerId: selectedCustomerId,
        coupon: formik.values.coupon,
      });
      if (res.status === 200) {
        const { amount, bank_name, clabe, reference } = res.data;
        setSpeiDataToBePayed({
          bank: bank_name,
          amount,
          clabe,
          reference,
        });
      }
    } catch (error) {
      handleError(error);
    } finally {
      formik.setSubmitting(false);
    }
  };

  const handleGetCoupon = async () => {
    formik.setSubmitting(true);
    try {
      const couponResponse = await APIClient.getValidatedCoupon(
        formik.values.coupon.trim(),
        plan.stripe_product_id,
      );

      if (couponResponse.status === 200) {
        if (formik.values.method === 'SPEI' && !!speiDataToBePayed) {
          const res = await APIClient.getSpeiToBePayed({
            stripeProductId: plan.stripe_product_id,
            customerId: selectedCustomerId,
            coupon: formik.values.coupon,
          });
          if (res.status === 200) {
            const { amount, bank_name, clabe, reference } = res.data;
            setSpeiDataToBePayed({
              bank: bank_name,
              amount,
              clabe,
              reference,
            });
          }
        }

        setStripeCoupon({
          coupon: couponResponse.data,
          isValid: true,
        });
      } else {
        throw new Error('Hubo un error procesando el pago ❌');
      }
    } catch (error) {
      if (error instanceof Error) {
        setStripeCoupon({
          coupon: { name: error.message },
          isValid: false,
        });
      } else {
        setStripeCoupon({
          coupon: { name: 'El cupón es inválido o está agotado.' },
          isValid: false,
        });
      }
    } finally {
      formik.setSubmitting(false);
    }
  };

  const handleFormSubmit = async (values, actions) => {
    actions.resetForm({ values });
    actions.setSubmitting(true);
    try {
      const stripeResponse = await stripe.createPaymentMethod({
        type: 'card',
        card: elements.getElement(CardNumberElement),
        metadata: {
          cardName: values.cardName,
        },
      });

      const { paymentMethod, error } = stripeResponse;

      if (error) {
        throw new Error(error.message);
      }

      const creditCardResponse = await APIClient.saveCreditCardMethod(
        {
          payment_method_id: paymentMethod.id,
          customer_id: selectedCustomerId,
        },
      );

      if (creditCardResponse.status !== 200) {
        throw new Error('Hubo un error procesando el pago ❌');
      }

      const suscriptionPaymentResponse =
        await APIClient.suscriptionPayment({
          ...values,
          cp: values.cp?.toString(),
          plan_id: plan.stripe_product_id,
          customer_id: selectedCustomerId,
        });

      if (suscriptionPaymentResponse.status === 201) {
        await APIClient.getCustomers().then(({ data }) => {
          const customers = data.reduce(
            (prev, crr) => ({ ...prev, [crr.customer._id]: crr }),
            {},
          );
          if (!(selectedCustomerId in customers)) {
            dispatch(selectCustomer(data[0].customer._id));
          }
          dispatch(setCustomers(customers));
        });
        navigate(ROUTES.SUBSCRIPTION_SUCCESS);
      }
    } catch (error) {
      handleError(error);
    } finally {
      actions.setSubmitting(false);
    }
  };

  const formik = useFormik({
    initialValues: formInitialValues,
    onSubmit: handleFormSubmit,
    validationSchema: formValidationSchema,
    validateOnMount: true,
  });

  const discount =
    stripeCoupon?.coupon?.amount_off / 100 ||
    ((plan?.price + (plan?.taxes || 0)) *
      (stripeCoupon?.coupon?.percent_off || 0)) /
      100 ||
    0;

  const total = plan?.price + plan?.taxes - discount;
  const paymentMethod = formik.values.paymentMethod;

  const CurrentPaymentMethod =
    paymentMethods[paymentMethod].component;

  const handleChangePaymentMethod = (method) => {
    setStripeCoupon(null);
    setSpeiDataToBePayed(null);
    formik.resetForm({
      values: { ...formInitialValues, paymentMethod: method },
    });
  };

  return (
    <FormikProvider value={formik}>
      <Form css={style}>
        <div className="folder">
          <div className="buttons">
            {Object.entries(paymentMethods)
              .slice(0, 1)
              .map(([method, { buttonLabel }], i) => (
                <Button
                  key={i}
                  className={`button ${
                    paymentMethod === method ? '--selected' : ''
                  }`}
                  onClick={() => handleChangePaymentMethod(method)}
                  disabled={method === paymentMethod}
                >
                  <Text
                    align="center"
                    fontSize={14}
                    lineHeight={16.8}
                    fontWeight={paymentMethod === method ? 600 : 400}
                  >
                    {buttonLabel}
                  </Text>
                </Button>
              ))}
          </div>
          <div className="form card">
            <CurrentPaymentMethod
              {...(speiDataToBePayed && { ...speiDataToBePayed })}
            />
          </div>
        </div>
        <div className="checkout card">
          <div className="price">
            <Text color={WHITE} fontSize={20} fontWeight="bold">
              {plan.name}
            </Text>
            <Text color={WHITE} fontSize={50} fontWeight="bold">
              $
              {plan.price > 1000
                ? Math.floor(plan.price / 1000) +
                  ',' +
                  plan.price.toString().slice(1)
                : plan.price}
            </Text>
            <Text color={WHITE} fontSize={18}>
              costo mensual
            </Text>
          </div>
          <Text fontSize={14} className="row">
            IVA(16%){' '}
            <span>
              $
              {plan.taxes > 1000
                ? Math.floor(plan.taxes / 1000) +
                  ',' +
                  plan.taxes.toString().slice(1)
                : plan.taxes}
            </span>
          </Text>
          <div className="row">
            <Text fontSize={14}>Cupón de descuento</Text>
            <div className="inputWrapper">
              <FormTextInput
                name="coupon"
                autoComplete="off"
                inputClassName={`couponInput ${
                  stripeCoupon?.isValid ? 'input --valid' : ''
                }`}
                onChange={() =>
                  !stripeCoupon?.isValid && setStripeCoupon(null)
                }
                {...(stripeCoupon?.coupon &&
                  !stripeCoupon?.isValid && { error: true })}
              />
              <Button
                className={
                  formik.isSubmitting ||
                  !formik.values.coupon ||
                  formik.errors.coupon
                    ? 'button disabled'
                    : 'button'
                }
                onClick={handleGetCoupon}
                disabled={
                  formik.isSubmitting ||
                  !formik.values.coupon ||
                  formik.errors.coupon
                }
              >
                <Text color={WHITE} fontSize={20}>
                  →
                </Text>
              </Button>
            </div>
          </div>
          {stripeCoupon?.coupon && (
            <>
              <Text
                fontSize={14}
                className="row"
                color={stripeCoupon.isValid ? GREEN : RED}
              >
                {stripeCoupon.coupon?.name}
              </Text>
              {stripeCoupon.isValid && (
                <Text fontSize={14} className="row">
                  Descuento{' '}
                  {stripeCoupon.coupon.percent_off
                    ? `(${stripeCoupon.coupon.percent_off}%)`
                    : `($${
                        stripeCoupon.coupon.amount_off > 100000
                          ? Math.floor(
                              stripeCoupon.coupon.amount_off / 100000,
                            ) +
                            ',' +
                            (stripeCoupon.coupon.amount_off / 100)
                              .toString()
                              .slice(1)
                          : stripeCoupon.coupon.amount_off / 100
                      }
                      )`}
                  <span>
                    -$
                    {discount > 1000
                      ? Math.floor(discount / 1000) +
                        ',' +
                        discount.toString().slice(1)
                      : discount}
                  </span>
                </Text>
              )}
            </>
          )}
          <div className="divider" />
          <Text fontSize={14} className="row">
            <b>Total</b>
            <span>
              $
              {total > 1000
                ? Math.floor(total / 1000) +
                  ',' +
                  total.toString().slice(1)
                : total}
            </span>
          </Text>

          <PrimaryButton
            label={
              paymentMethod === 'SPEI'
                ? 'Generar Ficha de Pago'
                : 'Contratar Plan'
            }
            onClick={
              paymentMethod === 'SPEI'
                ? handleGetSpeiToBePayed
                : formik.submitForm
            }
            disabled={
              (paymentMethod !== 'SPEI'
                ? Object.keys(formik.touched).length === 0 ||
                  !formik.isValid
                : !!speiDataToBePayed) || formik.isSubmitting
            }
          />
        </div>
      </Form>
    </FormikProvider>
  );
}
Checkout.propTypes = {
  plan: PropTypes.object,
};

export default Checkout;
