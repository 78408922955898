/** @jsxImportSource @emotion/react */
import * as Yup from 'yup';
import { useEffect } from 'react';
import moment from 'moment/moment';
import { css } from '@emotion/react';
import { Form, Formik } from 'formik';
import { useSelector } from 'react-redux';
import { useNavigate, useSearchParams } from 'react-router-dom';

import APIClient from 'redux/api';
import { ROUTES } from 'constants';
import Icon from 'components/Icon';
import Text from 'components/Text';
import handleError from 'utils/handleError';
import { MEDIA_QUERIES } from 'styles/constants';
import Step1Type from 'screens/AddCampaign/Step1Type';
import Step3Details from 'screens/AddCampaign/Step3Details';
import Step5Success from 'screens/AddCampaign/Step5Success';
import LinearProgressBar from 'components/LinearProgressBar';
import PrimaryButton from 'components/buttons/PrimaryButton';
import { GRAY_LIGHTER, PURPLE_FEEK, WHITE } from 'styles/colors';
import Step4Reservation from 'screens/AddCampaign/Step4Reservation';
import HeaderCampaignInfo from 'screens/AddCampaign/HeaderCampaignInfo';
import Step2Participation from 'screens/AddCampaign/Step2Participation';
import { useProfileCompletedStatus } from 'hooks/useProfileCompletedStatus';
import { ReactComponent as ChevronLeft } from 'assets/icons/ChevronLeft.svg';
import { ReactComponent as ChevronRight } from 'assets/icons/ChevronRight.svg';

const styles = css`
  padding: 45px 40px;
  background-color: ${GRAY_LIGHTER}40;

  &.success {
    padding: 0;
  }

  .form {
    flex: 1;
    height: 100%;
    display: flex;
    gap: 30px;
    flex-direction: column;
    justify-content: space-between;
  }

  .mobileOnly {
    display: none;
  }

  .stepsNavigation {
    display: grid;
    gap: 5px;
    grid-template-columns: repeat(3, minmax(0, 220px));
    grid-template-areas: '. progressBar buttonR';
    justify-content: space-between;

    > * {
      height: 50px;
      margin: 0;
      padding: 0;
    }

    .progressBarContainer {
      grid-area: progressBar;

      .bar {
        height: 10px;
        margin-top: 20px;
      }
    }

    .buttonNext {
      grid-area: buttonR;
    }
  }

  ${MEDIA_QUERIES.mobile} {
    gap: 30px;
    padding: 40px 24px;

    .mobileOnly {
      display: block;
    }

    .form {
      justify-content: initial;
    }

    .stepsNavigation {
      display: grid;
      grid-template-columns: repeat(2, minmax(0, 220px));
      grid-template-rows: repeat(2, auto);
      grid-template-areas:
        'progressBar progressBar'
        'buttonL buttonR';
      gap: 30px 5px;

      .progressBarContainer {
        width: 50vw;
        justify-self: center;
      }
    }
  }
`;

const validationSchema = Yup.object().shape({
  discount: Yup.number().when(['type', 'step'], (type, step) => {
    if (step !== 0) return;
    if (type === 'DISCOUNT_PERCENTAGE') {
      return Yup.number().required().min(10).max(99);
    } else if (type === 'MONETARY_DISCOUNT') {
      return Yup.number().required().min(10).max(9999);
    }
  }),
  type: Yup.string().when('step', {
    is: 0,
    then: (schema) => schema.required(),
  }),
  participation: Yup.string().when('step', {
    is: 1,
    then: (schema) => schema.required(),
  }),
  title: Yup.string().when('step', {
    is: 2,
    then: (schema) => schema.required('El título es requerido'),
  }),
  task: Yup.array().when('step', {
    is: 2,
    then: (schema) =>
      schema.test(
        'one-not-empty',
        'Al menos un campo debe ser llenado',
        function (value) {
          return value?.some((item) => !!item);
        },
      ),
  }),
  description: Yup.string().when('step', {
    is: 2,
    then: (scheme) => scheme.required('La descripción es requerida'),
  }),
  startDate: Yup.date().when(['step', 'update_id'], {
    is: (step, update_id) => step === 3 && !update_id,
    then: (scheme) =>
      scheme
        .required('La fecha inicial es requerida')
        .min(
          moment().format('YYYY-MM-DD'),
          'La fecha de inicio de campaña no puede ser anterior a hoy',
        ),
  }),
  days: Yup.array().when('step', {
    is: 3,
    then: (schema) =>
      schema
        .required('Al menos un día debe ser seleccionado')
        .min(1, 'Al menos un día debe ser seleccionado'),
  }),
  image: Yup.string().when('step', {
    is: 3,
    then: (schema) => schema.required('La imagen es requerida'),
  }),
});

function AddCampaign() {
  const { selectedCustomerId } = useSelector((state) => state.app);
  const { isProfileCompleted } = useProfileCompletedStatus();
  const { customers } = useSelector((state) => state.entities);
  const places = customers[selectedCustomerId]?.customer.places;
  const navigate = useNavigate();

  const [searchParams, setSearchParams] = useSearchParams();
  const campaigns = useSelector((state) => state.app.campaigns);
  const campaignToEdit = campaigns?.find(
    (item) =>
      item?._id === searchParams.get('update_id') ||
      item?._id === searchParams.get('clone_id'),
  );
  const stepToInit = searchParams.get('step');

  useEffect(() => {
    if ((!isProfileCompleted || places === 0) && !campaignToEdit)
      navigate(ROUTES.HOME);
  }, [isProfileCompleted, places, campaignToEdit]);

  const steps = [
    Step1Type,
    Step2Participation,
    Step3Details,
    Step4Reservation,
    Step5Success,
  ];

  const initialValues = {
    update_id: searchParams.get('update_id') || undefined,
    type: campaignToEdit?.type.type || '',
    discount: campaignToEdit?.discount || 0,
    participation: campaignToEdit?.participation || '',
    description: campaignToEdit?.description || '',
    title: campaignToEdit?.title || '',
    task: campaignToEdit?.task?.suggestions || [],
    extraTask: campaignToEdit?.extra_points?.suggestions || [],
    days: campaignToEdit?.days.map((item) => item.day) || [],
    startDate: (campaignToEdit?.start_date
      ? searchParams.get('clone_id')
        ? moment(campaignToEdit?.start_date).diff(moment()) > 0
          ? moment(campaignToEdit?.start_date)
          : moment()
        : moment(campaignToEdit?.start_date)
      : moment()
    ).format('YYYY-MM-DD'),
    endTime: campaignToEdit?.end_time || '12:00',
    startTime: campaignToEdit?.start_time || '12:00',
    image: campaignToEdit?.image || '',
    status: 'En curso',

    step: stepToInit ? parseInt(stepToInit) : 0,
  };
  const handleFormSubmit = async (values, actions) => {
    const {
      extraTask,
      task,
      startDate,
      startTime,
      endTime,
      ...rest
    } = values;
    const payload = Object.fromEntries(
      Object.entries({
        ...rest,
        extra_task: extraTask.filter((item) => !!item),
        task: task.filter((item) => !!item),
        customer_id: selectedCustomerId,
        start_date: startDate,
        start_time: startTime,
        end_time: endTime,
      }).map(([key, value]) => {
        if (
          (typeof value === 'string' || Array.isArray(value)) &&
          value.length === 0
        )
          return [key, null];
        return [key, value];
      }),
    );

    actions.setSubmitting(true);
    try {
      const res = !searchParams.get('update_id')
        ? await APIClient.createCampaign(payload)
        : await APIClient.updateCampaign(campaignToEdit._id, payload);

      if (res.status === 201 || res.status === 200) {
        actions.setSubmitting(false);
        actions.setFieldValue('step', values.step + 1);
      } else {
        throw new Error('Hubo un error inesperado');
      }
    } catch (error) {
      actions.setSubmitting(false);
      handleError(error);
    }
  };

  const handleNext = (formik) => {
    formik.setFieldValue('step', formik.values.step + 1);
  };

  const handleBack = (formik) => {
    formik.setFieldValue('step', formik.values.step - 1);
  };

  const handleResetForm = (formik) => {
    setSearchParams('');
    formik.resetForm({
      values: initialValues,
      errors: { type: 'required' },
    });
  };

  const handleInitForm = (formik) => {
    formik.resetForm({
      values: { ...formik.values, step: 0 },
    });
  };

  const getStep = (formik) => {
    const CurrentStep = steps[formik.values.step];

    return (
      <CurrentStep
        handleResetForm={() => handleResetForm(formik)}
        handleInitForm={() => handleInitForm(formik)}
      />
    );
  };

  return (
    <Formik
      validateOnMount
      enableReinitialize
      onSubmit={handleFormSubmit}
      initialValues={initialValues}
      validationSchema={validationSchema}
    >
      {(formik) => (
        <div
          css={styles}
          {...(formik.values.step === steps.length - 1 && {
            className: 'success',
          })}
        >
          <Form className="form">
            {formik.values.step < steps.length - 1 && (
              <>
                <Text fontSize={20} className="mobileOnly">
                  Crear campaña
                </Text>
                <HeaderCampaignInfo />
              </>
            )}
            {getStep(formik)}
            {formik.values.step < steps.length - 1 && (
              <div className="stepsNavigation">
                {formik.values.step > 0 && (
                  <PrimaryButton
                    onClick={() => handleBack(formik)}
                    icon={
                      <Icon
                        color={PURPLE_FEEK}
                        icon={ChevronLeft}
                        size={9.6}
                      />
                    }
                    strokeVariant
                    fontSize={18}
                    label="Anterior"
                    className="buttonBack"
                  />
                )}
                <Text
                  fontSize={16}
                  fontWeight="600"
                  align="center"
                  className="progressBarContainer"
                >
                  Paso {formik.values.step + 1} de {steps.length - 1}
                  <LinearProgressBar
                    progress={
                      ((formik.values.step + 1) * 100) /
                      (steps.length - 1)
                    }
                    className="bar"
                  />
                </Text>
                <PrimaryButton
                  onClick={() =>
                    formik.values.step < 3
                      ? handleNext(formik)
                      : formik.submitForm()
                  }
                  disabled={!formik.isValid || formik.isSubmitting}
                  icon={
                    <Icon
                      color={WHITE}
                      icon={ChevronRight}
                      size={9.6}
                    />
                  }
                  fontSize={18}
                  label={
                    formik.values.step === steps.length - 2
                      ? searchParams.get('update_id')
                        ? 'Guardar cambios'
                        : !searchParams.get('clone_id')
                        ? 'Crear campaña'
                        : 'Clonar campaña'
                      : 'Siguiente'
                  }
                  iconPosition="right"
                  className="buttonNext"
                />
              </div>
            )}
          </Form>
        </div>
      )}
    </Formik>
  );
}
export default AddCampaign;
