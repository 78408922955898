import React from 'react';
import { BrowserRouter, Routes, Route } from 'react-router-dom';

import Home from 'screens/Home';
import Share from 'screens/Share';
import Login from 'screens/Login';
import { ROUTES } from 'constants';
import Profile from 'screens/Profile';
import Payment from 'screens/Payment';
import NotFound from 'screens/NotFound';
import Campaigns from 'screens/Campaigns';
import Dashboard from 'screens/Dashboard';
import AddCampaign from 'screens/AddCampaign';
import Billing from 'screens/Payment/Billing';
import OutletMain from 'components/OutletMain';
import Registration from 'screens/Registration';
import Collaborators from 'screens/Collaborators';
import ForgotPassword from 'screens/ForgotPassword';
import PaymentHistory from 'screens/PaymentsHistory';
import BusinessProfile from 'screens/BusinessProfile';
import Policy from 'screens/TermsAndConditions/Policy';
import TermsAndConditions from 'screens/TermsAndConditions';
import { PrivateRoute, PublicRoute } from 'components/Routes';
import CollaboratorNewPassword from 'screens/CollaboratorNewPassword';
import RegistrationBussinesUnit from 'screens/Registration/RegistrationBussinesUnit';
import SubscriptionCustomerSuccess from 'screens/Payment/SubscriptionCustomerSuccess';
import RegistrationCustomerSuccess from 'screens/Registration/RegistrationCustomerSuccess';
import RegistrationCustomerProfile from 'screens/Registration/RegistrationCustomerProfile';
import RegistrationVerificationEmailCode from 'screens/Registration/RegistrationVerificationEmailCode';
import RegistrationVerificationPhoneCode from 'screens/Registration/RegistrationVerificationPhoneCode';

const RootNavigator = () => (
  <BrowserRouter>
    <Routes>
      {/* Auth agnostic */}
      <Route path="*" element={<NotFound />} />

      <Route
        path="/"
        element={
          <PublicRoute>
            <Login />
          </PublicRoute>
        }
      />

      <Route
        path="/"
        element={
          <PrivateRoute>
            <OutletMain />
          </PrivateRoute>
        }
      >
        <Route
          path={ROUTES.COLLABORATORS}
          element={<Collaborators />}
        />
        <Route path={ROUTES.CAMPAIGNS} element={<Campaigns />} />
        <Route
          path={ROUTES.ADD_CAMPAIGNS}
          element={<AddCampaign />}
        />
        <Route
          path={ROUTES.UPDATE_CAMPAIGNS}
          element={<AddCampaign />}
        />
        <Route
          path={ROUTES.PAYMENT_HISTORY}
          element={<PaymentHistory />}
        />
        <Route path={ROUTES.PROFILE} element={<Profile />} />
        <Route path={ROUTES.HOME} element={<Home />} />
        <Route path={ROUTES.DASHBOARD} element={<Dashboard />} />
        <Route
          path={ROUTES.BUSINESS_PROFILE}
          element={<BusinessProfile />}
        />
        <Route path={ROUTES.BILLING} element={<Billing />} />
      </Route>

      <Route path={ROUTES.POLICY} element={<Policy />} />

      <Route
        path={ROUTES.TERMS_AND_CONDITIONS}
        element={<TermsAndConditions />}
      />
      <Route exact path={ROUTES.SHARE} element={<Share />} />
      <Route exact path={ROUTES.SHARE_PARAMS} element={<Share />} />
      <Route
        path={ROUTES.COLLABORATOR_NEW_PASSWORD}
        element={<CollaboratorNewPassword />}
      >
        <Route
          path={':account_invite'}
          element={<CollaboratorNewPassword />}
        />
      </Route>

      {/* Public */}
      <Route
        exact
        path={ROUTES.REGISTRATION}
        element={
          <PublicRoute>
            <Registration />
          </PublicRoute>
        }
      />

      <Route
        exact
        path={ROUTES.REGISTRATION_VERIFICATION_CODE_EMAIL}
        element={
          <PublicRoute>
            <RegistrationVerificationEmailCode />
          </PublicRoute>
        }
      />

      <Route
        exact
        path={ROUTES.REGISTRATION_VERIFICATION_CODE_PHONE}
        element={
          <PublicRoute>
            <RegistrationVerificationPhoneCode />
          </PublicRoute>
        }
      />

      <Route
        exact
        path={ROUTES.LOGIN}
        element={
          <PublicRoute>
            <Login />
          </PublicRoute>
        }
      />
      <Route
        exact
        path={ROUTES.FORGOT_PASSWORD}
        element={
          <PublicRoute>
            <ForgotPassword />
          </PublicRoute>
        }
      />

      {/* Private */}
      <Route
        path={ROUTES.PAYMENT}
        element={
          <PrivateRoute>
            <Payment />
          </PrivateRoute>
        }
      />
      <Route
        path={ROUTES.SUBSCRIPTION_SUCCESS}
        element={
          <PrivateRoute>
            <SubscriptionCustomerSuccess />
          </PrivateRoute>
        }
      />
      <Route
        exact
        path={ROUTES.REGISTRATION_PROFILE}
        element={
          <PrivateRoute>
            <RegistrationCustomerProfile />
          </PrivateRoute>
        }
      />
      <Route
        exact
        path={ROUTES.REGISTRATION_PROFILE_SUCESS}
        element={
          <PrivateRoute>
            <RegistrationCustomerSuccess />
          </PrivateRoute>
        }
      />
      <Route
        exact
        path={ROUTES.REGISTRATION_BUSINESS_UNIT}
        element={
          <PrivateRoute>
            <RegistrationBussinesUnit />
          </PrivateRoute>
        }
      />
    </Routes>
  </BrowserRouter>
);

export default RootNavigator;
