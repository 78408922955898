/** @jsxImportSource @emotion/react */
import PropTypes from 'prop-types';
import { css } from '@emotion/react';
import { useState, useEffect } from 'react';
import { useSelector, useDispatch } from 'react-redux';

import {
  WHITE,
  GRAY_DARK,
  PURPLE_FEEK,
  GRADIENT_PURPLE_LIGHT,
} from 'styles/colors';
import APIClient from 'redux/api';
import Text from 'components/Text';
import Icon from 'components/Icon';
import { selectCustomer } from 'redux/app';
import Button from 'components/buttons/Button';
import { MEDIA_QUERIES } from 'styles/constants';
import { setCustomers, setPlans } from 'redux/entities';
import ModalChangePlan from 'components/ModalChangePlan';
import PrimaryButton from 'components/buttons/PrimaryButton';
import { ReactComponent as Info } from 'assets/icons/Info.svg';
import { ReactComponent as Check } from 'assets/icons/Check.svg';
import ModalCancelSubcription from 'components/ModalCancelSubcription';

const style = css`
  box-sizing: border-box;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: space-between;
  flex: 1;

  .plans {
    display: flex;
    gap: 30px;
    justify-content: center;
    flex-wrap: nowrap;
  }

  .card {
    flex: 0 1 minmax(calc((100% - 60px) / 3), 316px);
    display: flex;
    flex-direction: column;
    justify-content: space-between;
    align-items: center;
    gap: 40px;
    padding: 40px 20px;
    margin-bottom: 20px;
    background-color: ${WHITE};
    border-radius: 8px;
    box-shadow: 0px 4px 25px rgba(0, 0, 0, 0.15);

    .title {
      margin-bottom: 12px;
    }

    .description {
      padding: 0 20px;
    }

    .priceWrapper {
      display: flex;
      flex-direction: column;
      justify-content: center;
      align-items: center;
      .price {
        display: flex;
        align-items: center;
      }
    }

    .benefitItem {
      display: flex;
      gap: 10px;
      align-items: center;
      width: fit-content;
      margin-bottom: 15px;

      &:first-of-type div {
        font-weight: bold;
      }
    }

    .primary-button {
      width: 100%;
      padding: 8px;
      margin-top: 12px;
      border: 1px solid ${PURPLE_FEEK};
      border-radius: 15px;
    }
  }

  .footer {
    width: 100%;
    display: flex;
    align-items: center;
    justify-content: space-between;
    gap: 20px;
  }

  .info-text-container {
    display: flex;
    width: 50vw;
    gap: 20px;
    align-items: center;
    justify-content: space-between;
    gap: 10px;
  }

  .cancel-link {
    text-align: right;
  }

  @media only screen and (max-width: 1400px) {
    .footer {
      justify-content: center;
    }
  }

  ${MEDIA_QUERIES.mobile} {
    .plans {
      flex-direction: column;
    }

    .footer {
      flex-direction: column;
    }

    .info-text-container {
      width: 80vw;
    }
  }
`;

function PlanCard({ plan, handleNext, isActive }) {
  return (
    <div className="card">
      <Text
        fontSize={24}
        fontWeight="bold"
        align="center"
        className="title"
      >
        {plan?.name}
      </Text>

      <div className="priceWrapper">
        <div className="price">
          <Text fontSize={50} fontWeight="bold">
            $
            {plan.price > 1000
              ? Math.floor(plan.price / 1000) +
                ',' +
                plan.price.toString().slice(1)
              : plan.price}
          </Text>
          <Text fontSize={15}>+IVA</Text>
        </div>

        <Text color={GRAY_DARK} fontSize={18} type="inline">
          costo <b>mensual</b>
        </Text>
      </div>

      <div>
        {plan.benefits.map((benefit) => (
          <div className="benefitItem" key={benefit._id}>
            <Icon icon={Check} color={PURPLE_FEEK} size={10} />
            <Text fontSize={15}>{benefit.description}</Text>
          </div>
        ))}
      </div>

      {isActive ? (
        <PrimaryButton
          label="Este es tu plan contratado"
          color={GRADIENT_PURPLE_LIGHT}
          textColor={PURPLE_FEEK}
          textProps={{
            fontSize: 16,
            fontWeight: 600,
          }}
          className="primary-button"
        />
      ) : (
        <PrimaryButton
          onClick={handleNext}
          label="Elegir plan"
          textColor={WHITE}
          textProps={{
            fontSize: 16,
            fontWeight: 600,
          }}
          className="primary-button"
        />
      )}
    </div>
  );
}

function PlanSelection({ handleNext }) {
  const dispatch = useDispatch();
  const plans = Object.values(
    useSelector((state) => state.entities.plans),
  );

  const customers = useSelector((state) => state.entities.customers);
  const selectedCustomerId = useSelector(
    (state) => state.app.selectedCustomerId,
  );
  const selectedCustomer = customers[selectedCustomerId]?.customer;

  useEffect(() => {
    if (plans.length === 0) {
      APIClient.getPlans()
        .then(({ data }) => {
          dispatch(setPlans(data));
        })
        .catch((e) => console.error(e));
    }

    APIClient.getCustomers()
      .then(({ data }) => {
        const customers = data.reduce(
          (prev, crr) => ({ ...prev, [crr.customer._id]: crr }),
          {},
        );
        if (!(selectedCustomerId in customers)) {
          dispatch(selectCustomer(data[0].customer._id));
        }
        dispatch(setCustomers(customers));
      })
      .catch((e) => console.error(e));
  }, []);

  const [
    isModalCancelSubcriptionOpen,
    setIsModalCancelSubcriptionOpen,
  ] = useState(false);

  const [isModalChangePlanOpen, setIsModalChangePlanOpen] =
    useState(false);
  const [planSelected, setPlanSelected] = useState(undefined);

  const handleSelectPlan = (plan) => {
    if (selectedCustomer?.suscription_active) {
      setIsModalChangePlanOpen(true);
      setPlanSelected(plan);
    } else {
      handleNext(plan);
    }
  };

  return (
    <div css={style}>
      <ModalCancelSubcription
        isOpen={isModalCancelSubcriptionOpen}
        handleClose={() => setIsModalCancelSubcriptionOpen(false)}
      />

      <ModalChangePlan
        isOpen={isModalChangePlanOpen}
        handleClose={() => setIsModalChangePlanOpen(false)}
        planSelected={planSelected}
        customerPlan={selectedCustomer?.plan_id}
      />

      <div className="plans">
        {plans.map((plan) => {
          return (
            <PlanCard
              key={plan._id}
              plan={plan}
              handleNext={() => handleSelectPlan(plan)}
              isActive={
                selectedCustomer?.suscription_active &&
                plan._id === selectedCustomer?.plan_id?._id
              }
            />
          );
        })}
      </div>

      <div className="footer">
        <div className="info-text-container">
          <Icon icon={Info} color={WHITE} size={30} />
          <Text fontSize={10} color={WHITE}>
            *Nota: El plan es prepago, es decir, pagas el valor
            integral del servicio en el momento de la compra. Los
            servicios, herramientas y configuraciones incluidos en tu
            compra, corresponden al plan previamente seleccionado.
          </Text>
        </div>

        {selectedCustomer?.suscription_active && (
          <Button
            onClick={() => {
              setIsModalCancelSubcriptionOpen(true);
            }}
          >
            <Text color={WHITE} fontSize={13} className="cancel-link">
              ¿Deseas cancelar tu suscripción?
            </Text>
          </Button>
        )}
      </div>
    </div>
  );
}

PlanCard.propTypes = {
  plan: PropTypes.object,
  handleNext: PropTypes.func,
  isActive: PropTypes.bool,
};

PlanSelection.propTypes = {
  handleNext: PropTypes.func,
};

export default PlanSelection;
