/** @jsxImportSource @emotion/react */
import { Field } from 'formik';
import { forwardRef } from 'react';
import PropTypes from 'prop-types';
import { css } from '@emotion/react';

import {
  GRAY,
  WHITE,
  RED,
  GRAY_DARK,
  RED_LIGHT,
  GRAY_LIGHT,
  PURPLE_FEEK,
  GRAY_LIGHTER,
  PURPLE_INACTIVE,
} from 'styles/colors';
import Text from 'components/Text';
import { defaultFont } from 'styles/fonts';
import { forwardInputEventHandlers } from 'utils/form.utils';

const ignoredKeys = ['e', 'E', '-', '+', '.'];

const TextInput = forwardRef((props, ref) => {
  const {
    error,
    onKeyDown,
    className,
    inputClassName,
    ...restProps
  } = props;

  const styles = css`
    box-sizing: border-box;
    width: 100%;

    & > * {
      box-sizing: border-box;
    }

    .error-text {
      min-height: 19px;
      margin: 3px 0;
    }

    input {
      height: 60px;
      width: 100%;
      color: ${GRAY};
      border-radius: 16px;
      padding: 0 19px;
      border: 1px solid ${error ? RED : PURPLE_INACTIVE};
      background-color: ${error ? RED_LIGHT : WHITE};
      font-family: ${defaultFont};
      font-size: 16px;
      outline: none;

      &:-webkit-autofill {
        -webkit-text-fill-color: ${GRAY};
        background-color: ${error ? RED_LIGHT : WHITE} !important;
        box-shadow: inset 0 0 0 1px rgb(255 255 255 / 0%),
          inset 0 0 0 1000px ${WHITE};
      }

      &:focus {
        border-color: ${error ? RED : PURPLE_FEEK};
      }

      &::placeholder {
        color: ${GRAY_LIGHT};
      }

      &[disabled] {
        background: ${GRAY_LIGHTER};
        color: ${GRAY_DARK};
        cursor: not-allowed;
      }

      &::-webkit-outer-spin-button,
      &::-webkit-inner-spin-button {
        -webkit-appearance: none;
      }
    }
  `;

  const handleKeyDown = (e) => {
    if (ignoredKeys.includes(e.key) && restProps.type === 'number')
      e.preventDefault();

    onKeyDown?.(e);
  };

  return (
    <div ref={ref} css={styles} className={className}>
      <input
        type="text"
        className={inputClassName}
        {...restProps}
        onKeyDown={handleKeyDown}
      />
      <Text
        fontSize={14}
        lineHeight={17}
        fontWeight="500"
        color={RED}
        className="error-text"
      >
        {typeof error === 'string' ? error : ''}
      </Text>
    </div>
  );
});

export const FormTextInput = (props) => {
  const { name, error, ...restProps } = props;

  return (
    <Field name={name}>
      {({ meta, field }) => (
        <TextInput
          name={name}
          value={meta.value}
          error={meta.touched && (meta.error || error)}
          {...restProps}
          {...forwardInputEventHandlers(restProps, field)}
        />
      )}
    </Field>
  );
};

FormTextInput.propTypes = {
  name: PropTypes.string,
  error: PropTypes.oneOfType([PropTypes.string, PropTypes.bool]),
};

TextInput.propTypes = {
  error: PropTypes.oneOfType([PropTypes.string, PropTypes.bool]),
  onKeyDown: PropTypes.func,
  className: PropTypes.string,
  inputClassName: PropTypes.string,
};

TextInput.displayName = 'Button';

export default TextInput;
