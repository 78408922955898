import _ from 'lodash';
import { createSlice } from '@reduxjs/toolkit';

const initialState = {
  plans: {},
  roles: {},
  cards: {},
  customers: {},
  categories: {},
  campaignTypes: {},
  customersInvitations: {},
};

export const entitiesSlice = createSlice({
  name: 'entities',
  initialState,
  reducers: {
    setPlans: (state, action) => {
      _.mergeWith(
        state.plans,
        _.keyBy(action.payload, '_id'),
        (a, b) => (_.isArray(a) ? b : undefined),
      );
    },
    setRoles: (state, action) => {
      _.mergeWith(
        state.roles,
        _.keyBy(action.payload, '_id'),
        (a, b) => (_.isArray(a) ? b : undefined),
      );
    },
    setCategories: (state, action) => {
      _.mergeWith(
        state.categories,
        _.keyBy(action.payload, '_id'),
        (a, b) => (_.isArray(a) ? b : undefined),
      );
    },
    setCampaignTypes: (state, action) => {
      _.mergeWith(
        state.campaignTypes,
        _.keyBy(action.payload, '_id'),
        (a, b) => (_.isArray(a) ? b : undefined),
      );
    },
    setCustomers: (state, action) => {
      _.mergeWith(
        state.customers,
        _.keyBy(action.payload, 'customer._id'),
        (a, b) => (_.isArray(a) ? b : undefined),
      );
    },
    removeCustomer: (state, action) => {
      _.unset(state.customers, action.payload);
    },
    setCustomersInvitations: (state, action) => {
      _.mergeWith(
        state.customersInvitations,
        _.keyBy(action.payload, 'customer._id'),
        (a, b) => (_.isArray(a) ? b : undefined),
      );
    },
    removeCustomersInvitations: (state, action) => {
      _.unset(state.customersInvitations, action.payload);
    },
    setCardByCustomer: (state, action) => {
      _.mergeWith(
        state.cards,
        {
          [action.payload.customerId]: action.payload.card,
        },
        (a, b) => (_.isArray(a) ? b : undefined),
      );
    },
    setCustomerCollaborator: (state, action) => {
      _.set(
        state.customers,
        `${action.payload.customerId}.customer.collaborators`,
        [
          ...state.customers[action.payload.customerId].customer
            .collaborators,
          action.payload.collaborator,
        ],
      );
    },
    updateCustomerCollaborator: (state, action) => {
      _.set(
        state.customers,
        `${action.payload.customerId}.customer.collaborators`,
        state.customers[
          action.payload.customerId
        ].customer.collaborators.map((item) =>
          item._id !== action.payload.collaborator._id
            ? item
            : action.payload.collaborator,
        ),
      );
    },
    removeCustomerCollaborator: (state, action) => {
      _.set(
        state.customers,
        `${action.payload.customerId}.customer.collaborators`,
        state.customers[
          action.payload.customerId
        ].customer.collaborators.filter(
          (item) => item._id !== action.payload.collaboratorId,
        ),
      );
    },
    setCustomerSubscritionCancelled: (state, action) => {
      _.set(
        state.customers,
        `${action.payload}.customer.suscription_active`,
        false,
      );
    },
    setCustomerPlan: (state, action) => {
      _.set(
        state.customers,
        `${action.payload.customerId}.customer.plan_id`,
        action.payload.planId,
      );
    },
    setCustomerBilling: (state, action) => {
      _.set(
        state.customers,
        `${action.payload.customerId}.customer.billing`,
        action.payload.billing,
      );
    },
  },
});

export const {
  setPlans,
  setRoles,
  setCustomers,
  setCategories,
  removeCustomer,
  setCustomerPlan,
  setCampaignTypes,
  setCardByCustomer,
  setCustomerBilling,
  setCustomersInvitations,
  setCustomerCollaborator,
  removeCustomersInvitations,
  removeCustomerCollaborator,
  updateCustomerCollaborator,
  setCustomerSubscritionCancelled,
} = entitiesSlice.actions;

export default entitiesSlice.reducer;
