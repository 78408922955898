export const MEDIA_QUERIES = Object.freeze({
  /*
    Use example:
  
    ${MEDIA_QUERIES.mobile} {
      .background {
        display: none;
      }
    }
  */

  mobile: '@media (max-width: 520px)',
  tablet: '@media (max-width: 768px)',
  smallScreen: '@media (max-width: 1200px)',
});
