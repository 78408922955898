/** @jsxImportSource @emotion/react */
import PropTypes from 'prop-types';
import { css } from '@emotion/react';

import Text from 'components/Text';
import { GRAY_LIGHT, PURPLE_FEEK } from 'styles/colors';

function PasswordSecurityLevel({ value, maxValue }) {
  const styles = css`
    display: flex;
    height: 30px;
    margin-top: 10px;
    width: 100%;
    flex-direction: column;
    align-items: center;

    .barContainer {
      display: flex;
      flex-direction: row;
      height: 4px;
      width: 95%;
      background-color: ${GRAY_LIGHT};
    }

    .activeBar {
      display: flex;
      height: 100%;
      width: ${(value * 100) / maxValue}%;
      background-color: ${PURPLE_FEEK};
    }

    .text {
      height: 20px;
    }
  `;

  return (
    <div css={styles}>
      <Text
        align="left"
        fontSize={10}
        fontWeight="600"
        className="text"
      >
        Seguridad de contraseña
      </Text>
      <div className="barContainer">
        <div className="activeBar" />
      </div>
    </div>
  );
}

PasswordSecurityLevel.propTypes = {
  value: PropTypes.number,
  maxValue: PropTypes.number,
};

export default PasswordSecurityLevel;
