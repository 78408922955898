/** @jsxImportSource @emotion/react */
import { css } from '@emotion/react';
import { useNavigate } from 'react-router-dom';

import Card from 'components/Card';
import { ROUTES } from 'constants';
import Text from 'components/Text';
import SplashBg from 'assets/images/SplashBg.png';
import FeekLogoWorld from 'assets/images/FeekLogoWorld.png';
import PrimaryButton from 'components/buttons/PrimaryButton';
import feekLogoOverBackground from 'utils/feekLogoOverBackground';
import { useProfileCompletedStatus } from 'hooks/useProfileCompletedStatus';

const styles = css`
  display: flex;
  justify-content: center;
  align-items: center;
  min-height: 100vh;
  padding: 30px;
  ${feekLogoOverBackground(SplashBg)}

  .data {
    display: flex;
    gap: 20px;
    flex-direction: column;
    align-items: center;
    padding: 50px;
  }

  .cardContainer {
    width: 33%;
  }

  @media (max-width: 1600px) {
    .cardContainer {
      width: 40%;
    }
  }

  @media (max-width: 1200px) {
    .cardContainer {
      width: 70%;
    }
  }

  @media (max-width: 740px) {
    .cardContainer {
      width: 90%;
    }
  }

  .feekLogoWorld {
    width: 380px;
  }
`;

function SubscriptionCustomerSuccess() {
  const { isProfileCompleted } = useProfileCompletedStatus();
  const navigate = useNavigate();

  const handleGoToHome = () => {
    navigate(ROUTES.HOME);
  };

  const handleGoToCreateCampaign = () => {
    navigate(ROUTES.ADD_CAMPAIGNS);
  };

  return (
    <div css={styles}>
      <div className="cardContainer">
        <Card size="large">
          <div className="data">
            <img
              draggable={false}
              src={FeekLogoWorld}
              className="feekLogoWorld"
              alt="feek-logo"
            />
            <Text
              fontSize={22}
              align="center"
              fontWeight="600"
              className="title"
            >
              ¡Felicidades!
            </Text>

            <Text fontSize={14} align="center">
              Ahora eres socio <b>Feek</b>, <br />
              {isProfileCompleted && (
                <span>
                  Comienza a configurar tus primeras campañas
                </span>
              )}
              {!isProfileCompleted && (
                <span>
                  Es necesario completar tu perfil para crear tu
                  primera campaña
                </span>
              )}
            </Text>
            {isProfileCompleted ? (
              <PrimaryButton
                label="Crear mi primera campaña"
                onClick={handleGoToCreateCampaign}
              />
            ) : (
              <PrimaryButton
                label="Completar mi perfil"
                onClick={handleGoToHome}
              />
            )}
            <PrimaryButton
              strokeVariant
              label="Ir al inicio"
              onClick={handleGoToHome}
            />
          </div>
        </Card>
      </div>
    </div>
  );
}

export default SubscriptionCustomerSuccess;
