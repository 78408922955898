/** @jsxImportSource @emotion/react */
import { css } from '@emotion/react';

import PropTypes from 'prop-types';
import * as Colors from 'styles/colors';
import { defaultFont } from 'styles/fonts';

const Text = (props) => {
  const {
    children,
    className,
    color = Colors.GRAY,
    fontSize = 12,
    fontWeight = 'normal',
    align = 'left',
    lineHeight = fontSize * 1.2,
    type = 'block',
    ...rest
  } = props;

  const styles = css`
    color: ${color};
    text-align: ${align};
    font-size: ${fontSize}px;
    line-height: ${lineHeight}px;
    font-family: ${defaultFont};
    font-weight: ${fontWeight};
  `;

  let ReactElement;

  switch (type) {
    case 'block':
      ReactElement = 'div';
      break;
    case 'inline':
      ReactElement = 'span';
      break;
    default:
      throw new Error('Unhandled text type');
  }

  return (
    <ReactElement css={styles} className={className} {...rest}>
      {children}
    </ReactElement>
  );
};

Text.propTypes = {
  children: PropTypes.node,
  className: PropTypes.string,
  color: PropTypes.oneOf(Object.values(Colors)),
  fontSize: PropTypes.number,
  fontWeight: PropTypes.oneOf([
    'inherit',
    'initial',
    'revert',
    'revert-layer',
    'unset',
    'normal',
    'bold',
    'lighter',
    'bolder',
    '100',
    '200',
    '300',
    '400',
    '500',
    '600',
    '700',
    '800',
    '900',
    100,
    200,
    300,
    400,
    500,
    600,
    700,
    800,
    900,
  ]),
  align: PropTypes.oneOf(['left', 'center', 'right']),
  lineHeight: PropTypes.number,
  type: PropTypes.oneOf(['block', 'inline']),
};

export default Text;
