/** @jsxImportSource @emotion/react */
import { Field } from 'formik';
import PropTypes from 'prop-types';
import { css } from '@emotion/react';
import { forwardRef, useState } from 'react';

import {
  RED,
  GRAY,
  WHITE,
  RED_LIGHT,
  GRAY_LIGHT,
  PURPLE_FEEK,
  PURPLE_INACTIVE,
} from 'styles/colors';
import Text from 'components/Text';
import { forwardInputEventHandlers } from 'utils/form.utils';

const SelectInput = forwardRef((props, ref) => {
  const [isFocused, setIsFocused] = useState(false);
  const { error, options, className, inputClassName, ...restProps } =
    props;

  const styles = css`
    box-sizing: border-box;
    width: 100%;

    & > * {
      box-sizing: border-box;
    }

    .error-text {
      min-height: 19px;
      margin: 3px 0;
    }

    .inputWrapper {
      height: 32px;
      background-color: ${error ? RED_LIGHT : WHITE};
      border-radius: 8px;
      border: 1px solid
        ${error ? RED : isFocused ? PURPLE_FEEK : GRAY_LIGHT};

      :hover {
        border: 1px solid
          ${error ? RED : isFocused ? PURPLE_FEEK : PURPLE_INACTIVE};
      }

      select {
        height: 100%;
        width: 100%;
        padding-inline: 8px 24px;
        color: ${GRAY};
        font-size: 13px;
        outline: none;
        border: none;
        cursor: pointer;
        border-radius: inherit;
        background: none;
        appearance: none;
        background-image: url('data:image/svg+xml;base64,PHN2ZyB3aWR0aD0iMTIiIGhlaWdodD0iOC4yNSIgdmlld0JveD0iMCAwIDE2IDExIiBmaWxsPSIjNUM1QzVDIiB4bWxucz0iaHR0cDovL3d3dy53My5vcmcvMjAwMC9zdmciPgoJPHBhdGggZD0iTTE2IDIuMzMzMTJMMTQuMTIgMC40NTMxMjVMOCA2LjU1OTc5TDEuODggMC40NTMxMjRMLTguMjE3NzRlLTA4IDIuMzMzMTJMOCAxMC4zMzMxTDE2IDIuMzMzMTJaIiAvPgo8L3N2Zz4K');
        background-repeat: no-repeat;
        background-position: calc(100% - 6px) calc(50% - -2px);

        &:-webkit-autofill {
          -webkit-text-fill-color: ${GRAY};
          background-color: ${error ? RED_LIGHT : WHITE} !important;
          box-shadow: inset 0 0 0 1px rgb(255 255 255 / 0%),
            inset 0 0 0 1000px ${WHITE};
        }

        &::placeholder {
          color: ${GRAY_LIGHT};
        }
      }
    }
  `;

  const handleBlur = (e) => {
    setIsFocused(false);
    restProps.onBlur?.(e);
  };

  const handleFocus = (e) => {
    setIsFocused(true);
    restProps.onFocus?.(e);
  };

  return (
    <div ref={ref} css={styles} className={className}>
      <div className="inputWrapper">
        <select
          className={inputClassName}
          {...restProps}
          onFocus={handleFocus}
          onBlur={handleBlur}
        >
          {options.map(({ value, label }, index) => (
            <option key={index} value={value}>
              {label}
            </option>
          ))}
        </select>
      </div>
      <Text
        fontSize={14}
        lineHeight={17}
        fontWeight="500"
        color={RED}
        className="error-text"
      >
        {typeof error === 'string' ? error : ''}
      </Text>
    </div>
  );
});

export const FormSelectInput = (props) => {
  const { name, error, ...restProps } = props;

  return (
    <Field name={name}>
      {({ meta, field }) => (
        <SelectInput
          name={name}
          value={meta.value}
          error={meta.touched && (meta.error || error)}
          {...restProps}
          {...forwardInputEventHandlers(restProps, field)}
        />
      )}
    </Field>
  );
};

FormSelectInput.propTypes = {
  name: PropTypes.string,
  error: PropTypes.oneOfType([PropTypes.string, PropTypes.bool]),
};

SelectInput.propTypes = {
  error: PropTypes.oneOfType([PropTypes.string, PropTypes.bool]),
  onKeyDown: PropTypes.func,
  className: PropTypes.string,
  inputClassName: PropTypes.string,
  options: PropTypes.arrayOf(
    PropTypes.shape({
      value: PropTypes.string,
      label: PropTypes.string,
    }),
  ),
};

SelectInput.displayName = 'SelectInput';

export default SelectInput;
