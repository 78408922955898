/** @jsxImportSource @emotion/react */
import moment from 'moment';
import { useState } from 'react';
import PropTypes from 'prop-types';
import { css } from '@emotion/react';
import { useSelector, useDispatch } from 'react-redux';

import {
  WHITE,
  PURPLE_FEEK,
  GRADIENT_RED,
  GRAY_LIGHTEST,
} from 'styles/colors';
import APIClient from 'redux/api';
import Text from 'components/Text';
import Modal from 'components/modals/Modal';
import Leave from 'assets/images/Leave.svg';
import handleError from 'utils/handleError';
import Button from 'components/buttons/Button';
import NotResults from 'assets/images/NotResults.svg';
import PrimaryButton from 'components/buttons/PrimaryButton';
import { setCustomerSubscritionCancelled } from 'redux/entities';

const styles = css`
  box-sizing: border-box;
  display: flex;
  flex-direction: column;
  align-items: center;
  width: 450px;
  padding: 40px 50px;
  background-color: ${WHITE};
  border-radius: 8px;

  & > * {
    box-sizing: border-box;
  }

  .iconClose {
    display: flex;
    align-items: center;
    align-self: flex-end;
    justify-content: center;
    width: 32px;
    height: 32px;
    border-radius: 50%;
    background-color: ${GRAY_LIGHTEST};
    margin-top: -20px;
    margin-right: -20px;
  }

  .image {
    width: 255px;
    height: 140px;
    margin-bottom: 15px;
  }
  .title {
    margin-bottom: 15px;
  }
  .label {
    margin-bottom: 0.75rem;
  }
  .button-container {
    margin-top: 37px;
    width: 100%;
    display: flex;
    justify-content: space-between;
    gap: 20px;
  }

  .primary-button {
    width: 100%;
    border: 1px solid ${PURPLE_FEEK};
  }

  .infoFooterLink {
    color: ${PURPLE_FEEK};
    text-decoration: underline;
  }
`;

function ModalCancelSubcription({ isOpen, handleClose }) {
  const dispatch = useDispatch();

  const [isOnFirstCancelStep, setIsOnFirstCancelStep] =
    useState(true);

  const customers = useSelector((state) => state.entities.customers);

  const selectedCustomerId = useSelector(
    (state) => state.app.selectedCustomerId,
  );

  const selectedCustomer = customers[selectedCustomerId]?.customer;

  const cancelConfirm = async () => {
    try {
      const response = await APIClient.cancelSuscription(
        selectedCustomerId,
      );
      if (response.status === 200) {
        setIsOnFirstCancelStep(false);
        dispatch(setCustomerSubscritionCancelled(selectedCustomerId));
      }
    } catch (error) {
      handleError(error);
    }
  };

  return (
    <Modal
      visible={isOpen}
      onRequestClose={handleClose}
      outsideOutlet
    >
      <div css={styles}>
        {isOnFirstCancelStep ? (
          <>
            <img
              src={NotResults}
              alt="Feeker looking report"
              className="image"
            />
            <Text
              fontSize={24}
              align="center"
              fontWeight="bold"
              lineHeight={32}
              className="title"
            >
              ¿Estás seguro que deseas cancelar tu suscripción?
            </Text>
            <Text
              fontSize={14}
              lineHeight={24}
              align="center"
              className="label"
            >
              Una vez cancelada tu suscripción, tu plan seguirá activo
              hasta tu fecha de corte el{' '}
              <b>
                {moment(selectedCustomer?.current_period_end).format(
                  'DD MMM YYYY',
                )}
                .
              </b>
            </Text>
            <Text
              fontSize={14}
              lineHeight={24}
              align="center"
              className="label"
            >
              Después de esta fecha ya no podrás crear campañas.
            </Text>
            <Button onClick={handleClose}>
              <Text
                color={PURPLE_FEEK}
                className="infoFooterLink"
                fontSize={14}
              >
                Explora nuestros planes de suscripción
              </Text>
            </Button>

            <div className="button-container">
              <PrimaryButton
                color={WHITE}
                textColor={PURPLE_FEEK}
                label="Regresar"
                onClick={handleClose}
                textProps={{
                  fontSize: 16,
                  fontWeight: 600,
                }}
                className="primary-button"
              />

              <PrimaryButton
                color={GRADIENT_RED}
                textColor={WHITE}
                label="Confirmar Cancelación"
                onClick={cancelConfirm}
                textProps={{
                  fontSize: 16,
                  fontWeight: 600,
                  align: 'center',
                }}
              />
            </div>
          </>
        ) : (
          <>
            <img
              src={Leave}
              alt="Feeker looking report"
              className="image"
            />
            <Text
              fontSize={24}
              align="center"
              fontWeight="bold"
              lineHeight={32}
              className="title"
            >
              Lamentamos verte partir
            </Text>
            <Text
              fontSize={14}
              lineHeight={24}
              align="center"
              className="label"
            >
              Has cancelado tu suscripción.
            </Text>
            <Text
              fontSize={14}
              lineHeight={24}
              align="center"
              className="label"
            >
              Tu plan seguirá activo hasta tu fecha de corte el{' '}
              <b>
                {moment(selectedCustomer?.current_period_end).format(
                  'DD MMM YYYY',
                )}
              </b>
              . Después de esta fecha ya no podrás crear campañas
              hasta que renueves tu suscripción.
            </Text>

            <PrimaryButton
              color={WHITE}
              textColor={PURPLE_FEEK}
              label="Entendido"
              onClick={handleClose}
              textProps={{
                fontSize: 16,
                fontWeight: 600,
              }}
              className="primary-button"
            />
          </>
        )}
      </div>
    </Modal>
  );
}

ModalCancelSubcription.propTypes = {
  handleClose: PropTypes.func,
  isOpen: PropTypes.bool,
};

export default ModalCancelSubcription;
