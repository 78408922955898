/** @jsxImportSource @emotion/react */
import { css } from '@emotion/react';
import PropTypes from 'prop-types';

import { useState } from 'react';
import Text from 'components/Text';
import Modal from 'components/modals/Modal';
import { MEDIA_QUERIES } from 'styles/constants';
import { PURPLE_FEEK, WHITE } from 'styles/colors';
import AccordionStatus from 'components/AccordionStatus';
import PrimaryButton from 'components/buttons/PrimaryButton';
import BusinessCategoriesSelector from 'components/BusinessCategoriesSelector';

const stylesModal = css`
  width: 610px;
  border-radius: 16px;
  padding: 50px 60px 50px 60px;
  background-color: ${WHITE};

  ${MEDIA_QUERIES.mobile} {
    width: 100vw;
    border-radius: 0px;
    padding: 40px 30px 40px 30px;
  }
`;

const styles = css`
  .businessCategoriesWrapper {
    height: 486px;
    width: 610px;
    border-radius: 16px;
    padding: 50px 60px 50px 60px;
    background-color: ${WHITE};
  }

  .categoriesListContainer {
    display: flex;
    gap: 6px;
    flex-wrap: wrap;
    margin-block-end: 12px;

    .category {
      padding: 5px 9px;
      background: ${PURPLE_FEEK};
      border-radius: 16px;
    }
  }

  .editBtn {
    width: 120px;
    height: 30px;
  }

  .buttonShowCategoriesModal {
    width: 120px;
    color: ${PURPLE_FEEK};
    background: ${WHITE};
    border: 1px solid ${PURPLE_FEEK};
    border-radius: 8px;
    font-size: 14px;
    padding-block: 7px;
    text-align: center;
  }
`;

function AccordionProfileCategories({
  statusIcon = false,
  className,
  categories,
  customer,
}) {
  const [showModal, setShowModal] = useState(false);

  return (
    <>
      <Modal
        visible={showModal}
        onRequestClose={() => setShowModal(false)}
      >
        <div css={stylesModal}>
          <BusinessCategoriesSelector
            buttonText="Guardar Categorías"
            className="categoriesModal"
            title="Categorías del negocio"
            onSuccess={() => setShowModal(false)}
            categories={categories}
            customer={customer}
          />
        </div>
      </Modal>

      <AccordionStatus
        status={
          statusIcon ? !!customer?.categories.length : undefined
        }
        className={className}
        title="Categorías"
        content={
          <div css={styles}>
            {customer?.categories && (
              <div className="categoriesListContainer">
                {customer?.categories.map((categoryId, i) => (
                  <Text
                    align="center"
                    fontSize={12}
                    fontWeight="600"
                    color={WHITE}
                    className="category"
                    key={i}
                  >
                    {categories[categoryId]?.name}
                  </Text>
                ))}
              </div>
            )}

            <PrimaryButton
              strokeVariant
              fontSize={13}
              label="Editar"
              className="editBtn"
              onClick={() => setShowModal(true)}
            />
          </div>
        }
      />
    </>
  );
}

AccordionProfileCategories.propTypes = {
  className: PropTypes.string,
  statusIcon: PropTypes.bool,
  categories: PropTypes.object,
  customer: PropTypes.object,
};

export default AccordionProfileCategories;
