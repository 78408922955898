/** @jsxImportSource @emotion/react */

import { useState } from 'react';
import PropTypes from 'prop-types';
import { css } from '@emotion/react';
import { useSelector } from 'react-redux';

import APIClient from 'redux/api';
import { ROUTES } from 'constants';
import Icon from 'components/Icon';
import Text from 'components/Text';
import Modal from 'components/modals/Modal';
import handleError from 'utils/handleError';
import { useNavigate } from 'react-router-dom';
import { MEDIA_QUERIES } from 'styles/constants';
import { PURPLE_FEEK, WHITE } from 'styles/colors';
import PrimaryButton from 'components/buttons/PrimaryButton';
import { ReactComponent as Sheet } from 'assets/icons/Sheet.svg';

const styles = css`
  .saveBtn {
    height: 48px;
    border-radius: 16px;
  }

  ${MEDIA_QUERIES.mobile} {
    display: none;
  }
`;

const stylesModal = css`
  background: ${WHITE};
  min-width: 500px;
  padding: 30px;
  border-radius: 15px;
  height: 100%;

  .title,
  .text {
    padding: 15px;
  }

  .buttonsWrapper {
    display: flex;
    justify-content: center;
    gap: 15px;
    margin-block: 15px;
  }

  .button {
    width: 40%;
  }
`;

function SaveDraft({ isSaveDraftDisabled, values }) {
  const [isModalExitCampaign, setIsModalExitCampaign] =
    useState(false);
  const [isLoading, setIsLoading] = useState(false);
  const selectedCustomerId = useSelector(
    (state) => state.app.selectedCustomerId,
  );
  const navigate = useNavigate();

  const handleCloseExitCampaign = () => {
    setIsModalExitCampaign(false);
  };

  const openExitCampaign = () => {
    setIsModalExitCampaign(true);
  };

  const handleSaveDraft = async () => {
    const {
      extraTask,
      task,
      startDate,
      startTime,
      endTime,
      ...rest
    } = values;
    const payload = {
      ...rest,
      extra_task: extraTask.filter((item) => !!item),
      task: task.filter((item) => !!item),
      customer_id: selectedCustomerId,
      start_date: startDate,
      start_time: startTime,
      end_time: endTime,
      status: 'draft',
    };
    setIsLoading(true);
    try {
      const res = await APIClient.createCampaign(
        Object.fromEntries(
          Object.entries(payload).map(([key, value]) => {
            if (
              (typeof value === 'string' || Array.isArray(value)) &&
              value.length === 0
            )
              return [key, null];
            return [key, value];
          }),
        ),
      );
      if (res.status === 201) {
        setIsLoading(false);
        navigate(ROUTES.CAMPAIGNS);
      }
    } catch (error) {
      setIsLoading(false);
      handleError(error);
    }
  };

  return (
    <div css={styles}>
      <PrimaryButton
        strokeVariant
        className="saveBtn"
        onClick={openExitCampaign}
        disabled={isSaveDraftDisabled}
        label="Guardar como borrador"
        iconPosition="right"
        icon={<Icon icon={Sheet} color={PURPLE_FEEK} size={12} />}
        fontSize={14}
      />
      <Modal
        visible={isModalExitCampaign}
        onRequestClose={handleCloseExitCampaign}
      >
        <div css={stylesModal}>
          <Text fontSize={26} fontWeight={700} className="title">
            Desea guardar como borrador?
          </Text>
          <Text fontSize={16} className="text">
            Se guardará el progreso o datos que ya <br />
            hayas completado
          </Text>

          <div className="buttonsWrapper">
            <PrimaryButton
              disabled={isLoading}
              label="Regresar"
              onClick={handleCloseExitCampaign}
              className="button"
            />
            <PrimaryButton
              disabled={isLoading}
              label="Guardar"
              onClick={handleSaveDraft}
              className="button"
            />
          </div>
        </div>
      </Modal>
    </div>
  );
}

SaveDraft.propTypes = {
  values: PropTypes.object,
  isSaveDraftDisabled: PropTypes.bool,
};

export default SaveDraft;
