import { createSlice } from '@reduxjs/toolkit';

import APIClient from 'redux/api';

const initialState = {
  name: '',
  email: '',
  phone: '',
  password: '',
  /**
   * @deprecated This property doesn't follow our architecture principles
   */
  campaigns: [],
  isSideMenuVisible: false,
  selectedCustomerId: localStorage.getItem('selectedCustomerId'),
};

export const appSlice = createSlice({
  name: 'app',
  initialState,
  reducers: {
    selectCustomer: (state, action) => {
      localStorage.setItem('selectedCustomerId', action.payload);
      state.selectedCustomerId = action.payload;
    },
    toggleSideMenu: (state) => {
      state.isSideMenuVisible = !state.isSideMenuVisible;
    },
    openSideMenu: (state) => {
      state.isSideMenuVisible = true;
    },
    closeSideMenu: (state) => {
      state.isSideMenuVisible = false;
    },
    setUser: (state, action) => {
      const { phone, name, email } = action.payload;

      state.name = name;
      state.email = email;
      state.phone = phone;
    },
    logout: () => {
      APIClient.unsetAuthenticatedInstance();
    },

    /**
     * @deprecated This function will not be used anymore,
     */
    setCampaigns: (state, action) => {
      state.campaigns = action.payload;
    },
    /**
     * @deprecated This function will not be used anymore,
     */
    signUpSuccess: (state, action) => {
      state.email = action.payload?.email || '';
      state.password = action.payload.password;
      state.phone = '+' + action.payload?.phone || '';
    },
  },
});

export const {
  logout,
  setUser,
  openSideMenu,
  setCampaigns,
  signUpSuccess,
  closeSideMenu,
  selectCustomer,
  toggleSideMenu,
} = appSlice.actions;

export default appSlice.reducer;
