/** @jsxImportSource @emotion/react */
import moment from 'moment';
import PropTypes from 'prop-types';
import { css } from '@emotion/react';
import { toast } from 'react-toastify';
import { useNavigate } from 'react-router-dom';
import { useReactToPrint } from 'react-to-print';
import { useEffect, useRef, useState } from 'react';
import { useDispatch, useSelector } from 'react-redux';

import {
  RED,
  GRAY,
  WHITE,
  YELLOW,
  GREEN_C,
  PURPLE_FEEK,
  GRAY_LIGHTER,
  GRAY_LIGHTEST,
} from 'styles/colors';
import APIClient from 'redux/api';
import { ROUTES } from 'constants';
import Icon from 'components/Icon';
import Text from 'components/Text';
import { setPlans } from 'redux/entities';
import handleError from 'utils/handleError';
import Button from 'components/buttons/Button';
import NotResults from 'assets/images/NotResults.svg';
import PrimaryButton from 'components/buttons/PrimaryButton';
import { ReactComponent as Eye } from 'assets/icons/Eye.svg';
import PaginationButtons from 'components/buttons/PaginationButtons';

const paymentStatusData = {
  paid: { color: GREEN_C, label: 'Pagado' },
  rejected: { color: RED, label: 'Rechazado' },
  cancelled: { color: RED, label: 'Cancelado' },
  pending: { color: YELLOW, label: 'Pendiente' },
};
const paymentMethodsRedableNames = {
  'credit-card': 'Tarjeta de crédito',
  'debit-card': 'Tarjeta de débito',
};

const styles = css`
  display: flex;
  flex-direction: column;
  width: 100%;
  background-color: ${WHITE};
  overflow: hidden;

  &,
  & :where(*) {
    box-sizing: border-box;
  }
  .notResults {
    display: flex;
    flex-direction: column;
    align-items: center;
    padding: 50px;

    img {
      width: 100%;
      max-width: 600px;
      margin-bottom: 30px;
    }

    .label {
      display: flex;
      align-items: center;
      gap: 5px;
      height: 33px;
      padding: 0 8px;
      border-radius: 6px;
      background-color: ${GRAY_LIGHTER};

      .button {
        text-decoration: underline;
      }
    }
  }

  .paymentsList {
    display: flex;
    flex-direction: column;
    align-items: center;
    flex: 1;
    width: 100%;
    padding: 40px;

    .tableWrapper {
      width: 100%;
      overflow: auto;
      margin-bottom: 30px;

      table {
        width: 100%;
        min-width: 500px;
        text-align: left;
        color: ${GRAY};
        font-size: 14px;
        line-height: 16.8px;
        border-collapse: collapse;

        td:last-of-type,
        th:last-of-type {
          text-align: center;
        }

        tr {
          height: 64px;
        }
        td,
        th {
          padding: 0 10px;
          white-space: nowrap;
        }

        tbody {
          tr:nth-of-type(odd) {
            height: 44px;
            td {
              background-color: ${GRAY_LIGHTEST};
              white-space: nowrap;
            }

            td:first-of-type {
              border-top-left-radius: 8px;
              border-bottom-left-radius: 8px;
            }

            td:last-of-type {
              border-top-right-radius: 8px;
              border-bottom-right-radius: 8px;
            }
          }
        }
      }
    }
  }

  .ticketStep {
    padding: 40px;

    .titleWrapper {
      display: flex;
      align-items: center;
      justify-content: space-between;
    }

    .ticketWrapper {
      width: 380px;
      table {
        display: flex;
        text-align: left;
        color: ${GRAY};
        font-size: 14px;
        line-height: 16.8px;
        margin-top: 49px;
        margin-bottom: 10px;
        border-collapse: collapse;

        tr {
          height: 70px;
          width: 100%;
        }

        tr:last-of-type {
          height: 31px;
          td,
          th {
            font-size: 12px;
            font-weight: normal;
          }

          td {
            text-align: right;
          }
        }

        td,
        th {
          width: 190px;
          padding: 0 10px;
        }

        tr:nth-of-type(odd) {
          height: 31px;
          td,
          th {
            background-color: ${GRAY_LIGHTEST};
          }

          th {
            border-top-left-radius: 8px;
            border-bottom-left-radius: 8px;
          }

          td {
            border-top-right-radius: 8px;
            border-bottom-right-radius: 8px;
          }
        }
      }

      .buttons {
        display: flex;
        justify-content: space-between;
        width: 100%;

        .button {
          height: 44px;
          width: fit-content;
        }
      }
    }
  }

  .date {
    text-transform: capitalize;
  }
`;

const PaymentList = ({ onViewTicket }) => {
  const dispatch = useDispatch();
  const navigate = useNavigate();

  const selectedCustomerId = useSelector(
    (state) => state.app.selectedCustomerId,
  );
  const [pages, setPages] = useState([]);
  const [isLoading, setIsLoading] = useState(false);
  const [pageNumber, setPageNumber] = useState(1);

  useEffect(() => {
    setIsLoading(true);
    APIClient.getPaymentHistory(selectedCustomerId, 1)
      .then((res) => {
        if (res.status === 200) {
          setPages([res.data]);
        } else {
          setPages([]);
          toast.error(
            handleError('Hubo un error cargando los pagos.'),
          );
        }
      })
      .catch((e) => {
        setPages([]);
        toast.error(
          handleError(e || 'Hubo un error cargando los pagos.'),
        );
      })
      .finally(() => {
        setIsLoading(false);
        setPageNumber(1);
      });
  }, [selectedCustomerId]);

  const handleChangeToNextPage = async (page) => {
    if (!pages[page - 1]) {
      setIsLoading(true);
      try {
        const res = await APIClient.getPaymentHistory(
          selectedCustomerId,
          page,
        );
        if (res.status === 200) {
          setPages((crr) => [...crr, res.data]);
          setPageNumber(page);
        } else {
          toast.error(
            handleError('Hubo un error cargando los pagos.'),
          );
        }
      } catch (error) {
        toast.error(
          handleError(error || 'Hubo un error cargando los pagos.'),
        );
      } finally {
        setIsLoading(false);
      }
    } else {
      setPageNumber(page);
    }
  };

  const handleNavigateToPlans = () => {
    APIClient.getPlans()
      .then(({ data }) => {
        dispatch(setPlans(data));
      })
      .catch((e) => console.error(e));
    navigate(ROUTES.PAYMENT);
  };

  const currentPage = pages[pageNumber - 1];

  return (
    <div>
      {!currentPage?.totalDocs ? (
        <div className="notResults">
          <img src={NotResults} alt="No results" />
          <Text fontSize={14} className="label">
            Aún no tienes pagos registrados. Contrata un plan Feek y
            comienza a crear campañas.
            <Button
              onClick={handleNavigateToPlans}
              className="button"
            >
              <Text color={PURPLE_FEEK} fontSize={14}>
                Ver Planes.
              </Text>
            </Button>
          </Text>
        </div>
      ) : (
        <div className="paymentsList">
          <div className="tableWrapper">
            <table>
              <thead>
                <tr>
                  <th>Fecha</th>
                  <th>Cantidad</th>
                  <th>Concepto</th>
                  <th>Método de Pago</th>
                  <th>Estado</th>
                  <th>Ver Recibo</th>
                </tr>
              </thead>
              <tbody>
                {currentPage?.docs.map((item) => (
                  <tr key={item._id}>
                    <td className="date">
                      {moment(item?.current_period_start).format(
                        'D / MMM / YY',
                      )}
                    </td>
                    <td>
                      {item?.total} {item?.currency?.toUpperCase()}
                    </td>
                    <td>{item?.concept}</td>
                    <td>
                      {paymentMethodsRedableNames[
                        item?.payment_method
                      ] || item?.payment_method}
                    </td>
                    <td>
                      <Text
                        fontSize={14}
                        lineHeight={16.8}
                        color={paymentStatusData[item?.status]?.color}
                      >
                        {paymentStatusData[item?.status]?.label}
                      </Text>
                    </td>
                    <td>
                      <Button onClick={() => onViewTicket(item)}>
                        <Icon icon={Eye} />
                      </Button>
                    </td>
                  </tr>
                ))}
              </tbody>
            </table>
          </div>

          <PaginationButtons
            currentPage={pageNumber}
            backPageDisabled={isLoading || !currentPage?.hasPrevPage}
            nextPageDisabled={isLoading || !currentPage?.hasNextPage}
            onBackPage={() => setPageNumber((crr) => crr - 1)}
            onNextPage={() => handleChangeToNextPage(pageNumber + 1)}
          />
        </div>
      )}
    </div>
  );
};

PaymentList.propTypes = {
  onViewTicket: PropTypes.func,
};

const PaymentTicket = ({
  iva,
  onBack,
  status,
  concept,
  discount,
  subtotal,
  currency,
  invoiceId,
  paymentDate,
  paymentMethod,
}) => {
  const [isLoading, setIsLoading] = useState(false);
  const ticketRef = useRef();
  const total = iva + subtotal - discount;

  const handlePrint = useReactToPrint({
    content: () => ticketRef.current,
    pageStyle: `
      table {
        display: flex;
        text-align: left;
        color: ${GRAY};
        font-size: 14px;
        line-height: 16.8px;
        border-collapse: collapse;
      }
      tr {
        height: 70px;
        width: 100%;
      }
      tr:last-of-type {
        height: 31px;
      }
      tr:last-of-type td,
      tr:last-of-type th {
        font-size: 12px;
        font-weight: normal;
      }
      tr:last-of-type td {
        text-align: right;
      }
      td,
      th {
        width: 190px;
        padding: 0 10px;
      }
      tr:nth-of-type(odd) {
        height: 31px;
      }
      tr:nth-of-type(odd) td,
      tr:nth-of-type(odd) th {
        background-color: ${GRAY_LIGHTEST};
      }
      tr:nth-of-type(odd) th {
        border-top-left-radius: 8px;
        border-bottom-left-radius: 8px;
      }
      tr:nth-of-type(odd) td {
        border-top-right-radius: 8px;
        border-bottom-right-radius: 8px;
      }
    `,
  });

  const handleDowloadInvoice = async () => {
    setIsLoading(true);
    try {
      const res = await APIClient.downloadInvoice(invoiceId);
      const a = document.createElement('a');
      a.href = URL.createObjectURL(
        new Blob([res.data], {
          type: 'application/pdf',
        }),
      );
      a.download = 'invoice.pdf';
      a.click();
      a.remove();
      setIsLoading(false);
    } catch (error) {
      setIsLoading(false);
      toast.error(handleError(error));
    }
  };

  return (
    <div className="ticketStep">
      <div className="titleWrapper">
        <Text fontSize={26} lineHeight={31.2}>
          Recibo de Pago
        </Text>
        <Button onClick={onBack}>
          <Text fontSize={14} lineHeight={16.8}>
            ← Regresar a Historial de Pagos
          </Text>
        </Button>
      </div>
      <div className="ticketWrapper">
        <table ref={ticketRef}>
          <thead>
            <tr>
              <th>Fecha</th>
            </tr>
            <tr>
              <th>Concepto</th>
            </tr>
            <tr>
              <th>Método de Pago</th>
            </tr>
            <tr>
              <th>Estado</th>
            </tr>
            <tr>
              <th>Subtotal</th>
            </tr>
            <tr>
              <th>Cupón</th>
            </tr>
            <tr>
              <th>IVA</th>
            </tr>
            <tr>
              <th>TOTAL</th>
            </tr>
            <tr>
              <th>Feek Technologies SAPI de CV </th>
            </tr>
          </thead>
          <tbody>
            <tr>
              <td className="date">
                {moment(paymentDate).format('D MMMM, Y')}
              </td>
            </tr>
            <tr>
              <td>{concept}</td>
            </tr>
            <tr>
              <td>{paymentMethod}</td>
            </tr>
            <tr>
              <td>
                <Text
                  fontSize={14}
                  lineHeight={16.8}
                  color={paymentStatusData[status]?.color}
                >
                  {paymentStatusData[status]?.label}
                </Text>
              </td>
            </tr>
            <tr>
              <td>
                {`${
                  Number.isFinite(subtotal)
                    ? subtotal?.toFixed(2).toLocaleString('en-US')
                    : '0.00'
                } ${currency}`}
              </td>
            </tr>
            <tr>
              <td>
                {`- ${
                  Number.isFinite(discount)
                    ? discount?.toFixed(2).toLocaleString('en-US')
                    : '0.00'
                } ${currency}`}
              </td>
            </tr>
            <tr>
              <td>
                {`${
                  Number.isFinite(iva)
                    ? iva?.toFixed(2).toLocaleString('en-US')
                    : '0.00'
                } ${currency}`}
              </td>
            </tr>
            <tr>
              <td>
                {`${
                  Number.isFinite(total)
                    ? total?.toFixed(2).toLocaleString('en-US')
                    : '0.00'
                } ${currency}`}
              </td>
            </tr>
            <tr>
              <td>{moment().get('year')}</td>
            </tr>
          </tbody>
        </table>
        <div className="buttons">
          <PrimaryButton
            strokeVariant
            label="Imprimir recibo"
            className="button"
            onClick={handlePrint}
          />
          {invoiceId && (
            <PrimaryButton
              strokeVariant
              className="button"
              disabled={isLoading}
              label="Descargar factura"
              onClick={handleDowloadInvoice}
            />
          )}
        </div>
      </div>
    </div>
  );
};

PaymentTicket.propTypes = {
  iva: PropTypes.number,
  onBack: PropTypes.func,
  concept: PropTypes.string,
  currency: PropTypes.string,
  discount: PropTypes.number,
  subtotal: PropTypes.number,
  invoiceId: PropTypes.string,
  paymentDate: PropTypes.string,
  paymentMethod: PropTypes.string,
  status: PropTypes.oneOf(['pending', 'paid', 'rejected']),
};

function PaymentHistory() {
  const steps = [PaymentList, PaymentTicket];
  const [selectedPayment, setSelectedPayment] = useState(null);
  const selectedCustomerId = useSelector(
    (state) => state.app.selectedCustomerId,
  );

  const CurrentStepComponent = steps[!selectedPayment ? 0 : 1];

  const handleBack = () => {
    setSelectedPayment(null);
  };

  const handleViewTicket = (payment) => {
    setSelectedPayment(payment);
  };

  useEffect(() => {
    handleBack();
  }, [selectedCustomerId]);

  return (
    <div css={styles}>
      <CurrentStepComponent
        {...(selectedPayment
          ? {
              onBack: handleBack,
              iva: selectedPayment?.iva,
              status: selectedPayment.status,
              discount: selectedPayment.discount,
              concept: selectedPayment.concept,
              currency: selectedPayment.currency?.toUpperCase(),
              subtotal: selectedPayment.subtotal,
              invoiceId: selectedPayment?.invoice_id,
              paymentDate: selectedPayment.current_period_start,
              paymentMethod:
                paymentMethodsRedableNames[
                  selectedPayment?.payment_method
                ] || selectedPayment?.payment_method,
            }
          : {
              onViewTicket: handleViewTicket,
            })}
      />
    </div>
  );
}

export default PaymentHistory;
