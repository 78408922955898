/** @jsxImportSource @emotion/react */
import * as Yup from 'yup';
import { useState } from 'react';
import { css } from '@emotion/react';
import { Form, Formik } from 'formik';
import { useDispatch } from 'react-redux';
import { Link, useNavigate } from 'react-router-dom';

import APIClient from 'redux/api';
import { ROUTES } from 'constants';
import Text from 'components/Text';
import Icon from 'components/Icon';
import handleError from 'utils/handleError';
import { setUser, signUpSuccess } from 'redux/app';
import { PURPLE_FEEK, WHITE } from 'styles/colors';
import GoogleButton from 'components/buttons/GoogleButton';
import PrimaryButton from 'components/buttons/PrimaryButton';
import { FormPhoneInput } from 'components/inputs/PhoneInput';
import { FormCheckboxInput } from 'components/inputs/CheckBox';
import CarouselScreen from 'screens/Registration/CarouselScreen';
import { ReactComponent as Email } from 'assets/icons/Email.svg';
import { FormTextInput } from 'components/inputs/TextInput/index';
import PasswordSecurityLevel from 'components/PasswordSecurityLevel';
import { FormPasswordInput } from 'components/inputs/PasswordInput/index';
import { ReactComponent as Smartphone } from 'assets/icons/Smartphone.svg';

const styles = css`
  display: flex;
  height: 100vh;
  width: 100%;

  &,
  & :where(*) {
    box-sizing: border-box;
  }

  .container {
    width: 100%;
    height: 100vh;
    display: flex;
    justify-content: center;
    align-items: center;
  }

  .feekIconContainer {
    display: flex;
    margin-top: 2rem;
    position: absolute;
    left: 10px;
    width: 20%;
    padding-left: 3%;
    z-index: 100;
  }

  .rightContainer {
    width: 100%;
    display: flex;
    flex-wrap: wrap;
    overflow-y: auto;
    justify-content: center;
    padding-block: 45px;
  }

  .headerLogin {
    width: 100%;
    display: flex;
    justify-content: flex-end;
    align-items: center;
    gap: 18px;
    padding: 0 32px 38px;
    background: ${WHITE};
  }

  .loginButton {
    width: 130px;
    height: 40px;
    border-radius: 15px;
  }

  .header {
    width: 50%;
    margin-block-end: 30px;

    div:first-of-type {
      margin-block-end: 10px;
    }
  }

  .formContainer {
    display: flex;
    flex-direction: column;
    width: 60%;
  }

  .policies {
    margin: 15px auto 0;

    .inputAndLabel {
      justify-content: center;
    }
  }

  .policiesLink {
    color: ${PURPLE_FEEK};
  }

  .buttonsWrapper {
    width: 100%;
    display: flex;
    flex-direction: column;
    gap: 15px;
  }
`;

const formInitialValuesPhone = {
  phone: '',
  password: '',
  policies: false,
};
const formInitialValuesEmail = {
  email: '',
  password: '',
  policies: false,
};

const formValidationPhoneSchema = Yup.object({
  phone: Yup.string()
    .min(10, 'El télefono tiene que tener al menos 10 numeros')
    .required('El télefono es requerido'),

  password: Yup.string()
    .required('La contraseña es requerida')
    .min(6, 'La contraseña debe tener al menos 6 caracters')
    .max(30, 'La contraseña no puede tener mas de 30 caracters')
    .matches(
      /[0-9]/,
      'La contraseña tiene que tener al menos un numero',
    )
    .matches(
      /[A-Z]/,
      'La contraseña tiene que tener al menos una letra mayúscula',
    ),

  policies: Yup.boolean()
    .required('Debes aceptar las políticas de privacidad.')
    .oneOf([true], 'Debes aceptar las políticas de privacidad.'),
});

const formValidationEmailSchema = Yup.object({
  email: Yup.string()
    .email('El email es inválido')
    .required('El email es requerido'),

  password: Yup.string()
    .required('La contraseña es requerida')
    .min(6, 'La contraseña debe tener al menos 6 caracters')
    .max(30, 'La contraseña no puede tener mas de 30 caracters')
    .matches(
      /[0-9]/,
      'La contraseña tiene que tener al menos un numero',
    )
    .matches(
      /[A-Z]/,
      'La contraseña tiene que tener al menos una letra mayúscula',
    ),

  policies: Yup.boolean()
    .required('Debes aceptar las políticas de privacidad.')
    .oneOf([true], 'Debes aceptar las políticas de privacidad.'),
});

function Registration() {
  const [usePhoneLogin, setUsePhoneLogin] = useState(false);

  const navigate = useNavigate();
  const dispatch = useDispatch();

  const handleLogin = async (googleData) => {
    if ('access_token' in googleData) {
      try {
        const response = await APIClient.loginByGoogle({
          token: googleData.access_token,
        });
        if (response.status === 201) {
          localStorage.setItem('userId', response.data.data.user._id);
          APIClient.setAuthenticatedInstance(
            response.data.data.access_token,
          );
          dispatch(setUser(response.data.data.user));
          navigate(ROUTES.HOME);
        }
      } catch (e) {
        handleError(e);
      }
    }
  };

  const handleSignUp = async (values, actions) => {
    actions.resetForm({ values });
    actions.setSubmitting(true);

    const payload = {
      role: 'customer',
      ...(usePhoneLogin
        ? {
            phone: values.phone,
            password: values.password,
          }
        : {
            email: values.email.toLowerCase().trim(),
            password: values.password,
          }),
    };

    try {
      const {
        status,
        data: { user_id },
      } = usePhoneLogin
        ? await APIClient.signUpUserByPhone(payload)
        : await APIClient.signUpUserByEmail(payload);

      if (status === 201) {
        localStorage.setItem('userId', user_id);
        dispatch(signUpSuccess(payload));
        navigate(
          usePhoneLogin
            ? ROUTES.REGISTRATION_VERIFICATION_CODE_PHONE
            : ROUTES.REGISTRATION_VERIFICATION_CODE_EMAIL,
        );
      }
    } catch (e) {
      handleError(e);
      actions.setSubmitting(false);
    }
  };

  return (
    <div css={styles}>
      <CarouselScreen />

      <div className="rightContainer">
        <div className="headerLogin">
          <Text fontSize={12}>¿Ya tienes cuenta?</Text>
          <PrimaryButton
            label="Inicia sesión"
            onClick={() => navigate(ROUTES.LOGIN)}
            strokeVariant
            fontSize={13}
            className="loginButton"
          />
        </div>

        <div className="header">
          <Text
            fontSize={24}
            fontWeight={700}
            lineHeight={28.8}
            align="center"
          >
            ¡Regístrate ahora y descubre el efecto Feek!
          </Text>
          <Text fontSize={14} lineHeight={16.8} align="center">
            Tus datos están a salvo con nosotros.
          </Text>
        </div>

        <Formik
          validateOnMount
          onSubmit={handleSignUp}
          initialValues={
            usePhoneLogin
              ? formInitialValuesPhone
              : formInitialValuesEmail
          }
          validationSchema={
            usePhoneLogin
              ? formValidationPhoneSchema
              : formValidationEmailSchema
          }
        >
          {(formik) => {
            return (
              <Form className="formContainer">
                {usePhoneLogin ? (
                  <FormPhoneInput name="phone" />
                ) : (
                  <FormTextInput placeholder="Email" name="email" />
                )}

                <FormPasswordInput
                  placeholder="Contraseña"
                  name="password"
                />

                <PasswordSecurityLevel
                  value={
                    [
                      formik.values.password.length > 6,
                      formik.values.password.match(/[0-9]/),
                      formik.values.password.match(/[A-Z]/g),
                    ].filter((value) => Boolean(value)).length
                  }
                  maxValue={3}
                />

                <FormCheckboxInput
                  wrapperClassName="policies"
                  name="policies"
                  label={
                    <Text fontSize={10} fontWeight={600}>
                      Acepto las{' '}
                      <Link
                        className="policiesLink"
                        to={ROUTES.POLICY}
                      >
                        políticas de privacidad
                      </Link>
                    </Text>
                  }
                />

                <div className="buttonsWrapper">
                  <PrimaryButton
                    label="Registrarme"
                    onClick={formik.submitForm}
                    disabled={!formik.isValid || formik.isSubmitting}
                  />

                  {usePhoneLogin ? (
                    <PrimaryButton
                      strokeVariant
                      label="Regístrate con email"
                      onClick={() => {
                        setUsePhoneLogin(false);
                        formik.handleReset();
                      }}
                      icon={
                        <Icon
                          icon={Email}
                          color={PURPLE_FEEK}
                          size={20}
                        />
                      }
                    />
                  ) : (
                    <PrimaryButton
                      strokeVariant
                      label="Regístrate con Teléfono"
                      onClick={() => {
                        setUsePhoneLogin(true);
                        formik.handleReset();
                      }}
                      icon={
                        <Icon
                          icon={Smartphone}
                          color={PURPLE_FEEK}
                          size={20}
                        />
                      }
                    />
                  )}

                  <GoogleButton
                    onSuccess={handleLogin}
                    onFailure={handleLogin}
                    text="Regístrate con Google"
                  />
                </div>
              </Form>
            );
          }}
        </Formik>
      </div>
    </div>
  );
}

export default Registration;
