/** @jsxImportSource @emotion/react */
import { useState } from 'react';
import { css } from '@emotion/react';
import { useNavigate } from 'react-router-dom';
import { loadStripe } from '@stripe/stripe-js';
import { Elements } from '@stripe/react-stripe-js';

import { ROUTES } from 'constants';
import Icon from 'components/Icon';
import Text from 'components/Text';
import { WHITE } from 'styles/colors';
import Button from 'components/buttons/Button';
import Checkout from 'screens/Payment/Checkout';
import PlanSelection from 'screens/Payment/PlanSelection';
import { ReactComponent as FeekLogo } from 'assets/icons/FeekLogo.svg';
import TurbulentFlowColorsBg from 'assets/images/TurbulentFlowColorsBg.png';

const stripePromise = loadStripe(
  `${process.env.REACT_APP_STRIPE_KEY}`,
  {
    locale: 'es',
  },
);

const style = css`
  box-sizing: border-box;
  display: flex;
  flex-direction: column;
  align-items: center;
  background: url(${TurbulentFlowColorsBg}) no-repeat center;
  background-size: cover;
  padding: 48px;
  min-height: 100vh;

  .header {
    box-sizing: border-box;
    display: flex;
    justify-content: space-between;
    align-items: center;
    width: 100%;
    margin-bottom: 42px;
  }
`;

function Payment() {
  const navigate = useNavigate();
  const [index, setIndex] = useState(0);
  const [plan, setPlan] = useState({});

  const steps = [PlanSelection, Checkout];

  const handleBack = () => {
    index > 0 ? setIndex((crr) => crr - 1) : navigate(-1);
  };

  const handleNext = (plan) => {
    if (plan) setPlan(plan);
    setIndex((crr) => crr + 1);
  };

  const CurrentStepComponent = steps[index];

  return (
    <div css={style}>
      <div className="header">
        <Button onClick={() => navigate(ROUTES.HOME)}>
          <Icon icon={FeekLogo} color={WHITE} size={48} />
        </Button>
        <Text
          color={WHITE}
          fontSize={32}
          fontWeight="bold"
          align="center"
        >
          Plan de Suscripción
        </Text>
        <Button onClick={handleBack}>
          <Text color={WHITE} fontSize={16}>
            ← Regresar
          </Text>
        </Button>
      </div>

      <Elements stripe={stripePromise}>
        <CurrentStepComponent handleNext={handleNext} plan={plan} />
      </Elements>
    </div>
  );
}
export default Payment;
