/** @jsxImportSource @emotion/react */
import { css } from '@emotion/react';
import { useState, useEffect } from 'react';
import { useNavigate, useSearchParams } from 'react-router-dom';

import APIClient from 'redux/api';
import Card from 'components/Card';
import Text from 'components/Text';
import { ROUTES } from 'constants';
import handleError from 'utils/handleError';
import PasswordInput from 'components/inputs/PasswordInput';
import PrimaryButton from 'components/buttons/PrimaryButton';
import feekLogoOverBackground from 'utils/feekLogoOverBackground';
import TurbulentFlowColorsBg from 'assets/images/TurbulentFlowColorsBg.png';

const styles = css`
  display: flex;
  justify-content: center;
  align-items: center;
  width: 100%;
  min-height: 100vh;
  ${feekLogoOverBackground(TurbulentFlowColorsBg)}

  &,
  & :where(*) {
    box-sizing: border-box;
  }

  .cardInnerContainer {
    display: flex;
    padding: 30px;
    flex-direction: column;
    align-items: center;
    gap: 30px;
  }
`;

function CollaboratorNewPassword() {
  const navigate = useNavigate();
  const [searchParams] = useSearchParams();
  const [password, setPassword] = useState('');
  const [isLoading, setIsLoading] = useState(false);
  const [isLoginDisabled, setIsLoginDisabled] = useState(true);

  useEffect(() => {
    APIClient.validateInvitCollaborator(
      searchParams.get('account_invite'),
    ).catch((e) => {
      navigate(ROUTES.LOGIN);
      handleError(e);
    });
  }, []);

  const handleSignIn = () => {
    setIsLoading(true);
    APIClient.changePasswordCollaborator({
      account_invite: searchParams.get('account_invite'),
      new_password: password,
    })
      .then(() => navigate(ROUTES.LOGIN))
      .catch((e) => {
        handleError(e);
        setIsLoading(false);
      });
  };

  useEffect(() => {
    const validateRegister = password.length > 6;
    if (validateRegister) {
      setIsLoginDisabled(false);
    } else {
      setIsLoginDisabled(true);
    }
  }, [password]);

  return (
    <div css={styles}>
      <Card>
        <div className="cardInnerContainer">
          <Text fontSize={24}>Cambiar contraseña</Text>

          <PasswordInput
            value={password}
            onChange={(e) => setPassword(e.target.value)}
          />

          <PrimaryButton
            label="Iniciar sesión"
            onClick={handleSignIn}
            disabled={isLoginDisabled || isLoading}
          />
        </div>
      </Card>
    </div>
  );
}

export default CollaboratorNewPassword;
