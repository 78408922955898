import Icon from 'components/Icon';
import { WHITE } from 'styles/colors';
import { renderToString } from 'react-dom/server';
import { ReactComponent as FeekLogo } from 'assets/icons/FeekLogo.svg';

export default function feekLogoOverBackground(
  bgImage,
  bgPosition = 'center',
  bgSize = 'cover',
  bgRepeat = 'no-repeat',
  logoPosition = '64px 58px',
  logoSize = '32px 59px',
  logoRepeat = 'no-repeat',
  logoColor = WHITE,
) {
  const logoString = btoa(
    renderToString(
      <Icon icon={FeekLogo} color={logoColor} />,
    ).replace('<path', `<path fill="${logoColor}"`),
  );

  return `
  background-image: url('data:image/svg+xml;base64,${logoString}'), url(${bgImage});
  background-position: ${logoPosition}, ${bgPosition};
  background-size: ${logoSize}, ${bgSize};
  background-repeat: ${logoRepeat}, ${bgRepeat};
`;
}
