// Brand
export const BLUE_FEEK = '#5E48FF',
  PURPLE_FEEK = '#6456F2',
  PURPLE_FEEK_50 = '#6456F250',
  DYNAMIC_BLUE = '#00B6FF',
  GRADIENT_SIDEBAR =
    'linear-gradient(153.37deg, #b828ff 2.75%, #00c8ff 92.35%)',
  GRADIENT_PURPLE_BLUE =
    'linear-gradient(180deg, #9442FF 0%, #00B6FF 100%)',
  GRADIENT_DASHBOARD = 'linear-gradient(135deg, #bd50f8, #30cdfa)';

// UI
export const BLACK = '#000000',
  WHITE = '#ffffff',
  WHITE_25 = '#ffffff25',
  GRAY = '#5C5C5C',
  GRAY_DEFAULT = '#BEC4CD',
  GRAY_DARK = '#87878C',
  GRAY_LIGHT = '#D2D7DE',
  GRAY_LIGHTER = '#ECEDF4',
  GRAY_LIGHTEST = '#F3F4F9',
  GRAY_LIGHTEST_PLUS = '#F9F9FC',
  PURPLE_INACTIVE = '#8A8CB3',
  PURPLE_INACTIVE_LIGHT = '#eceafe',
  PURPLE_ACTIVE = '#CED1FF',
  PURPLE_ACTIVE_DARK = '#9895FF',
  GREEN_LIGHTER = '#E8F9DB',
  GRADIENT_PURPLE =
    'linear-gradient(166.98deg, #7773FA -3.04%, #5652E5 90.61%)',
  GRADIENT_PURPLE_LIGHT =
    'linear-gradient(180deg, #EFEEFB 0%, #C9C6F2 100%)',
  GRADIENT_BLUE =
    'linear-gradient(328.35deg, #2E8BFF 3.93%, #00B9FF 97.77%)',
  GRADIENT_RED = 'linear-gradient(180deg, #F25A5A 0%, #D04B4B 100%)';

// Status
export const RED = '#F24242',
  RED_LIGHT = '#fef7f7',
  GREEN = '#90D25B',
  GREEN_LIGHT = '#E8F9DB',
  YELLOW = '#FFC830',
  YELLOW_LIGHT = '#FCE8AF';

// Secondary: greens
export const GREEN_B = '#0DBC71',
  GREEN_C = '#029154',
  GREEN_E = '#34E8D7';

// Secondary: purples
export const PURPLE_B = '#7229E5',
  PURPLE_C = '#6405FA';

// Others

export const GRADIENT_INSTAGRAM =
    'linear-gradient(180deg, #F04A6F 7.94%, #B0329E 100%)',
  TRANSPARENT = 'transparent';
