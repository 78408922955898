const createInputEventHandler = (original, injected) => {
  return (...args) => {
    original?.(...args);
    injected(...args);
  };
};

export const forwardInputEventHandlers = (props, formikField) => {
  const { onBlur, onChange, ...restProps } = props;
  const { onBlur: formikOnBlur, onChange: formikOnChange } =
    formikField;

  return {
    ...restProps,
    onBlur: createInputEventHandler(onBlur, formikOnBlur),
    onChange: createInputEventHandler(onChange, formikOnChange),
  };
};
