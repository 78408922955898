/** @jsxImportSource @emotion/react */
import { css } from '@emotion/react';

import Text from 'components/Text';
import { GRAY_DARK } from 'styles/colors';
import { MEDIA_QUERIES } from 'styles/constants';
import { FormTextAreaInput } from 'components/inputs/TextAreaInput/index';
import { FormExpansibleInputList } from 'components/inputs/ExpansibleInputList';

const styles = css`
  display: grid;
  grid: repeat(2, auto) / repeat(2, minmax(0, 1fr));
  gap: 50px;

  .title {
    display: flex;
    align-items: baseline;
    justify-content: space-between;
    margin: 0 0 10px 0;
    padding: 0;
    cursor: default;
  }

  .textAreaWrapper {
    display: flex;
    flex-direction: column;
    justify-content: space-between;
    flex: 1 1 calc(50% - 1.5rem);
    margin: 0;
    padding: 0;
    border: none;
    box-sizing: border-box;
  }

  .titleTextArea {
    font-weight: 700;
    font-size: 21px;
    line-height: 25px;
  }
  .titleTextArea::placeholder {
    font-weight: initial;
    font-size: initial;
    line-height: initial;
  }

  ${MEDIA_QUERIES.mobile} {
    grid: auto auto / 1fr;
    gap: 30px;

    .cardButton {
      flex: 1 1 100%;
      max-width: 100%;
    }
  }
`;

function Step3Details() {
  return (
    <div css={styles}>
      <div className="textAreaWrapper">
        <Text className="title" fontSize={16} fontWeight="500">
          Título de la campaña
          <Text
            type="inline"
            align="right"
            fontSize={12}
            color={GRAY_DARK}
          >
            70 caracteres
          </Text>
        </Text>
        <FormTextAreaInput
          name="title"
          rows="8"
          maxLength="70"
          textAreaClassName="titleTextArea"
          placeholder="Escribe un descripción clara de la promoción que ofreces.
              Ej: “50% de descuento en nuestra barra de bebidas”"
        />
      </div>
      <div className="textAreaWrapper">
        <Text className="title" fontSize={16} fontWeight="500">
          Descripción
          <Text
            className="charMaxCount"
            type="inline"
            align="right"
            fontSize={12}
            color={GRAY_DARK}
          >
            600 caracteres
          </Text>
        </Text>
        <FormTextAreaInput
          rows="8"
          maxLength="600"
          name="description"
          placeholder="Describe qué debe mostrar el Feeker en su
            Historia Principal y sus Historias Extra.
            Ej: “Sube una foto tuya con tu bebida”"
        />
      </div>
      <FormExpansibleInputList
        name="task"
        placeholder="Escribe una sugerencia para la historia principal..."
        initialInputsCount={3}
        title={
          <Text className="title" fontSize={16} fontWeight="500">
            Historia principal
            <Text
              type="inline"
              align="right"
              fontSize={12}
              color={GRAY_DARK}
            >
              70 caracteres máximo
            </Text>
          </Text>
        }
      />
      <FormExpansibleInputList
        name="extraTask"
        placeholder="Escribe una sugerencia para una historia extra..."
        title={
          <Text className="title" fontSize={16} fontWeight="500">
            Historias extra
            <Text
              type="inline"
              align="right"
              fontSize={12}
              color={GRAY_DARK}
            >
              70 caracteres máximo
            </Text>
          </Text>
        }
      />
    </div>
  );
}

export default Step3Details;
