/** @jsxImportSource @emotion/react */
import PropTypes from 'prop-types';
import { css } from '@emotion/react';
import { useNavigate } from 'react-router-dom';
import { useSelector, useDispatch } from 'react-redux';

import {
  WHITE,
  GREEN_B,
  PURPLE_FEEK,
  GRAY_LIGHTEST,
  GRADIENT_PURPLE,
  PURPLE_INACTIVE,
} from 'styles/colors';
import { useState } from 'react';
import APIClient from 'redux/api';
import { ROUTES } from 'constants';
import Text from 'components/Text';
import Icon from 'components/Icon';
import { formatPrice } from 'utils/format';
import Modal from 'components/modals/Modal';
import handleError from 'utils/handleError';
import { setCustomerPlan } from 'redux/entities';
import PrimaryButton from 'components/buttons/PrimaryButton';
import { ReactComponent as CheckCircle } from 'assets/icons/CheckCircle.svg';
import FeekerFlyingLinearChart from 'assets/images/FeekerFlyingLinearChart.png';

const styles = css`
  box-sizing: border-box;
  display: flex;
  flex-direction: column;
  align-items: center;
  width: 450px;
  padding: 40px 60px;
  background-color: ${WHITE};
  border-radius: 8px;

  & > * {
    box-sizing: border-box;
  }
  .image {
    width: 280px;
    height: 172px;
    margin-bottom: 15px;
  }

  .title {
    margin-bottom: 15px;
  }
  .label {
    margin-bottom: 0.75rem;
  }
  .statusIcon {
    margin-left: 10px;
  }

  .button-container {
    margin-bottom: 22px;
    width: 100%;
    display: flex;
    justify-content: space-between;
    gap: 20px;
  }

  .primary-button {
    margin-top: 27px;
    width: 170px;
    border: 1px solid ${PURPLE_FEEK};
  }

  table {
    width: 100%;
    border-spacing: 0 6px;

    td {
      height: 30px;
    }

    tbody {
      tr:nth-of-type(odd) {
        background-color: ${GRAY_LIGHTEST};
      }
    }
  }

  table.upgrade {
    tr td:last-of-type *,
    tr th:last-of-type * {
      color: ${PURPLE_FEEK} !important;
    }
  }

  table.downgrade {
    tr td:last-of-type *,
    tr th:last-of-type * {
      color: ${PURPLE_INACTIVE} !important;
    }
  }
`;

function ModalChangePlan({
  isOpen,
  handleClose,
  planSelected,
  customerPlan,
}) {
  const navigate = useNavigate();
  const dispatch = useDispatch();

  const [isOnFirstStep, setIsOnFirstStep] = useState(true);

  const selectedCustomerId = useSelector(
    (state) => state.app.selectedCustomerId,
  );

  const changePlanConfirm = async () => {
    try {
      const response = await APIClient.changeSubscription({
        customerId: selectedCustomerId,
        planId: planSelected._id,
      });
      if (response.status === 200) {
        setIsOnFirstStep(false);
        dispatch(
          setCustomerPlan({
            planId: response.data,
            customerId: selectedCustomerId,
          }),
        );
      }
    } catch (error) {
      handleError(error);
    }
  };

  const closeModal = () => {
    setIsOnFirstStep(true);
    handleClose();
  };

  return (
    <Modal visible={isOpen} onRequestClose={closeModal} outsideOutlet>
      <div css={styles}>
        {isOnFirstStep ? (
          <>
            <Text
              fontSize={24}
              align="center"
              fontWeight="bold"
              lineHeight={32}
              className="title"
            >
              {planSelected?.credits < customerPlan?.credits
                ? '¿Estás seguro que deseas cambiar a ' +
                  planSelected?.name +
                  '?'
                : 'Multiplica tu alcance con el plan ' +
                  planSelected?.name}
            </Text>

            <table
              className={
                planSelected?.credits < customerPlan?.credits
                  ? 'downgrade'
                  : 'upgrade'
              }
            >
              <thead>
                <tr>
                  <th></th>
                  <th>
                    <Text
                      fontSize={13}
                      align="center"
                      fontWeight={600}
                      lineHeight={24}
                    >
                      Actual
                    </Text>
                  </th>
                  <th>
                    <Text
                      fontSize={13}
                      align="center"
                      fontWeight={600}
                      lineHeight={24}
                    >
                      Nuevo
                    </Text>
                  </th>
                </tr>
              </thead>
              <tbody>
                <tr>
                  <td>
                    <Text
                      fontSize={13}
                      align="center"
                      fontWeight={600}
                    >
                      Plan
                    </Text>
                  </td>
                  <td>
                    <Text
                      fontSize={13}
                      align="center"
                      fontWeight={400}
                    >
                      {customerPlan?.name}
                    </Text>
                  </td>
                  <td>
                    <Text
                      fontSize={13}
                      align="center"
                      fontWeight={400}
                    >
                      {planSelected?.name}
                    </Text>
                  </td>
                </tr>
                <tr>
                  <td>
                    <Text
                      fontSize={13}
                      align="center"
                      fontWeight={600}
                    >
                      Campañas
                    </Text>
                  </td>
                  <td>
                    <Text
                      fontSize={13}
                      align="center"
                      fontWeight={400}
                    >
                      {customerPlan?.credits}
                    </Text>
                  </td>
                  <td>
                    <Text
                      fontSize={13}
                      align="center"
                      fontWeight={400}
                    >
                      {planSelected?.credits}
                    </Text>
                  </td>
                </tr>
                <tr>
                  <td>
                    <Text
                      fontSize={13}
                      align="center"
                      fontWeight={600}
                    >
                      Alcance
                    </Text>
                  </td>
                  <td>
                    <Text
                      fontSize={13}
                      align="center"
                      fontWeight={400}
                    >
                      {customerPlan?.places_feekers} Feekers
                    </Text>
                  </td>
                  <td>
                    <Text
                      fontSize={13}
                      align="center"
                      fontWeight={400}
                    >
                      {planSelected?.places_feekers} Feekers
                    </Text>
                  </td>
                </tr>
                <tr>
                  <td>
                    <Text
                      fontSize={13}
                      align="center"
                      fontWeight={600}
                    >
                      Colaboradores
                    </Text>
                  </td>
                  <td>
                    <Text
                      fontSize={13}
                      align="center"
                      fontWeight={400}
                    >
                      {customerPlan?.places_collaborators}
                    </Text>
                  </td>
                  <td>
                    <Text
                      fontSize={13}
                      align="center"
                      fontWeight={400}
                    >
                      {planSelected?.places_collaborators}
                    </Text>
                  </td>
                </tr>
                <tr>
                  <td>
                    <Text
                      fontSize={13}
                      align="center"
                      fontWeight={600}
                    >
                      Costo
                    </Text>
                  </td>
                  <td>
                    <Text
                      fontSize={13}
                      align="center"
                      fontWeight={400}
                    >
                      {formatPrice(customerPlan?.price)} /mes
                    </Text>
                  </td>
                  <td>
                    <Text
                      fontSize={13}
                      align="center"
                      fontWeight={400}
                    >
                      {formatPrice(planSelected?.price)} /mes
                    </Text>
                  </td>
                </tr>
              </tbody>
            </table>

            <div className="button-container">
              <PrimaryButton
                color={WHITE}
                textColor={PURPLE_FEEK}
                label="Cancelar"
                onClick={closeModal}
                textProps={{
                  fontSize: 16,
                  fontWeight: 600,
                }}
                className="primary-button"
              />

              <PrimaryButton
                color={GRADIENT_PURPLE}
                textColor={WHITE}
                label="Confirmar cambio de Plan"
                onClick={changePlanConfirm}
                className="primary-button"
                textProps={{
                  fontSize: 16,
                  fontWeight: 600,
                  align: 'center',
                }}
              />
            </div>
            <Text
              fontSize={11}
              align="center"
              fontWeight="500"
              lineHeight={16}
            >
              Tu nuevo plan estará disponible en tu cuenta
              inmediatamente después de adquirirlo y se cobrará en tu
              próxima factura mensual.
            </Text>
          </>
        ) : (
          <>
            <Text
              fontSize={18}
              align="center"
              fontWeight="bold"
              className="title"
            >
              Suscripción modificada con éxito
              <Icon
                size={16}
                color={GREEN_B}
                icon={CheckCircle}
                className="statusIcon"
              />
            </Text>
            <Text
              fontSize={16}
              lineHeight={24}
              align="center"
              className="label"
            >
              Ya puedes continuar creando Campañas
            </Text>

            <img
              src={FeekerFlyingLinearChart}
              alt="Feeker looking report"
              className="image"
            />

            <div className="button-container">
              <PrimaryButton
                color={WHITE}
                textColor={PURPLE_FEEK}
                label="Cerrar"
                onClick={closeModal}
                textProps={{
                  fontSize: 16,
                  fontWeight: 600,
                }}
                className="primary-button"
              />

              <PrimaryButton
                color={GRADIENT_PURPLE}
                textColor={WHITE}
                label="Ir a Campañas"
                onClick={() => {
                  navigate(ROUTES.CAMPAIGNS);
                }}
                className="primary-button"
                textProps={{
                  fontSize: 16,
                  fontWeight: 600,
                  align: 'center',
                }}
              />
            </div>
          </>
        )}
      </div>
    </Modal>
  );
}

ModalChangePlan.propTypes = {
  handleClose: PropTypes.func,
  isOpen: PropTypes.bool,
  planSelected: PropTypes.object,
  customerPlan: PropTypes.object,
};

export default ModalChangePlan;
