/** @jsxImportSource @emotion/react */
import { useState } from 'react';
import PropTypes from 'prop-types';
import { css } from '@emotion/react';
import { useDispatch } from 'react-redux';

import {
  setCustomers,
  removeCustomersInvitations,
} from 'redux/entities';
import APIClient from 'redux/api';
import Text from 'components/Text';
import { selectCustomer } from 'redux/app';
import { RED, WHITE } from 'styles/colors';
import Modal from 'components/modals/Modal';
import handleError from 'utils/handleError';
import Button from 'components/buttons/Button';
import PrimaryButton from 'components/buttons/PrimaryButton';
import FeekerLookingReport from 'assets/images/FeekerLookingReport.png';

const styles = css`
  box-sizing: border-box;
  display: flex;
  flex-direction: column;
  align-items: center;
  gap: 24px;
  width: 450px;
  padding: 32px;
  background-color: ${WHITE};
  border-radius: 8px;

  & > * {
    box-sizing: border-box;
  }

  .image {
    width: 190px;
    height: 140px;
    margin-bottom: 20px;
  }

  .button {
    height: 46px;
  }

  .declineBtn {
    margin-top: -10px;
    text-decoration: underline;
    text-decoration-color: ${RED};

    &:disabled {
      opacity: 0.5;
    }
  }
`;

function AcceptInvitationToCustomerModal({
  isOpen,
  roleName,
  customerId,
  handleClose,
  customerName,
  roleDescription,
}) {
  const dispatch = useDispatch();
  const [isLoading, setIsLoading] = useState(false);

  const handleRespondInvitation = async (accepted) => {
    setIsLoading(true);

    try {
      const res = await APIClient.respondCustomerInvitation(
        customerId,
        accepted,
      );

      if (res.status === 201) {
        dispatch(removeCustomersInvitations(customerId));

        if (accepted) {
          dispatch(setCustomers([res.data]));
          dispatch(selectCustomer(res.data.customer._id));
        }

        handleClose();
      }
    } catch (error) {
      setIsLoading(false);
      handleError(error);
    }
  };

  return (
    <Modal visible={isOpen} onRequestClose={handleClose}>
      <div css={styles}>
        <Text fontSize={24} align="center" lineHeight={32}>
          Te han invitado a ser <strong>{roleName}</strong> de{' '}
          <strong>{customerName}</strong>
        </Text>
        <Text fontSize={16} lineHeight={24} align="center">
          Como {roleName} podrás: {roleDescription}
        </Text>
        <img
          src={FeekerLookingReport}
          alt="Feeker looking report"
          className="image"
        />
        <PrimaryButton
          className="button"
          disabled={isLoading}
          label="Aceptar Invitación"
          onClick={() => handleRespondInvitation(true)}
        />
        <Button
          disabled={isLoading}
          className="declineBtn"
          onClick={() => handleRespondInvitation(false)}
        >
          <Text color={RED} fontSize={14}>
            Rechazar Invitación
          </Text>
        </Button>
      </div>
    </Modal>
  );
}

AcceptInvitationToCustomerModal.propTypes = {
  handleClose: PropTypes.func,
  isOpen: PropTypes.bool,
  roleName: PropTypes.string,
  roleDescription: PropTypes.string,
  customerName: PropTypes.string,
  customerId: PropTypes.string,
};

export default AcceptInvitationToCustomerModal;
