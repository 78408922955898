/** @jsxImportSource @emotion/react */
import * as Yup from 'yup';
import PropTypes from 'prop-types';
import { css } from '@emotion/react';
import { useDispatch, useSelector } from 'react-redux';
import { Form, FormikProvider, useFormik } from 'formik';

import APIClient from 'redux/api';
import Text from 'components/Text';
import { setUser } from 'redux/app';
import { WHITE } from 'styles/colors';
import Modal from 'components/modals/Modal';
import handleError from 'utils/handleError';
import PrimaryButton from 'components/buttons/PrimaryButton';
import { FormTextInput } from 'components/inputs/TextInput/index';
import { FormPhoneInput } from 'components/inputs/PhoneInput/index';

const styles = css`
  display: flex;
  flex-direction: column;
  width: 25rem;
  padding: 30px;
  background: ${WHITE};
  border-radius: 8px;

  .title {
    margin-bottom: 20px;
  }

  .form {
    width: 100%;
  }

  .label {
    margin-bottom: 8px;
  }

  .buttonsWrapper {
    display: flex;
    flex-direction: row;
    justify-content: space-between;
    margin-top: 20px;
    gap: 1rem;
  }

  .phoneInputComponent .inputContainer .btn .dropDown {
    transform: translate(90px, 40px);
  }
`;

const formValidationSchema = Yup.object({
  email: Yup.string()
    .required('El email es requerido')
    .email('El email inválido'),
  name: Yup.string().required('El nombre es requerido'),
  phone: Yup.string()
    .required('El télefono es requerido')
    .min(12, 'El télefono tiene que tener al menos 10 numeros'),
});

function FormEditUserProfile({ onRequestClose }) {
  const dispatch = useDispatch();
  const { email, name, phone } = useSelector((state) => state.app);

  const formInitialValues = {
    name: name || '',
    phone: phone || '',
    email: email || '',
  };

  const updateProfile = async (values, actions) => {
    actions.setSubmitting(true);
    const userId = localStorage.getItem('userId');
    try {
      const response = await APIClient.updateUser(userId, values);
      if (response.status === 200) {
        dispatch(setUser(response.data));
        actions.resetForm({ values });
        actions.setSubmitting(false);
        onRequestClose();
      }
    } catch (e) {
      actions.setSubmitting(false);
      handleError(e);
    }
  };

  const formik = useFormik({
    validateOnMount: true,
    onSubmit: updateProfile,
    enableReinitialize: true,
    initialValues: formInitialValues,
    validationSchema: formValidationSchema,
  });

  return (
    <div css={styles}>
      <Text
        className="title"
        fontSize={20}
        fontWeight="bold"
        align="center"
      >
        Información de cuenta
      </Text>
      <FormikProvider value={formik}>
        <Form className="form">
          <Text className="label" fontSize={14}>
            Nombre
          </Text>
          <FormTextInput
            name="name"
            placeholder="Nombre de colaborador"
          />
          <Text className="label" fontSize={14}>
            Correo electrónico
          </Text>
          <FormTextInput
            name="email"
            placeholder="Correo electrónico"
          />
          <Text className="label" fontSize={14}>
            Teléfono
          </Text>
          <FormPhoneInput
            className="phoneInputComponent"
            name="phone"
          />
          <div className="buttonsWrapper">
            <PrimaryButton
              label="Cancelar"
              disabled={formik.isSubmitting}
              onClick={() => {
                formik.resetForm();
                onRequestClose?.();
              }}
              size="md"
              strokeVariant
              fontSize={16}
            />
            <PrimaryButton
              disabled={
                !formik.isValid ||
                formik.isSubmitting ||
                !formik.dirty
              }
              label="Guardar cambios"
              onClick={formik.submitForm}
              fontSize={16}
            />
          </div>
        </Form>
      </FormikProvider>
    </div>
  );
}

function ModalEditUser({ visible, onRequestClose }) {
  return (
    <Modal visible={visible} onRequestClose={onRequestClose}>
      <FormEditUserProfile onRequestClose={onRequestClose} />
    </Modal>
  );
}

ModalEditUser.propTypes = {
  visible: PropTypes.bool,
  onRequestClose: PropTypes.func,
};

FormEditUserProfile.propTypes = {
  onRequestClose: PropTypes.func,
};

export default ModalEditUser;
