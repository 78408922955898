/** @jsxImportSource @emotion/react */
import * as Yup from 'yup';
import { useState } from 'react';
import PropTypes from 'prop-types';
import { css } from '@emotion/react';
import { Form, Formik } from 'formik';
import { useTranslation } from 'react-i18next';
import { useDispatch, useSelector } from 'react-redux';

import {
  GRAY,
  WHITE,
  PURPLE_FEEK,
  GRAY_LIGHTER,
  GRAY_LIGHTEST,
} from 'styles/colors';
import APIClient from 'redux/api';
import Text from 'components/Text';
import handleError from 'utils/handleError';
import { rolesSelectValues } from 'constants';
import Button from 'components/buttons/Button';
import { MEDIA_QUERIES } from 'styles/constants';
import { setCustomerCollaborator } from 'redux/entities';
import PrimaryButton from 'components/buttons/PrimaryButton';
import { FormPhoneInput } from 'components/inputs/PhoneInput';
import { FormSelectInput } from 'components/inputs/SelectInput';
import { FormTextInput } from 'components/inputs/TextInput/index';
import AdministratorBackground1 from 'screens/Collaborators/components/AdministratorBackground1.jpg';
import AdministratorBackground2 from 'screens/Collaborators/components/AdministratorBackground2.png';
import AdministratorBackground3 from 'screens/Collaborators/components/AdministratorBackground3.png';

const styles = css`
  box-sizing: border-box;
  display: flex;
  flex-direction: column;
  width: 100%;

  .listContainer {
    box-sizing: border-box;
    display: flex;
    width: 100%;
    padding: 53px;
    gap: 80px;
  }

  .roles {
    box-sizing: border-box;
    width: 40%;
  }

  .form {
    box-sizing: border-box;
    flex-direction: column;
    flex: 1;

    .label {
      margin-bottom: 5px;
    }

    .textInputContainer {
      margin-bottom: 45px;
    }
  }

  .iconContainer {
    display: flex;
    justify-content: center;
    align-items: center;
    width: 41px;
    height: 41px;
    background: ${GRAY_LIGHTER};
    border-radius: 4.7619px;
  }

  .nameText {
    font-style: normal;
    font-weight: 600;
    font-size: 14px;
    color: ${PURPLE_FEEK};
    margin-left: 2rem;
  }

  .iconInfoContainer {
    display: flex;
    justify-content: center;
    align-items: center;
    width: 71px;
    height: 41px;
  }

  .titleText {
    font-style: normal;
    font-weight: 600;
    font-size: 30px;
    color: ${GRAY};
  }

  .subtitleText {
    font-style: normal;
    font-weight: 600;
    font-size: 14px;
    line-height: 15px;
    color: ${GRAY};
  }

  .headerListTitleContainer {
    display: flex;
    justify-content: end;
    margin-block-start: 40px;
    padding: 0 30px;
  }

  .buttonContainer {
    width: 60%;
  }

  .roleText {
    font-style: normal;
    font-weight: normal;
    font-size: 12px;
    display: flex;
    align-items: center;
    color: ${GRAY};
    margin-left: 5rem;
  }

  .updateIconContainer {
    display: flex;
    align-items: center;
    flex-direction: row;
    justify-content: flex-end;
    width: 30%;
  }

  .card {
    box-sizing: border-box;
    display: flex;
    flex-direction: row;
    align-items: center;
    gap: 17px;
    width: 100%;
    margin-bottom: 30px;
    backdrop-filter: blur(1.5px);
    border-radius: 15px;
    padding: 22px;

    .cardInfo {
      display: flex;
      flex: 1;
      flex-direction: column;
      gap: 7;
    }

    .cardTitle {
      display: flex;
      align-items: center;
      justify-content: space-between;
    }

    .cardIconContainer {
      box-sizing: border-box;
      display: flex;
      justify-content: center;
      align-items: center;
      height: 56px;
      width: 56px;
      background: ${GRAY_LIGHTER};
      opacity: 0.9;
      border-radius: 15px;
      padding: 10px;
    }

    .cardIconCheckContainer {
      height: 16px;
      width: 16px;
      border: 2px solid ${WHITE};
      box-sizing: border-box;
      border-radius: 8px;

      &.--active {
        background-color: ${WHITE};
      }
    }
  }

  .mobileHeading,
  .roleSelectWrapper {
    display: none;
  }

  ${MEDIA_QUERIES.tablet} {
    .listContainer {
      flex-direction: column;

      .roles,
      .form {
        width: 100%;
      }
    }
  }

  ${MEDIA_QUERIES.mobile} {
    .roles,
    .headerListTitleContainer {
      display: none;
    }

    .mobileHeading,
    .roleSelectWrapper {
      display: block;
    }

    .roleSelect .inputWrapper {
      height: 46px;
    }

    .mobileHeading {
      margin-block-end: 30px;
    }

    .listContainer {
      padding: 40px 24px;
      gap: 0;
      background: ${GRAY_LIGHTEST};

      .textInputContainer {
        margin-block-end: 10px;

        .label {
          margin-block-end: 10px;
        }
      }

      .buttonContainer {
        width: 100%;
      }
    }
  }
`;

const formValidationSchema = Yup.object({
  email: Yup.string()
    .required('El email es requerido')
    .email('El email inválido'),
  name: Yup.string().required('El nombre es requerido'),
  phone: Yup.string()
    .required('El télefono es requerido')
    .min(10, 'El télefono tiene que tener al menos 10 numeros'),
});

const formInitialValues = {
  name: '',
  phone: '',
  email: '',
};

function CollaborationCreationSelection(props) {
  const { handleIndex } = props;
  const dispatch = useDispatch();
  const { t } = useTranslation('common');
  const [selectedRole, setSelectedRole] = useState('customerAdmin');
  const roles = useSelector((state) => state.entities.roles);
  const selectedCustomerId = useSelector(
    (state) => state.app.selectedCustomerId,
  );

  const background = [
    AdministratorBackground1,
    AdministratorBackground2,
    AdministratorBackground3,
  ];

  const handleFormSubmit = async (values, actions) => {
    actions.resetForm({ values });
    actions.setSubmitting(true);

    try {
      const response = await APIClient.createCollaborator({
        role: selectedRole,
        ...values,
        customer_id: selectedCustomerId,
      });
      if (response.status === 201) {
        actions.setSubmitting(false);
        handleIndex(2);
        dispatch(
          setCustomerCollaborator({
            collaborator: response.data,
            customerId: selectedCustomerId,
          }),
        );
      }
    } catch (error) {
      actions.setSubmitting(false);
      handleError(error);
    }
  };

  const select = (role) => {
    setSelectedRole(role);
  };

  return (
    <div css={styles}>
      <div className="headerListTitleContainer">
        <Button onClick={() => handleIndex(0)}>
          <Text fontSize={14}>← Regresar a Colaboradores</Text>
        </Button>
      </div>

      <div className="listContainer">
        <Text fontSize={20} lineHeight={24} className="mobileHeading">
          Agregar Colaborador
        </Text>

        <div className="roles">
          {Object.values(roles).map((role, index) => (
            <Button
              key={index}
              onClick={() => select(role.name)}
              css={css`
                background-image: url(${background[index]});
                background-size: cover;
              `}
              className="card"
            >
              <div className="cardIconContainer">
                <img draggable={false} src={role.icon} />
              </div>
              <div className="cardInfo">
                <div className="cardTitle">
                  <Text
                    fontSize={20}
                    lineHeight={22}
                    color={WHITE}
                    fontWeight="bold"
                  >
                    {t('collaborators.' + role.name)}
                  </Text>
                  <div
                    className={
                      selectedRole === role.name
                        ? 'cardIconCheckContainer --active'
                        : 'cardIconCheckContainer'
                    }
                  />
                </div>
                <Text fontSize={12} lineHeight={15} color={WHITE}>
                  {t('collaborators.' + role.name + 'Description')}
                </Text>
              </div>
            </Button>
          ))}
        </div>

        <Formik
          validateOnMount
          onSubmit={handleFormSubmit}
          initialValues={formInitialValues}
          validationSchema={formValidationSchema}
        >
          {(formik) => {
            return (
              <Form className="form">
                <div className="textInputContainer roleSelectWrapper">
                  <Text className="label" fontSize={14}>
                    Rol
                  </Text>
                  <FormSelectInput
                    name="role"
                    options={rolesSelectValues}
                    className="roleSelect"
                  />
                </div>
                <div className="textInputContainer">
                  <Text className="label" fontSize={14}>
                    Nombre del colaborador
                  </Text>
                  <FormTextInput name="name" placeholder="Nombre" />
                </div>
                <div className="textInputContainer">
                  <Text className="label" fontSize={14}>
                    Teléfono
                  </Text>
                  <FormPhoneInput name="phone" />
                </div>
                <div className="textInputContainer">
                  <Text className="label" fontSize={14}>
                    Correo electrónico
                  </Text>
                  <FormTextInput
                    name="email"
                    placeholder="@email.com"
                  />
                </div>
                <div className="buttonContainer">
                  <PrimaryButton
                    disabled={
                      !formik.dirty ||
                      !formik.isValid ||
                      formik.isSubmitting
                    }
                    label="Crear colaborador"
                    onClick={formik.submitForm}
                  />
                </div>
              </Form>
            );
          }}
        </Formik>
      </div>
    </div>
  );
}

CollaborationCreationSelection.propTypes = {
  handleIndex: PropTypes.func,
  collaborators: PropTypes.arrayOf(PropTypes.object),
};

export default CollaborationCreationSelection;
