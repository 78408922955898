/** @jsxImportSource @emotion/react */
import { useState } from 'react';
import PropTypes from 'prop-types';
import { css } from '@emotion/react';

import Text from 'components/Text';
import Modal from 'components/modals/Modal';
import Button from 'components/buttons/Button';
import { MEDIA_QUERIES } from 'styles/constants';
import { GRADIENT_PURPLE, RED } from 'styles/colors';
import AccordionStatus from 'components/AccordionStatus';
import PrimaryButton from 'components/buttons/PrimaryButton';
import InstagramButton from 'components/buttons/InstagramButton';

const modalStyles = css`
  padding: 40px 44.5px;
  background: white;
  max-width: 450px;

  > div:first-of-type {
    margin-block-end: 15px;
  }

  > div:nth-of-type(2) {
    margin-block-end: 37px;
  }

  > div:nth-of-type(3) {
    display: flex;
    gap: 21px;
    width: 100%;
    margin-block-end: 15px;

    .instagramBtn.changeAccount {
      box-sizing: border-box;
      width: 100%;
      height: 60px;
      display: flex;
      gap: 18px;
      padding: 0 10px;
      border-radius: 8px;
      align-items: center;
      justify-content: center;
      background: ${GRADIENT_PURPLE};

      .changeAccountLabel {
        text-align: center;
        font-size: 16px;
        line-height: 17.6px;
      }

      ${MEDIA_QUERIES.mobile} {
        height: 40px;
      }
    }
  }
`;

function AccordionProfileInstagramSync({
  statusIcon = false,
  customer,
  className,
}) {
  const styles = css`
    display: flex;
    flex-direction: column;
    gap: 12px;

    .instagramBtn {
      opacity: 1;
    }
  `;

  const [showModal, setShowModal] = useState(false);

  return (
    <>
      <Modal
        visible={showModal}
        onRequestClose={() => setShowModal(false)}
      >
        <div css={modalStyles}>
          <Text
            fontSize={20}
            fontWeight="700"
            lineHeight={24}
            align="center"
          >
            ¿Estás seguro que deseas vincular otra cuenta de
            Instagram?
          </Text>
          <Text
            fontSize={14}
            fontWeight="500"
            lineHeight={21}
            align="center"
          >
            Esta acción provocará que la cuenta vinculada actualmente
            se desvincule.
          </Text>
          <div>
            <PrimaryButton
              strokeVariant
              label="Cancelar"
              textProps={{
                align: 'center',
                fontSize: 16,
                lineHeight: 17.6,
              }}
              onClick={() => setShowModal(false)}
            />
            <InstagramButton
              label="Continuar con cambio de cuenta"
              className="instagramBtn changeAccount"
              labelClassName="changeAccountLabel"
              showIcon={false}
            />
          </div>
        </div>
      </Modal>

      <AccordionStatus
        className={className}
        status={
          statusIcon ? !!customer?.instagram?.username : undefined
        }
        title="Instagram vinculado"
        content={
          <div css={styles}>
            {customer?.instagram?.username ? (
              <>
                <Text fontSize={12} fontWeight="500">
                  Estado de vinculación: <b>Activa</b>
                </Text>
                <InstagramButton
                  label={
                    customer?.instagram?.username ||
                    'Vincular Instagram'
                  }
                  className="instagramBtn"
                  showIcon={false}
                  disabled={true}
                />
                <Button onClick={() => setShowModal(true)}>
                  <Text fontSize={12} fontWeight="500" color={RED}>
                    Vincular otra cuenta
                  </Text>
                </Button>
              </>
            ) : (
              <>
                <Text fontSize={12} fontWeight="500">
                  Ingresa con la cuenta de Instagram de tu negocio.
                </Text>
                <InstagramButton
                  label="Vincular Instagram"
                  className="instagramBtn"
                  showIcon={true}
                  disabled={false}
                />
              </>
            )}
          </div>
        }
      />
    </>
  );
}

AccordionProfileInstagramSync.propTypes = {
  statusIcon: PropTypes.bool,
  customer: PropTypes.object,
  className: PropTypes.string,
};

export default AccordionProfileInstagramSync;
