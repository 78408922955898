import { useSelector } from 'react-redux';

export const useProfileCompletedStatus = () => {
  const { name, email, phone, selectedCustomerId } = useSelector(
    (state) => state.app,
  );
  const customers = useSelector((state) => state.entities.customers);
  const selectedCustomer = customers[selectedCustomerId]?.customer;

  const completionRequirements = [
    !!name && !!email && !!phone,
    !!selectedCustomer?.instagram,
    !!selectedCustomer?.image,
    !!selectedCustomer?.background_image,
    !!selectedCustomer?.location &&
      selectedCustomer?.days?.length > 0,
    !!selectedCustomer?.plan_id,
    selectedCustomer?.categories.length > 0,
  ];

  return {
    isProfileCompleted: completionRequirements.every(
      (requirement) => requirement,
    ),
    completionRequirements: completionRequirements,
  };
};
