/** @jsxImportSource @emotion/react */
import PropTypes from 'prop-types';
import { css } from '@emotion/react';

import { GRAY_LIGHTER, PURPLE_FEEK } from 'styles/colors';

function LinearProgressBar({
  progress = 0,
  progressColor = PURPLE_FEEK,
  backgroundColor = GRAY_LIGHTER,
  className,
}) {
  const styles = css`
    box-sizing: border-box;
    display: flex;
    width: 100%;
    height: 4px;
    border-radius: 16px;
    background-color: ${backgroundColor};
    overflow: hidden;

    .progress {
      box-sizing: border-box;
      width: ${progress}%;
      height: 100%;
      background-color: ${progressColor};
      border-radius: 16px;
    }
  `;

  return (
    <div css={styles} className={className}>
      <div className="progress" />
    </div>
  );
}

LinearProgressBar.propTypes = {
  progress: PropTypes.number,
  progressColor: PropTypes.string,
  backgroundColor: PropTypes.string,
  className: PropTypes.string,
};

export default LinearProgressBar;
