/** @jsxImportSource @emotion/react */
import * as Yup from 'yup';
import { useState } from 'react';
import PropTypes from 'prop-types';
import { css } from '@emotion/react';
import { Form, Formik } from 'formik';
import { toast } from 'react-toastify';
import { useDispatch } from 'react-redux';
import { useNavigate } from 'react-router-dom';

import APIClient from 'redux/api';
import { ROUTES } from 'constants';
import Text from 'components/Text';
import { setUser } from 'redux/app';
import handleError from 'utils/handleError';
import Button from 'components/buttons/Button';
import { FormTextInput } from 'components/inputs/TextInput';
import PrimaryButton from 'components/buttons/PrimaryButton';
import { FormPhoneInput } from 'components/inputs/PhoneInput';
import { GRAY_LIGHT, PURPLE_FEEK, WHITE } from 'styles/colors';
import feekLogoOverBackground from 'utils/feekLogoOverBackground';
import { FormPasswordInput } from 'components/inputs/PasswordInput';
import PasswordSecurityLevel from 'components/PasswordSecurityLevel';
import TurbulentFlowColorsBg from 'assets/images/TurbulentFlowColorsBg.png';
import { FormSplittedNumericalInput } from 'components/inputs/SplittedNumericalInput';

const styles = css`
  display: flex;
  justify-content: center;
  align-items: center;
  min-height: 100vh;
  padding: 50px;
  ${feekLogoOverBackground(TurbulentFlowColorsBg)};

  .formContainer {
    box-sizing: border-box;
    display: flex;
    align-items: stretch;
    flex-direction: column;
    width: 502px;
    height: min-content;
    background-color: ${WHITE};
    box-shadow: 0px 0px 25px rgba(0, 0, 0, 0.15);
    border-radius: 15px;
    padding: 50px 60px;
  }

  .title {
    margin-bottom: 30px;
  }

  .divider {
    border-top: 1px solid ${GRAY_LIGHT};
    width: 100%;
  }

  /* firstStep */
  .firstStep__inputs {
    display: flex;
    flex-direction: column;
    width: 100%;
    margin-bottom: 15px;
  }

  .firstStep__inputs > div:nth-of-type(2) {
    margin-bottom: 25px;
  }

  .firstStep__backBtn {
    margin-top: 40px;
  }

  /* secondStep */
  .secondStep__form {
    display: flex;
    flex-direction: column;
    align-items: center;
    gap: 30px;
  }
  .secondStep__form button:last-of-type {
    width: 343px;
  }

  /* thirStep */
  .thirdStep__form button:last-of-type {
    margin-top: 30px;
  }

  /* header */
  .headerContainer {
    display: flex;
    position: absolute;
    top: 10px;
    width: 100%;
    height: 50px;
    justify-content: flex-end;
    align-items: center;
  }

  .registrationText {
    margin-right: 20px;
  }

  .buttonRegistrationContainer {
    display: flex;
    top: 10px;
    width: 150px;
    right: 10px;
  }

  .createAccount {
    height: 40px;
    width: 130px;
    border-radius: 15px;
  }
`;

const firstStepFormValidationSchema = Yup.object().shape(
  {
    email: Yup.string()
      .test(
        'one-not-empty',
        'Uno de los datos es requerido',
        function (value) {
          const phone = this.parent.phone || '';
          return !!value || !(phone.length < 3);
        },
      )
      .when('phone', {
        is: (phone) => {
          return phone?.length <= 3;
        },
        then: Yup.string().email('El email es inválido'),
      }),
    phone: Yup.string()
      .test(
        'one-not-empty',
        'Uno de los datos es requerido',
        function (value) {
          const { email } = this.parent;
          return !(value?.length < 3) || !!email;
        },
      )
      .test(
        'min-length',
        'El télefono tiene que tener al menos 10 numeros',
        function (value) {
          const { email } = this.parent;
          return !!email || value?.length >= 10;
        },
      ),
  },
  ['phone', 'email'],
);

const firstStepformInitialValues = {
  email: '',
  phone: '',
};

const FirstStep = (props) => {
  const { navigate, next } = props;
  const handleFormSubmit = async (values, actions) => {
    actions.resetForm({ values });
    actions.setSubmitting(true);

    try {
      const { status } =
        await APIClient.sendForgotPasswordVerificationCode({
          key_user: values.email.toLowerCase() || '+' + values.phone,
        });
      if (status === 200) {
        next(values.email.toLowerCase() || '+' + values.phone);
      }
    } catch (error) {
      if (error instanceof Error) {
        if (error.message instanceof Array) {
          toast.error(error.message.join(' '));
        } else {
          toast.error(error.message);
        }
      } else {
        toast.error('Algo inesperado paso');
      }
    } finally {
      actions.setSubmitting(false);
    }
  };

  return (
    <Formik
      validateOnMount
      onSubmit={handleFormSubmit}
      initialValues={firstStepformInitialValues}
      validationSchema={firstStepFormValidationSchema}
    >
      {(formik) => {
        return (
          <Form className="formContainer">
            <Text className="title" fontSize={24} fontWeight="bold">
              Introduce tu email o teléfono
            </Text>
            <div className="firstStep__inputs">
              <FormTextInput
                placeholder="Email"
                name="email"
                onChange={() => formik.setFieldValue('phone', '52')}
              />
              <Text fontSize={16} lineHeight={19} align="center">
                ó
              </Text>
              <FormPhoneInput
                name="phone"
                onChange={() => formik.setFieldValue('email', '')}
              />
            </div>
            <PrimaryButton
              label="Continuar"
              onClick={formik.submitForm}
              disabled={!formik.isValid || formik.isSubmitting}
            />
            <Button
              onClick={() => navigate('/')}
              className="firstStep__backBtn"
            >
              <Text
                fontWeight="700"
                fontSize={10}
                lineHeight={14.4}
                color={PURPLE_FEEK}
                align="center"
              >
                ← Regresar a Inicio de Sesión
              </Text>
            </Button>
          </Form>
        );
      }}
    </Formik>
  );
};

FirstStep.propTypes = {
  navigate: PropTypes.func,
  next: PropTypes.func,
};

const secondStepFormValidationSchema = Yup.object().shape({
  code: Yup.string()
    .required('El código es requerido')
    .min(6, 'El código debe tener al menos 6 digitos'),
});

const secondStepformInitialValues = {
  code: '',
};

const SecondStep = (props) => {
  const [isCodeSendLoading, setIsCodeSendLoading] = useState(false);
  const { keyUser, next } = props;
  const isEmail = keyUser.includes('@');

  const handleFormSubmit = async (values, actions) => {
    actions.resetForm({ values });
    actions.setSubmitting(true);
    try {
      const { status } = await APIClient.forgotPasswordValidateCode({
        key_user: keyUser,
        code: values.code,
      });
      if (status === 200) {
        next();
      }
    } catch (error) {
      if (error instanceof Error) {
        if (error.message instanceof Array) {
          toast.error(error.message.join(' '));
        } else {
          toast.error(error.message);
        }
      } else {
        toast.error('Algo inesperado paso');
      }
    } finally {
      actions.setSubmitting(false);
    }
  };

  const handleResendCode = async () => {
    try {
      setIsCodeSendLoading(true);
      const { status } =
        await APIClient.sendForgotPasswordVerificationCode({
          key_user: keyUser,
        });
      if (status === 200) {
        toast.success('El código fue reenviado con exito.');
      }
    } catch (error) {
      if (error instanceof Error) {
        if (error.message instanceof Array) {
          toast.error(error.message.join(' '));
        } else {
          toast.error(error.message);
        }
      } else {
        toast.error('Algo inesperado paso');
      }
    } finally {
      setIsCodeSendLoading(false);
    }
  };
  return (
    <Formik
      validateOnMount
      onSubmit={handleFormSubmit}
      initialValues={secondStepformInitialValues}
      validationSchema={secondStepFormValidationSchema}
    >
      {(formik) => {
        return (
          <Form className="formContainer">
            <Text
              className="title"
              fontSize={24}
              fontWeight="bold"
              align="center"
            >
              Revisa tu{' '}
              {isEmail ? ' correo electrónico' : ' teléfono'}
            </Text>
            <div className="secondStep__form">
              <Text lineHeight={24} fontSize={16} align="center">
                Introduce el código que enviamos a tu
                {isEmail ? ' correo ' : ' teléfono '}
                <Text
                  fontWeight="700"
                  fontSize={16}
                  lineHeight={24}
                  type="inline"
                >
                  {keyUser}
                </Text>
                {isEmail ? '' : ' vía SMS '}
                para restablecer tu contraseña.
              </Text>
              <FormSplittedNumericalInput name="code" />
              <PrimaryButton
                label="Verificar código"
                onClick={formik.submitForm}
                disabled={!formik.isValid || formik.isSubmitting}
              />
              <div className="divider" />
              <Text align="center" fontSize={16}>
                ¿No recibiste el código?
              </Text>
              <PrimaryButton
                strokeVariant
                onClick={handleResendCode}
                label={`Reenviar el código a mi  ${
                  isEmail ? ' correo ' : ' teléfono '
                }`}
                disabled={isCodeSendLoading || formik.isSubmitting}
              />
            </div>
          </Form>
        );
      }}
    </Formik>
  );
};

SecondStep.propTypes = {
  next: PropTypes.func,
  keyUser: PropTypes.string,
};

const thirdStepFormValidationSchema = Yup.object().shape({
  password: Yup.string()
    .required('La contraseña es requerida')
    .min(6, 'La contraseña debe tener al menos 6 caracters')
    .max(30, 'La contraseña no puede tener mas de 30 caracters')
    .matches(
      /[0-9]/,
      'La contraseña tiene que tener al menos un numero',
    )
    .matches(
      /[A-Z]/,
      'La contraseña tiene que tener al menos una letra mayúscula',
    ),
  newPassword: Yup.string().test(
    'both-equals',
    'Las contraseñas no coinciden',
    function (value) {
      const { password } = this.parent;
      return password === value;
    },
  ),
});

const thirdStepformInitialValues = {
  password: '',
  newPassword: '',
};

const ThirdStep = (props) => {
  const dispatch = useDispatch();
  const { keyUser, navigate } = props;

  const handleFormSubmit = async (values, actions) => {
    actions.resetForm({ values });
    actions.setSubmitting(true);
    const payload = {
      key_user: keyUser,
      new_password: values.password,
    };

    try {
      const {
        status,
        data: { access_token, user },
      } = await APIClient.resetPassword(payload);
      if (status === 201) {
        localStorage.setItem('userId', user._id);
        APIClient.setAuthenticatedInstance(access_token);
        dispatch(setUser(user));
      }
      navigate(ROUTES.HOME);
    } catch (error) {
      actions.setSubmitting(false);
      handleError(error);
    }
  };

  return (
    <Formik
      validateOnMount
      onSubmit={handleFormSubmit}
      initialValues={thirdStepformInitialValues}
      validationSchema={thirdStepFormValidationSchema}
    >
      {(formik) => {
        return (
          <Form className="formContainer">
            <Text
              className="title"
              fontSize={24}
              fontWeight="bold"
              align="center"
            >
              Crea tu nueva contraseña
            </Text>
            <div className="thirdStep__form">
              <FormPasswordInput
                name="password"
                placeholder="Contraseña Nueva"
              />
              <FormPasswordInput
                name="newPassword"
                placeholder="Confirmar Contraseña Nueva"
              />
              <PasswordSecurityLevel
                value={
                  [
                    formik.values.password.length > 6,
                    formik.values.password.match(/[0-9]/),
                    formik.values.password.match(/[A-Z]/g),
                  ].filter((value) => Boolean(value)).length
                }
                maxValue={3}
              />

              <PrimaryButton
                disabled={!formik.isValid || formik.isSubmitting}
                onClick={formik.submitForm}
                label="Guardar contraseña"
              />
            </div>
          </Form>
        );
      }}
    </Formik>
  );
};

ThirdStep.propTypes = {
  navigate: PropTypes.func,
  keyUser: PropTypes.string,
};

const forgotPasswordSteps = [FirstStep, SecondStep, ThirdStep];

function ForgotPassword() {
  const [step, setStep] = useState(0);
  const [keyUser, setKeyUser] = useState('');
  const navigate = useNavigate();
  const next = (keyUser) => {
    if (keyUser) {
      setKeyUser(keyUser);
    }
    return setStep((prevStep) =>
      Math.min(forgotPasswordSteps.length - 1, prevStep + 1),
    );
  };

  const CurrentStepComponent = forgotPasswordSteps[step];

  return (
    <div css={styles}>
      <div className="headerContainer">
        <Text
          fontSize={12}
          color={WHITE}
          className="registrationText"
        >
          ¿No tienes cuenta?
        </Text>

        <div className="buttonRegistrationContainer">
          <PrimaryButton
            label={'Regístrate'}
            onClick={() => navigate(ROUTES.REGISTRATION)}
            strokeVariant
            className="createAccount"
            fontSize={13}
          />
        </div>
      </div>

      <CurrentStepComponent
        next={next}
        keyUser={keyUser}
        navigate={navigate}
      />
    </div>
  );
}

export default ForgotPassword;
