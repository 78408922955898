/** @jsxImportSource @emotion/react */
import PropTypes from 'prop-types';
import { css } from '@emotion/react';
import { toast } from 'react-toastify';
import { useSearchParams } from 'react-router-dom';
import { useEffect, useRef, useState } from 'react';
import { useDispatch, useSelector } from 'react-redux';

import {
  BLACK,
  WHITE,
  GRAY_DARK,
  PURPLE_FEEK,
  DYNAMIC_BLUE,
} from 'styles/colors';
import APIClient from 'redux/api';
import Icon from 'components/Icon';
import Text from 'components/Text';
import { selectCustomer } from 'redux/app';
import handleError from 'utils/handleError';
import { setCustomers } from 'redux/entities';
import Button from 'components/buttons/Button';
import AccordionStatus from 'components/AccordionStatus';
import { ReactComponent as X } from 'assets/icons/X.svg';
import ModalEditUser from 'components/modals/ModalEditUser';
import PrimaryButton from 'components/buttons/PrimaryButton';
import AccordionProfilePlan from 'components/AccordionProfilePlan';
import AccordionProfileImage from 'components/AccordionProfileImage';
import AccordionProfileAddress from 'components/AccordionProfileAddress';
import TurbulentFlowColorsBg from 'assets/images/TurbulentFlowColorsBg.png';
import { ReactComponent as PenInSquare } from 'assets/icons/PenInSquare.svg';
import AccordionProfileCategories from 'components/AccordionProfileCategories';
import { ReactComponent as CameraPlusSign } from 'assets/icons/CameraPlusSign.svg';
import AccordionProfileInstagramSync from 'components/AccordionProfileInstagramSync';

const styles = css`
  box-sizing: border-box;
  display: flex;
  flex-direction: column;
  align-items: center;
  width: 350px;
  height: fit-content;
  padding: 27px;
  border-radius: 8px;
  background: url(${TurbulentFlowColorsBg}) no-repeat center;
  background-size: cover;
  flex-shrink: 0;

  .hidden {
    display: none;
  }

  .firstStep,
  .secondStep {
    display: flex;
    flex-direction: column;
    align-items: center;
  }

  .firstStep__image {
    position: relative;
  }

  .firstStep__image .editBtn {
    position: absolute;
    display: flex;
    justify-content: center;
    align-items: center;
    height: 60px;
    width: 60px;
    background-color: ${WHITE};
    border-radius: 50%;
    top: 10px;
    right: 10px;
  }

  .firstStep__image .progress {
    position: relative;
    box-sizing: border-box;
    display: flex;
    justify-content: center;
    align-items: center;
    width: 220px;
    height: 220px;
    margin-bottom: 35px;
    transform: rotate(90deg);
  }

  .firstStep__image svg {
    margin: 0;
  }

  .firstStep__image svg circle {
    stroke-width: 2px;
    stroke: ${WHITE};
    stroke-linecap: round;
    fill: none;
  }

  .firstStep__image svg circle:nth-of-type(2) {
    stroke-dasharray: 100;
    stroke: ${DYNAMIC_BLUE};
  }

  .firstStep__image .image {
    position: absolute;
    display: flex;
    justify-content: center;
    align-items: center;
    background: ${GRAY_DARK};
    border-radius: 50%;
    width: 186px;
    height: 186px;
    left: 17px;
    top: 17px;
  }

  .firstStep__image .image {
    position: absolute;
    left: 17px;
    top: 17px;
    display: flex;
    justify-content: center;
    align-items: center;
    width: 186px;
    height: 186px;
    overflow: hidden;
    background: ${GRAY_DARK};
    border-radius: 50%;
  }

  .image__avatar {
    width: 100%;
    height: 100%;
    object-fit: contain;
  }

  .firstStep > :last-of-type {
    margin-top: 39px;
    width: 100%;
    max-width: 279px;
  }

  .secondStep__closeBtn {
    display: flex;
    justify-content: center;
    align-items: center;
    align-self: flex-end;
    width: 32px;
    height: 32px;
    margin-bottom: 8px;
    border-radius: 50%;
    background-color: ${WHITE};
  }

  .secondStep__accordions {
    margin-top: 30px;
    width: 100%;
  }

  .secondStep__accordions > * {
    box-sizing: border-box;
    width: 100%;
    margin-top: 0px;
    margin-bottom: 18px;
    max-width: 295px;
  }

  .accountData {
    .accountDataItem {
      word-break: break-word;
      margin-bottom: 8px;
      &:last-of-type {
        margin-bottom: 12px;
      }
    }

    .button {
      width: 120px;
      height: 30px;
    }
  }
`;

function ContentCompleteProfile({ completionRequirements }) {
  const inputFile = useRef();
  const [isLoading, setIsLoading] = useState(false);
  const dispatch = useDispatch();
  const [searchParams, setSearchParams] = useSearchParams();
  const { email, name, phone, selectedCustomerId } = useSelector(
    (state) => state.app,
  );
  const customers = useSelector((state) => state.entities.customers);
  const selectedCustomer = customers[selectedCustomerId]?.customer;

  const [isOnFirstStep, setIsOnFirstStep] = useState(true);

  const categories = useSelector(
    (state) => state.entities.categories,
  );

  const percentage =
    (completionRequirements.filter((value) => value).length * 100) /
    completionRequirements.length;

  const openFileBrowser = (event) => {
    event.stopPropagation();
    inputFile.current.click();
  };

  function handleChange(event) {
    event.stopPropagation();
    const {
      target: { value, files },
    } = event;
    const [image] = files || [];
    handleChangeImage(image || value);
  }

  const [
    isModalUpdateProfileVisible,
    setIsModalUpdateProfileVisible,
  ] = useState(false);

  const handleCloseUpdateProfile = () => {
    setIsModalUpdateProfileVisible(false);
  };

  const handleChangeImage = async (file) => {
    setIsLoading(true);
    if (file.type.includes('image')) {
      await APIClient.uploadFile({ file })
        .then(({ data: { Location } }) => {
          APIClient.updateCustomer(
            { image: Location },
            selectedCustomerId,
          ).then(() => {
            APIClient.getCustomers().then(({ data }) => {
              const customers = data.reduce(
                (prev, crr) => ({
                  ...prev,
                  [crr.customer._id]: crr,
                }),
                {},
              );
              dispatch(setCustomers(customers));
            });
            setIsLoading(false);
          });
        })
        .catch((e) => {
          setIsLoading(false);
          handleError(e);
        });
    }
  };

  const onShowUpdateProfileUserModal = (event) => {
    event.stopPropagation();
    setIsModalUpdateProfileVisible(true);
  };

  useEffect(() => {
    const code = searchParams.get('code');
    const updateInstagram = async () => {
      if (code && selectedCustomerId) {
        try {
          const payload = {
            instagram_token: code,
            customer_id: selectedCustomerId,
            redirect_uri: window.location.href.slice(
              0,
              window.location.href.indexOf('?'),
            ),
          };
          const res = await APIClient.syncUpInstagramCustomer(
            payload,
          );
          if (res.status === 201) {
            toast.success('Instagram vinculado con éxito.');
            await APIClient.getCustomers().then(({ data }) => {
              const customers = data.reduce(
                (prev, crr) => ({ ...prev, [crr.customer._id]: crr }),
                {},
              );
              if (!(selectedCustomerId in customers)) {
                dispatch(selectCustomer(data[0].customer._id));
              }
              dispatch(setCustomers(customers));
            });
          } else {
            toast.error('Error al vincular con Instagram');
          }
        } catch (error) {
          handleError(error);
        } finally {
          setSearchParams('');
        }
      }
    };
    updateInstagram();
  }, [searchParams, selectedCustomerId]);

  return (
    <div css={styles} className>
      <ModalEditUser
        visible={isModalUpdateProfileVisible}
        onRequestClose={handleCloseUpdateProfile}
      />

      {isOnFirstStep ? (
        <div className="firstStep">
          <div className="firstStep__image">
            <svg className="progress">
              <circle cx="110" cy="110" r="105" />
              <circle
                cx="110"
                cy="110"
                r="105"
                pathLength="100"
                css={css`
                  stroke-dashoffset: ${100 - percentage};
                `}
              />
            </svg>
            <Button
              className="image"
              disabled={isLoading}
              onClick={openFileBrowser}
            >
              {selectedCustomer?.image ? (
                <img
                  src={selectedCustomer?.image}
                  className="image__avatar"
                />
              ) : (
                <div>
                  <Icon
                    icon={CameraPlusSign}
                    color={WHITE}
                    size={70}
                  />
                  <Text
                    align="center"
                    color={WHITE}
                    fontSize={10}
                    lineHeight={27}
                  >
                    Editar foto
                  </Text>
                </div>
              )}
            </Button>
            <Button
              className="editBtn"
              disabled={isLoading}
              onClick={openFileBrowser}
            >
              <input
                type="file"
                id="file"
                accept="image/*"
                onChange={handleChange}
                ref={inputFile}
                className="hidden"
              />
              <Icon
                icon={PenInSquare}
                size={20}
                color={PURPLE_FEEK}
              />
            </Button>
          </div>
          <Text
            color={WHITE}
            fontSize={25}
            lineHeight={28}
            align="center"
          >
            Tu perfil esta al {parseInt(percentage)}%, para crear tu
            primera campaña Feek debes tener el 100%
          </Text>
          <div className="footerBootom">
            <PrimaryButton
              label="Completar"
              onClick={() => setIsOnFirstStep(false)}
            />
          </div>
        </div>
      ) : (
        <div className="secondStep">
          <Button
            onClick={() => setIsOnFirstStep(true)}
            className="secondStep__closeBtn"
          >
            <Icon icon={X} size={10} color={BLACK} />
          </Button>
          <Text
            color={WHITE}
            fontSize={25}
            lineHeight={20}
            align="center"
          >
            Completa el perfil al <b>100% </b>
            para Feek Negocios
          </Text>
          <div className="secondStep__accordions">
            <AccordionStatus
              status={!!(name && email && phone)}
              title="Datos de cuenta"
              content={
                <div className="accountData">
                  <Text fontSize={12} className="accountDataItem">
                    {name}
                  </Text>
                  <Text fontSize={12} className="accountDataItem">
                    {phone}
                  </Text>
                  <Text fontSize={12} className="accountDataItem">
                    {email}
                  </Text>

                  <PrimaryButton
                    strokeVariant
                    fontSize={12}
                    label="Editar"
                    className="button"
                    onClick={onShowUpdateProfileUserModal}
                  />
                </div>
              }
            />

            <AccordionProfileInstagramSync
              statusIcon
              customer={selectedCustomer}
            />

            <AccordionProfileImage
              statusIcon
              customer={selectedCustomer}
              imageType="image"
            />

            <AccordionProfileImage
              statusIcon
              customer={selectedCustomer}
              imageType="background_image"
            />

            <AccordionProfileAddress
              statusIcon
              customer={selectedCustomer}
            />

            <AccordionProfilePlan
              statusIcon
              customer={selectedCustomer}
              showAddOn={!!selectedCustomer?.plan_id}
            />

            <AccordionProfileCategories
              statusIcon
              categories={categories}
              customer={selectedCustomer}
            />
          </div>
        </div>
      )}
    </div>
  );
}

ContentCompleteProfile.propTypes = {
  next: PropTypes.func,
  completionRequirements: PropTypes.arrayOf(PropTypes.bool),
};

export default ContentCompleteProfile;
