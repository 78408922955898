/** @jsxImportSource @emotion/react */
import { css } from '@emotion/react';

import Text from 'components/Text';
import { PURPLE_INACTIVE } from 'styles/colors';
import LinearChartsInCircle from 'assets/images/LinearChartsInCircle.svg';

const style = css`
  .icon {
    width: 90px;
    height: 90px;
  }
`;

export default function MissingData() {
  return (
    <div css={[style]} className="missingData">
      <Text fontSize={16} lineHeight={22.4} color={PURPLE_INACTIVE}>
        Aún estamos recabando datos suficientes para generar esta
        gráfica.
      </Text>
      <img className="icon" src={LinearChartsInCircle} />
    </div>
  );
}
