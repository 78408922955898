/** @jsxImportSource @emotion/react */
import { useState } from 'react';
import PropTypes from 'prop-types';
import { css } from '@emotion/react';
import { useDispatch, useSelector } from 'react-redux';

import APIClient from 'redux/api';
import Text from 'components/Text';
import { selectCustomer } from 'redux/app';
import Modal from 'components/modals/Modal';
import handleError from 'utils/handleError';
import { removeCustomer } from 'redux/entities';
import { WHITE, GRADIENT_RED } from 'styles/colors';
import PrimaryButton from 'components/buttons/PrimaryButton';

const styles = css`
  padding: 45px 25px;
  max-width: 450px;
  border-radius: 16px;

  .container {
    display: flex;
    flex-direction: column;
    background: ${WHITE};
  }

  .title {
    margin-bottom: 20px;
  }

  .label {
    margin-bottom: 8px;
    line-height: 1.5;
  }

  .title,
  .label {
    padding-left: 55px;
    padding-right: 55px;
  }

  .buttonsWrapper {
    display: flex;
    flex-direction: row;
    justify-content: space-between;
    margin-top: 20px;
    margin-left: 20px;
    margin-right: 20px;
    gap: 1rem;

    > div > div {
      text-align: center;
    }
  }
`;
function ModalContentUnlinkBusiness({
  visible,
  onRequestClose,
  customerToUnlink,
}) {
  const dispatch = useDispatch();
  const [isLoading, setIsLoading] = useState(false);
  const customers = Object.values(
    useSelector((state) => state.entities.customers),
  );
  const selectedCustomerId = useSelector(
    (state) => state.app.selectedCustomerId,
  );

  const unlinkCustomer = async () => {
    setIsLoading(true);
    try {
      const res = await APIClient.unlinkCustomer(customerToUnlink);
      if (res.status === 200) {
        if (selectedCustomerId === customerToUnlink) {
          dispatch(
            selectCustomer(
              customers[0] === customerToUnlink
                ? customers[1]
                : customers[0],
            ),
          );
        }

        dispatch(removeCustomer(customerToUnlink));
        onRequestClose();
      }
    } catch (error) {
      setIsLoading(false);
      handleError(error);
    }
  };

  return (
    <Modal visible={visible} onRequestClose={onRequestClose}>
      <div css={styles}>
        <div className="container">
          <Text
            className="title"
            fontSize={20}
            fontWeight="600"
            align="center"
          >
            ¿Estás seguro que deseas desvincularte del negocio?
          </Text>
          <Text className="label" fontSize={14} align="center">
            Esta acción no puede deshacerse, una vez que desvincules
            el Negocio de tu cuenta perderás acceso a él.
          </Text>

          <div className="buttonsWrapper">
            <PrimaryButton
              label="Cancelar"
              onClick={onRequestClose}
              fontSize={16}
              strokeVariant
            />
            <PrimaryButton
              fontSize={16}
              disabled={isLoading}
              color={GRADIENT_RED}
              onClick={unlinkCustomer}
              label="Confirmar Desvinculación"
            />
          </div>
        </div>
      </div>
    </Modal>
  );
}

ModalContentUnlinkBusiness.propTypes = {
  visible: PropTypes.bool,
  onRequestClose: PropTypes.func,
  customerToUnlink: PropTypes.string,
};

export default ModalContentUnlinkBusiness;
