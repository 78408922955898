/** @jsxImportSource @emotion/react */
import PropTypes from 'prop-types';
import { css } from '@emotion/react';

import { BLACK, WHITE } from 'styles/colors';

function Card({ size = 'small', children }) {
  const styles = css`
    ${size === 'small'
      ? 'position: absolute; width: 502px;'
      : 'width: 100%'}
    height: auto;
    background: ${WHITE};
    box-shadow: 0px 0px 25px ${BLACK}26;
    border-radius: 15px;
  `;

  return <div css={styles}>{children}</div>;
}

export default Card;

Card.propTypes = {
  size: PropTypes.oneOf(['small', 'large']),
  children: PropTypes.oneOf([
    PropTypes.element,
    PropTypes.arrayOf(PropTypes.element),
  ]).isRequired,
};
