/** @jsxImportSource @emotion/react */
import moment from 'moment';
import PropTypes from 'prop-types';
import { css } from '@emotion/react';

import Text from 'components/Text';
import Icon from 'components/Icon';
import { MEDIA_QUERIES } from 'styles/constants';
import { ReactComponent as Clock } from 'assets/icons/Clock.svg';
import { PURPLE_FEEK, PURPLE_INACTIVE_LIGHT } from 'styles/colors';
import { ReactComponent as Calendar } from 'assets/icons/Calendar.svg';

const styles = css`
  display: flex;
  align-items: center;
  justify-content: space-between;
  width: 100%;

  .constrainItem {
    display: flex;
    align-items: center;
    gap: 5px;

    .iconWrapper {
      display: flex;
      align-items: center;
      justify-content: center;
      width: 24px;
      height: 24px;
      background-color: ${PURPLE_INACTIVE_LIGHT};
      border-radius: 6px;

      img {
        width: 12px;
        height: 12px;
      }
    }
  }
  ${MEDIA_QUERIES.mobile} {
    flex-direction: column;
    gap: 8.3px;
    align-items: flex-start;
  }
`;

function CampaignConstrains({
  endTime,
  typeIcon,
  typeName,
  startDate,
  startTime,
}) {
  return (
    <div css={styles}>
      <div className="constrainItem">
        <div className="iconWrapper">
          <img src={typeIcon} draggable={false} />
        </div>
        <Text fontSize={12} fontWeight="500" type="inline">
          {typeName}
        </Text>
      </div>
      <div className="constrainItem">
        <div className="iconWrapper">
          <Icon icon={Clock} color={PURPLE_FEEK} size={12} />
        </div>
        <Text fontSize={12} fontWeight="500" type="inline">
          {startTime} a {endTime} hrs.
        </Text>
      </div>
      <div className="constrainItem">
        <div className="iconWrapper">
          <Icon icon={Calendar} color={PURPLE_FEEK} size={12} />
        </div>
        <Text fontSize={12} fontWeight="500" type="inline">
          {moment(startDate).format('DD MMM')}
        </Text>
      </div>
    </div>
  );
}

export default CampaignConstrains;

CampaignConstrains.propTypes = {
  endTime: PropTypes.string,
  typeIcon: PropTypes.string,
  typeName: PropTypes.string,
  startDate: PropTypes.string,
  startTime: PropTypes.string,
};
