/** @jsxImportSource @emotion/react */
import PropTypes from 'prop-types';
import { css } from '@emotion/react';

import Text from 'components/Text';
import Icon from 'components/Icon';
import Button from 'components/buttons/Button';
import { MEDIA_QUERIES } from 'styles/constants';
import { GRADIENT_PURPLE, PURPLE_FEEK, WHITE } from 'styles/colors';

const PrimaryButton = (props) => {
  const {
    icon,
    label,
    fontSize = 18,
    textProps = {},
    disabled = false,
    strokeVariant = false,
    iconPosition = 'left',
    textColor = strokeVariant ? PURPLE_FEEK : WHITE,
    color = strokeVariant ? WHITE : GRADIENT_PURPLE,
    ...restProps
  } = props;

  const styles = css`
    box-sizing: border-box;
    width: 100%;
    height: 60px;
    display: flex;
    gap: 18px;
    padding: 0 10px;
    border-radius: 8px;
    align-items: center;
    justify-content: center;
    flex-direction: ${iconPosition !== 'left'
      ? 'row-reverse'
      : 'row'};

    ${!strokeVariant
      ? `background: ${color};`
      : `background: ${color};
        border: 1px solid ${PURPLE_FEEK};`}

    ${disabled
      ? 'opacity: 0.5;'
      : `
          &:hover {
            opacity: 0.5;
            transition-duration: 0.3ms;
          }

          &:active {
            transform: translateY(1px);
            opacity: 1;
          }
        `};

    ${MEDIA_QUERIES.mobile} {
      height: 40px;
    }
  `;

  return (
    <Button
      css={styles}
      disabled={disabled}
      tabIndex={disabled ? -1 : 0}
      {...restProps}
    >
      {icon && icon}
      {typeof label === 'string' ? (
        <Text
          color={textColor}
          fontWeight="bold"
          fontSize={fontSize}
          {...textProps}
        >
          {label}
        </Text>
      ) : (
        label
      )}
    </Button>
  );
};

PrimaryButton.propTypes = {
  ...Button.propTypes,
  label: PropTypes.oneOfType([PropTypes.string, PropTypes.element]),
  color: PropTypes.string,
  strokeVariant: PropTypes.bool,

  fontSize: function (props, propName, componentName) {
    if (props[propName]) {
      if (typeof props['label'] !== 'string') {
        return new Error(
          `Invalid prop ${propName} supplied to ${componentName}. ${propName} will not be supplied if the label prop is not of type string.`,
        );
      } else {
        PropTypes.checkPropTypes(
          Text.propTypes,
          props,
          propName,
          componentName,
        );
      }
    }
  },
  textColor: function (props, propName, componentName) {
    if (props[propName]) {
      if (typeof props['label'] !== 'string') {
        return new Error(
          `Invalid prop ${propName} supplied to ${componentName}. ${propName} will not be supplied if the label prop is not of type string.`,
        );
      } else {
        PropTypes.checkPropTypes(
          Text.propTypes,
          props,
          propName,
          componentName,
        );
      }
    }
  },
  textProps: function (props, propName, componentName) {
    if (typeof props['label'] !== 'string' && props[propName]) {
      return new Error(
        `Invalid prop ${propName} supplied to ${componentName}. ${propName} will not be supplied if the label prop is not of type string.`,
      );
    }
  },

  icon: PropTypes.shape({
    type: PropTypes.oneOf([Icon]),
  }),
  iconPosition: function (props, propName, componentName) {
    if (props[propName]) {
      if (!props['icon']) {
        return new Error(
          `Invalid prop ${propName} supplied to ${componentName}. ${propName} will not be supplied if the icon prop is missing.`,
        );
      } else {
        if (!['left', 'right'].includes(props[propName])) {
          return new Error(
            `Invalid prop ${propName} supplied to ${componentName} expected one of ['left', 'right'].`,
          );
        }
      }
    }
  },
};

export default PrimaryButton;
