/** @jsxImportSource @emotion/react */
import moment from 'moment';
import PropTypes from 'prop-types';
import { css } from '@emotion/react';
import { useState, useMemo } from 'react';

import {
  GRAY,
  WHITE,
  TRANSPARENT,
  PURPLE_FEEK,
  GRAY_DEFAULT,
  GRAY_LIGHTER,
  GRAY_LIGHTEST,
  GRAY_LIGHTEST_PLUS,
  PURPLE_INACTIVE_LIGHT,
} from 'styles/colors';
import Text from 'components/Text';
import Icon from 'components/Icon';
import DropDown from 'components/DropDown';
import Button from 'components/buttons/Button';
import { MEDIA_QUERIES } from 'styles/constants';
import TextInput from 'components/inputs/TextInput';
import { campaignStatusAbbreviation } from 'constants';
import { ReactComponent as Trash } from 'assets/icons/Trash.svg';
import PaginationButtons from 'components/buttons/PaginationButtons';
import { ReactComponent as PlusCircle } from 'assets/icons/PlusCircle.svg';
import MagnifyingGlassSimple from 'assets/icons/MagnifyingGlassSimple.svg';

const styles = css`
  padding: 31px 47px;

  .goBackButton {
    width: max-content;
    font-size: 14px;
    font-weight: 400;
    color: ${GRAY};
  }

  .searchBar {
    margin-block-start: 40px;

    .searchInput {
      height: 32px;
      width: 284px;
      border: 1px solid ${GRAY_DEFAULT};
      border-radius: 8px;
      padding-inline-start: 34px;
      background: ${GRAY_LIGHTEST} url(${MagnifyingGlassSimple})
        no-repeat scroll 11.4px 9.4px/14px 14px;

      ::placeholder {
        color: ${GRAY_DEFAULT};
      }
    }

    .error-text {
      display: none;
    }
  }

  .listContainer {
    display: grid;
    grid-template-columns:
      minmax(0, 3fr)
      repeat(3, 1fr)
      repeat(2, 0.5fr);
    row-gap: 10px;
    align-items: center;
    overflow-y: auto;
    margin-block: 25px;

    thead,
    tbody,
    tr {
      display: contents;
    }

    tbody tr {
      :nth-of-type(even) td {
        height: 100%;
        background: ${GRAY_LIGHTEST};
        display: flex;
        align-items: center;
      }

      td:first-of-type :is(div, span):last-of-type {
        overflow: hidden;
        text-overflow: ellipsis;
        white-space: nowrap;
      }
    }

    th,
    td {
      padding-block: 12px;
      padding-inline-end: 10px;

      :first-of-type {
        padding-inline-start: 10px;
        border-radius: 0;
      }

      :nth-of-type(5),
      :nth-of-type(6) {
        display: flex;
        align-items: center;
        justify-content: center;

        .deleteButton {
          display: flex;
        }
      }

      :last-of-type {
        padding-inline-start: 10px;
        border-radius: 0 8px 8px 0;
      }

      .status {
        padding: 2px 5px;
        border-radius: 4px;
        overflow: hidden;
        text-overflow: ellipsis;
        white-space: nowrap;
        max-width: fit-content;

        &.completada {
          background-color: ${PURPLE_FEEK};
          color: ${WHITE};
        }

        &.enCurso {
          background-color: ${WHITE};
          color: ${PURPLE_FEEK};
          box-shadow: 0px 0px 8px rgba(0, 0, 0, 0.07);
        }

        &.draft {
          background-color: ${PURPLE_INACTIVE_LIGHT};
          color: ${PURPLE_FEEK};
        }
      }
    }
  }

  ${MEDIA_QUERIES.mobile} {
    padding: 40px 24px;

    .heading {
      margin-block: 30px;
    }

    .searchBar {
      margin-block: 30px;

      .searchInput {
        width: 100%;
      }
    }

    .listContainer {
      display: flex;
      flex-direction: column;
      flex: 0 0 fit-content;
      gap: 0;
      margin-block-start: 24px;

      thead {
        display: none;
      }

      tbody tr {
        display: flex;
        flex-direction: column;
        gap: 12px;
        width: 100%;
        padding: 10px;
        border-radius: 8px;

        :nth-of-type(even) {
          background: ${GRAY_LIGHTEST};
        }

        :nth-of-type(even) td {
          height: auto;
          background: ${TRANSPARENT};
          display: grid;
          align-items: center;
          justify-items: start;
        }

        td:first-of-type :is(div, span):last-of-type {
          overflow: initial;
          text-overflow: initial;
          white-space: initial;
        }

        td {
          display: grid;
          grid-template-columns: minmax(0, 1fr) minmax(0, 3.25fr);
          column-gap: 10px;
          align-items: center;
          padding: 0;

          :first-of-type {
            padding-inline: 0px;
            border-radius: 0;
          }

          :nth-of-type(5),
          :nth-of-type(6) {
            display: grid;
            align-items: center;
            justify-content: initial;
          }

          :last-of-type {
            padding-inline: 0px;
            border-radius: 0;
          }
        }
      }
    }
  }
`;

const stylesDropDown = css`
  .content {
    width: max-content;
    background-color: ${GRAY_LIGHTEST_PLUS};
    border: 1px solid ${GRAY_DEFAULT};
    box-shadow: none;
    padding: 5px 0;

    .button {
      padding: 5px 15px;

      &:hover {
        background-color: ${GRAY_LIGHTER};
      }
    }
  }
`;

function CampaignRow({
  handleCloneAndEditCampaign,
  handleDeleteCampaign,
  title,
  status,
  type,
  id,
  createdAt,
  momentsCount,
}) {
  const [isDropdownOpen, setIsDropdownOpen] = useState(false);
  return (
    <tr>
      <td className="nameContainer">
        <Text
          className="showOnMobileOnly"
          fontSize={12}
          fontWeight={700}
        >
          Nombre de campaña
        </Text>
        <Text title={title} fontSize={14} fontWeight={500}>
          {title}
        </Text>
      </td>

      <td>
        <Text
          className="showOnMobileOnly"
          fontSize={12}
          fontWeight={700}
        >
          Estado
        </Text>
        <Text
          fontSize={12}
          fontWeight={500}
          type="inline"
          className={
            'status ' +
            status
              .toLowerCase()
              .replace(/\s(\w)/, (match, p1) => p1.toUpperCase())
          }
        >
          {status.charAt(0).toUpperCase() + status.slice(1)}
        </Text>
      </td>

      <td>
        <Text
          className="showOnMobileOnly"
          fontSize={12}
          fontWeight={700}
        >
          Tipo
        </Text>
        <Text fontSize={14} fontWeight={400}>
          {campaignStatusAbbreviation[type]}
        </Text>
      </td>

      <td>
        <Text
          className="showOnMobileOnly"
          fontSize={12}
          fontWeight={700}
        >
          Fecha de creación
        </Text>

        <Text fontSize={14} fontWeight={600}>
          {moment(createdAt)
            .format('DD · MMM · YY')
            .replace(/(?<=\w{3})\./, '')}
        </Text>
      </td>

      <td className="hideOnMobile">
        <DropDown
          icon={
            <Icon color={PURPLE_FEEK} icon={PlusCircle} size={20} />
          }
          isOpen={isDropdownOpen}
          onOpen={() => setIsDropdownOpen(true)}
          onClose={() => setIsDropdownOpen(false)}
          childrenWrapperCSS={stylesDropDown}
        >
          <Button
            onClick={() => handleCloneAndEditCampaign(id, 3)}
            className="button"
          >
            <Text fontSize={12} lineHeight={16.5}>
              Clonar campaña
            </Text>
          </Button>
          <Button
            onClick={() => handleCloneAndEditCampaign(id)}
            className="button"
          >
            <Text fontSize={12} lineHeight={16.5}>
              Clonar y editar campaña
            </Text>
          </Button>
        </DropDown>
      </td>

      <td>
        <Text
          className="showOnMobileOnly"
          fontSize={12}
          fontWeight={700}
        >
          Borrar
        </Text>

        <Button
          disabled={momentsCount > 0 && status === 'En curso'}
          onClick={() => handleDeleteCampaign(id)}
          className="deleteButton"
        >
          <Icon icon={Trash} color={PURPLE_FEEK} size={20} />
        </Button>
      </td>
    </tr>
  );
}

function CampaignsHistory({
  setShowHistoryOfCampaigns,
  handleCloneAndEditCampaign,
  handleDeleteCampaign,
  campaigns,
}) {
  const itemsCount = 10;
  const [query, setQuery] = useState('');
  const [currentPage, setCurrentPage] = useState(0);

  const filteredCampaigns = useMemo(
    () =>
      campaigns?.length
        ? (query
            ? campaigns.filter(({ title }) =>
                RegExp(
                  query.replaceAll('?', '.').replaceAll('*', '.+?'),
                ).test(title),
              )
            : campaigns
          ).reduce((campaignsArray, crr, index) => {
            if (Number.isInteger(index / itemsCount))
              campaignsArray.push([crr]);
            else
              campaignsArray[Math.floor(index / itemsCount)].push(
                crr,
              );
            return campaignsArray;
          }, [])
        : [],
    [campaigns, query],
  );

  const goBack = () => {
    setShowHistoryOfCampaigns(false);
  };

  return (
    <div css={[styles]}>
      <Button className="goBackButton" onClick={goBack}>
        ← Regresar a Campañas
      </Button>

      <Text
        className="heading showOnMobileOnly"
        fontSize={20}
        lineHeight={24}
      >
        Historial de Campañas
      </Text>

      <TextInput
        type="search"
        value={query}
        className="searchBar"
        inputClassName="searchInput"
        placeholder="Buscar campaña"
        onChange={({ target: { value } }) => setQuery(value)}
      />

      <table className="listContainer">
        <thead>
          <tr>
            <th>
              <Text fontSize={12} fontWeight={700}>
                Nombre de campaña
              </Text>
            </th>
            <th>
              <Text fontSize={12} fontWeight={700}>
                Estatus
              </Text>
            </th>
            <th>
              <Text fontSize={12} fontWeight={700}>
                Tipo
              </Text>
            </th>
            <th>
              <Text fontSize={12} fontWeight={700}>
                Fecha de creación
              </Text>
            </th>
            <th>
              <Text fontSize={12} fontWeight={700}>
                Clonar
              </Text>
            </th>
            <th>
              <Text fontSize={12} fontWeight={700}>
                Borrar
              </Text>
            </th>
          </tr>
        </thead>
        <tbody>
          {filteredCampaigns?.[currentPage]?.map(
            ({
              title,
              status,
              type: { type },
              created_at,
              _id,
              moments,
            }) => (
              <CampaignRow
                key={_id}
                title={title}
                status={status}
                type={type}
                createdAt={created_at}
                momentsCount={moments.length}
                id={_id}
                handleCloneAndEditCampaign={
                  handleCloneAndEditCampaign
                }
                handleDeleteCampaign={handleDeleteCampaign}
              />
            ),
          )}
        </tbody>
      </table>

      <PaginationButtons
        currentPage={currentPage + 1}
        backPageDisabled={currentPage === 0}
        nextPageDisabled={
          currentPage === filteredCampaigns.length - 1
        }
        onBackPage={() => setCurrentPage((crr) => crr - 1)}
        onNextPage={() => setCurrentPage((crr) => crr + 1)}
      />
    </div>
  );
}

CampaignRow.propTypes = {
  title: PropTypes.string,
  status: PropTypes.string,
  type: PropTypes.string,
  createdAt: PropTypes.string,
  momentsCount: PropTypes.number,
  id: PropTypes.string,
  handleCloneAndEditCampaign: PropTypes.func,
  handleDeleteCampaign: PropTypes.func,
};

CampaignsHistory.propTypes = {
  setShowHistoryOfCampaigns: PropTypes.func,
  handleCloneAndEditCampaign: PropTypes.func,
  handleDeleteCampaign: PropTypes.func,
  campaigns: PropTypes.arrayOf(PropTypes.object),
};

export default CampaignsHistory;
