import PropTypes from 'prop-types';
import { useGoogleLogin } from '@react-oauth/google';

import Icon from 'components/Icon';
import { GRAY, GRAY_LIGHTER } from 'styles/colors';
import PrimaryButton from 'components/buttons/PrimaryButton';
import { ReactComponent as Google } from 'assets/icons/Google.svg';

function GoogleButton({ onSuccess, onFailure, text }) {
  const login = useGoogleLogin({
    onSuccess: onSuccess,
    onError: onFailure,
  });

  return (
    <PrimaryButton
      onClick={login}
      label={text}
      fontSize={18}
      textColor={GRAY}
      color={GRAY_LIGHTER}
      icon={<Icon icon={Google} color={null} size={32} />}
    />
  );
}

GoogleButton.propTypes = {
  onSuccess: PropTypes.func,
  onFailure: PropTypes.func,
  text: PropTypes.string,
};

export default GoogleButton;
