/** @jsxImportSource @emotion/react */
import { useRef } from 'react';
import PropTypes from 'prop-types';
import { css } from '@emotion/react';

import Text from 'components/Text';
import { GRAY_LIGHT } from 'styles/colors';
import DropDown from 'components/DropDown';
import Button from 'components/buttons/Button';

const DropDownLabelAndIcon = (props) => {
  const {
    className,
    childrenWrapperCSS,
    children,
    label,
    rightIcon,
    leftIcon,
    isOpen,
    onOpen,
    onClose,
    position = 'bottom',
    alignment = 'right',
  } = props;

  const styles = css`
    box-sizing: border-box;
    display: flex;
    justify-content: space-between;
    align-items: center;
    min-width: fit-content;
    height: 44px;
    padding-left: 10px;
    border-radius: 8px;
    border: 1px solid ${GRAY_LIGHT};

    .wrapper {
      display: flex;
      align-items: center;
      gap: 18px;
      flex: 1;
    }

    .dropDownBtn {
      display: flex;
      justify-content: center;
      align-items: center;
      height: 100%;
      width: 44px;

      .btn {
        height: 100%;
        width: 100%;
      }
    }
  `;

  const ref = useRef(null);

  const handleDropDown = (e) => {
    e.stopPropagation();
    ref.current.click();
  };

  return (
    <div css={styles} className={className}>
      <Button className="buttonWrapper" onClick={handleDropDown}>
        <div className="wrapper">
          {leftIcon && leftIcon}
          {label && (
            <Text className="label" fontSize={16}>
              {label}
            </Text>
          )}
        </div>
      </Button>

      <DropDown
        ref={ref}
        className="dropDownBtn"
        position={position}
        alignment={alignment}
        isOpen={isOpen}
        onClose={onClose}
        onOpen={onOpen}
        icon={rightIcon}
        childrenWrapperCSS={childrenWrapperCSS}
      >
        {children}
      </DropDown>
    </div>
  );
};

DropDownLabelAndIcon.propTypes = {
  className: PropTypes.string,
  childrenWrapperCSS: PropTypes.object,
  children: PropTypes.node.isRequired,
  position: PropTypes.oneOf([
    'bottom',
    'top',
    'left',
    'right',
    'left-top',
    'right-top',
    'left-bottom',
    'right-bottom',
    'over',
  ]),
  /* USE OF POSITION 

    left-top       top       right-top
            |----------------|
            |                |
            |                |
            |    DROPDOWN    |
        left|      over      |right
            |                |
            |                |
            |----------------|
  left-bottom     bottom     right-bottom

  */

  alignment: PropTypes.oneOf([
    'center',
    'left',
    'right',
    'top',
    'bottom',
  ]),
  /* USE OF ALIGNMENT

                        TOP
                         |
              ___________|____________
              left     center    right
      |    top|----------------------|top     |
      |       |                      |        |
      |       |                      |        |
LEFT  | center|       dropdown       |center  |  RIGHT
      |       |         OVER         |        |
      |       |                      |        |
      | bottom|----------------------|bottom  |
              left     center    right
              _______________________
                         |
                         |
                       BOTTOM

  */

  rightIcon: PropTypes.element,
  leftIcon: PropTypes.element,
  label: PropTypes.string,
  isOpen: PropTypes.bool,
  onOpen: PropTypes.func,
  onClose: PropTypes.func,
};

export default DropDownLabelAndIcon;
