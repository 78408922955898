/** @jsxImportSource @emotion/react */
/** @jsxImportSource @emotion/react */
import { css } from '@emotion/react';
import { Link, useNavigate } from 'react-router-dom';

import { ROUTES } from 'constants';
import Icon from 'components/Icon';
import Text from 'components/Text';
import { WHITE } from 'styles/colors';
import Button from 'components/buttons/Button';
import AbstractWingBg from 'assets/images/AbstractWingBg.png';
import { ReactComponent as FeekLogo } from 'assets/icons/FeekLogo.svg';
import { ReactComponent as FeekLogoComplete } from 'assets/icons/FeekLogoComplete.svg';

export const style = css`
  display: flex;
  height: 100vh;
  width: 100%;

  a {
    text-decoration: none;
  }

  th {
    padding-right: 20px;
    vertical-align: top;
  }

  .header {
    box-sizing: border-box;
    display: flex;
    flex-direction: column;
    justify-content: space-between;
    background: url(${AbstractWingBg}) no-repeat center;
    background-size: cover;
    padding: 50px;
    border-top-right-radius: 20px;
    border-bottom-right-radius: 20px;
    flex: 1;

    svg:last-of-type {
      width: 59px;
      height: 59px;
    }
  }

  .info {
    box-sizing: border-box;
    width: 60%;
    height: 100%;
    padding: 40px;
    overflow: auto;
    scroll-behavior: smooth;

    div {
      margin: 20px 0;
    }

    .title {
      display: none;
    }
  }

  @media screen and (max-width: 769px) {
    flex-direction: column;
    height: unset;

    .header {
      border-top-right-radius: 0px;
      border-bottom-right-radius: 0px;

      svg:last-of-type {
        width: 25px;
        height: 25px;
      }

      div:last-of-type {
        display: none;
      }
    }

    .info {
      width: 100%;
      .title {
        display: initial;
      }
    }
  }
`;

function TermsAndConditions() {
  const navigate = useNavigate();

  return (
    <div css={style}>
      <div className="header">
        <Button onClick={() => navigate(ROUTES.HOME)}>
          <Icon icon={FeekLogo} color={WHITE} />
        </Button>
        <div>
          <Icon icon={FeekLogoComplete} color={WHITE} size={137} />
          <Text fontSize={60} color={WHITE} fontWeight="bold">
            Términos y Condiciones
          </Text>
        </div>
      </div>
      <div className="info">
        <Text fontSize={24} className="title">
          Términos y Condiciones
        </Text>
        <Text fontSize={20} lineHeight={24}>
          Fecha efectiva: 15 de junio de 2023
        </Text>
        <Text fontSize={20} lineHeight={24}>
          Última actualización: 31 de mayo de 2023
        </Text>
        <br />
        <br />
        <Text fontSize={20} lineHeight={24} fontWeight="bold">
          ¡Bienvenido!
        </Text>
        <Text fontSize={20} lineHeight={24}>
          Hemos redactado estos Términos y Condiciones de Uso y
          Servicio (que llamamos los ”Términos”) para que conozca las
          normas que rigen nuestra relación con usted como usuario de
          nuestros Servicios. Aunque hemos hecho todo lo posible para
          eliminar el vocabulario legal de los Términos, hay lugares
          en los que todavía pueden leerse como un contrato
          tradicional. Hay una buena razón para ello: estos Términos
          forman un contrato legalmente vinculante entre usted y
          Frengers, Inc. (”Feek”), una corporación de Delaware
          legalmente constituida bajo las leyes comerciales de los
          Estados Unidos de América, lo que se evidencia en su
          Certificado de Incorporación con Número de Archivo de la
          Compañía 5645246 y Número de Autenticación 202818604,
          emitido por el Secretario de Estado del estado de Delaware,
          Estados Unidos de América, el 24 de marzo de 2021, con
          Número de Identificación de Empleador (EIN): 35-2709014 y
          lugar de trabajo en 651 N Broad Street, Suite 206,
          Middletown, DE 19709. Por favor, léalos con atención.
          <br />
          <br />
          Para utilizar cualquiera de nuestros otros productos o
          servicios que están sujetos a estos Términos (a los que nos
          referimos colectivamente como los ”Servicios”), debe aceptar
          estos Términos, que se le presentan (i) cuando abre por
          primera vez la aplicación móvil ”Feek App” y/o{' '}
          <a
            rel="noreferrer"
            target="_blank"
            href="https://www.feekmanager.com"
          >
            https://feekmanager.com
          </a>{' '}
          y (ii) cuando realizamos cualquier cambio material en estos
          Términos. Por supuesto, si no los acepta, no utilice los
          Servicios. Estos Términos sustituyen expresamente cualquier
          acuerdo o convenio anterior entre las partes. Feek puede
          rescindir inmediatamente estos Términos y Condiciones o
          cualquiera de los Servicios con respecto a usted o, en
          general, dejar de ofrecer o denegar el acceso a los
          Servicios o a cualquier parte de los mismos, en cualquier
          momento y por cualquier motivo de forma unilateral.
          <br />
          <br />
          Pueden aplicarse términos y condiciones suplementarios a
          ciertos Servicios, como políticas para un evento, actividad
          o promoción en particular, y dichos términos y condiciones
          suplementarios le serán comunicados en relación con los
          Servicios aplicables. Los términos y condiciones
          suplementarios se establecen además de, y se considerarán
          parte de, los Términos a efectos de los Servicios
          aplicables. Los términos y condiciones suplementarios
          prevalecerán sobre los Términos en caso de cualquier
          conflicto relacionado con los Servicios aplicables.
          <br />
          <br />
          Feek puede modificar los Términos relacionados con los
          Servicios de vez en cuando. Las modificaciones entrarán en
          vigor una vez que Feek publique los Términos actualizados en
          esta ubicación o las políticas modificadas o los términos y
          condiciones suplementarios en el Servicio aplicable. Su
          acceso o uso continuado de los Servicios después de dicha
          publicación constituye su consentimiento para quedar
          obligado por los Términos y cualquier modificación de los
          mismos.
        </Text>
        <Text
          fontSize={20}
          lineHeight={24}
          fontWeight="bold"
          align="center"
        >
          1. CONDICIONES DE USO
        </Text>
        <a href="#terms-of-use-1.1">
          <Text fontSize={20} lineHeight={24} fontWeight="bold">
            1.1. Plataformas tecnológicas que permiten el uso de los
            Servicios
          </Text>
        </a>
        <a href="#terms-of-use-1.2">
          <Text fontSize={20} lineHeight={24} fontWeight="bold">
            1.2. Quién puede utilizar los Servicios
          </Text>
        </a>
        <a href="#terms-of-use-1.3">
          <Text fontSize={20} lineHeight={24} fontWeight="bold">
            1.3. Licencia
          </Text>
        </a>
        <a href="#terms-of-use-1.4">
          <Text fontSize={20} lineHeight={24} fontWeight="bold">
            1.4. Su cuenta
          </Text>
        </a>
        <a href="#terms-of-use-1.5">
          <Text fontSize={20} lineHeight={24} fontWeight="bold">
            1.5. Uso de la aplicación móvil ”Feek App”
          </Text>
        </a>
        <a href="#terms-of-use-1.6">
          <Text fontSize={20} lineHeight={24} fontWeight="bold">
            1.6. Uso del sitio web de Feekmanager
          </Text>
        </a>
        <a href="#terms-of-use-1.7">
          <Text fontSize={20} lineHeight={24} fontWeight="bold">
            1.7. Seguridad
          </Text>
        </a>
        <a href="#terms-of-use-1.8">
          <Text fontSize={20} lineHeight={24} fontWeight="bold">
            1.8. Tarifas de datos y teléfonos móviles
          </Text>
        </a>
        <br />
        <Text
          fontSize={20}
          lineHeight={24}
          fontWeight="bold"
          id="terms-of-use-1.1"
        >
          1.1. Plataformas tecnológicas que permiten el uso de los
          Servicios
        </Text>
        <Text fontSize={20} lineHeight={24}>
          Feek presta sus Servicios a través de dos plataformas
          diferentes: (i) la aplicación móvil ”Feek App”, que puede
          descargarse gratuitamente en los dispositivos móviles a
          través de las tiendas virtuales de aplicaciones ”App Store”
          de Apple y ”Google Play” de Google, aplicación que permite
          su uso de forma gratuita, y (ii) la página web{' '}
          <a
            rel="noreferrer"
            target="_blank"
            href="https://www.feekmanager.com"
          >
            https://feekmanager.com
          </a>{' '}
          que restringe su acceso a los usuarios con un plan activo y
          pagado para el uso específico de este servicio.
        </Text>
        <Text
          fontSize={20}
          lineHeight={24}
          fontWeight="bold"
          id="terms-of-use-1.2"
        >
          1.2. Quién puede utilizar los Servicios
        </Text>
        <Text fontSize={20} lineHeight={24}>
          Los Servicios prestados por Feek a través de la aplicación
          móvil ”Feek app” pueden ser utilizados por cualquier persona
          que no sea menor de 13 años, (o, si es mayor de 13 años, la
          edad mínima a la que una persona puede utilizar los
          Servicios en su país) a las que se les permitirá crear una
          cuenta (estos usuarios serán denominados ”Feeker” o
          ”Feekers”).
          <br />
          <br />
          Si tiene menos de 18 años (o la mayoría de edad legal en su
          país), sólo podrá utilizar los Servicios con el
          consentimiento previo de sus padres o su tutor. Asegúrese de
          que sus padres o su tutor hayan revisado y discutido estos
          Términos con usted antes de empezar a utilizar los
          Servicios. Es posible que ofrezcamos Servicios adicionales
          con términos adicionales que pueden requerir que usted sea
          aún mayor para utilizarlos. Por lo tanto, lea atentamente
          todos los Términos. Al utilizar los Servicios, usted declara
          y garantiza que:
          <ul>
            <li>puede formar un contrato vinculante con Feek;</li>
            <li>
              no es una persona que tenga prohibido el uso de los
              Servicios bajo las leyes de México, o cualquier otra
              jurisdicción aplicable, y
            </li>
            <li>
              cumplirá con estos Términos y con todas las leyes,
              normas y reglamentos locales, estatales, nacionales e
              internacionales aplicables.
            </li>
          </ul>
          Los servicios proporcionados por Feek a través del sitio web{' '}
          <a
            rel="noreferrer"
            target="_blank"
            href="https://www.feekmanager.com"
          >
            https://feekmanager.com
          </a>{' '}
          serán utilizados por individuos, empresas o alguna otra
          entidad (estos usuarios serán denominados ”Feekspot” o
          ”Feekspots”).
          <br />
          <br />
          Si usted utiliza los Servicios en nombre de una empresa o
          alguna otra entidad, usted declara que está autorizado a
          vincular a dicha empresa o entidad a estos Términos y acepta
          estos Términos en nombre de dicha empresa o entidad (y todas
          las referencias a ”usted” y ”su” en estos Términos
          significarán tanto usted como usuario final como dicha
          empresa o entidad).
          <br />
          <br />
          Los ”Feekers” y los ”Feekspots” (denominados colectivamente
          como ”usuarios” a lo largo de este documento) estarán
          sujetos a todas las disposiciones establecidas en estos
          Términos, a excepción de las disposiciones de la Sección
          1.5. Uso de la aplicación móvil ”Feek App”, que sólo se
          aplicará a los Feekers, y las disposiciones de la Sección
          1.6. Uso del sitio web de Feekmanager, que sólo se aplicará
          a los Feekspots.
        </Text>
        <Text
          fontSize={20}
          lineHeight={24}
          fontWeight="bold"
          id="terms-of-use-1.3"
        >
          1.3. Licencia
        </Text>
        <Text fontSize={20} lineHeight={24}>
          Sujeto al cumplimiento de estos Términos y Condiciones, Feek
          concede a los usuarios una autorización limitada, no
          exclusiva, revocable e intransferible para: (i) acceder y
          utilizar la aplicación móvil ”Feek App” o{' '}
          <a
            rel="noreferrer"
            target="_blank"
            href="https://www.feekmanager.com"
          >
            https://feekmanager.com
          </a>{' '}
          en el dispositivo personal de los usuarios únicamente en
          relación con su uso de los Servicios; y (ii) acceder y
          utilizar cualquier contenido, información y materiales
          relacionados que puedan estar disponibles a través de los
          Servicios, en cada caso únicamente para su uso personal y no
          comercial. Feek y sus licenciantes se reservan cualquier
          derecho no concedido expresamente por estos Términos y
          Condiciones.
        </Text>
        <Text
          fontSize={20}
          lineHeight={24}
          fontWeight="bold"
          id="terms-of-use-1.4"
        >
          1.4. Su cuenta
        </Text>
        <Text fontSize={20} lineHeight={24}>
          Como Feeker necesitará crear una cuenta para poder utilizar
          nuestros Servicios. Usted se compromete a proporcionarnos
          información precisa, veraz, completa y actualizada de su
          nombre de usuario, correo y/o número móvil y la
          autenticación de este nombre de usuario mediante alguna red
          social de las habilitadas en la “Feek App”.
          <br />
          <br />
          Como Feekspot, usted necesita crear una cuenta y tener un
          plan válido, vigente y comprado, previamente elegido por
          usted de acuerdo con las opciones de servicio proporcionadas
          por Feek, para otorgarle acceso al sitio web{' '}
          <a
            rel="noreferrer"
            target="_blank"
            href="https://www.feekmanager.com"
          >
            https://feekmanager.com
          </a>
          . Usted se compromete a proporcionarnos información precisa,
          completa y actualizada de su nombre o nombre de la entidad,
          su línea de negocio y su cuenta de correo electrónico.
          <br />
          <br />
          En ningún momento Feek solicitará información de los
          Feekspots que <b>NO</b> sea necesaria para otorgar el acceso
          a los Servicios y para la facilitación del pago, por lo que
          los datos de la tarjeta de débito o crédito sólo serán
          solicitados al momento de realizar el pago virtual de uno de
          nuestros planes. Estos datos no serán almacenados por Feek,
          se registrarán directamente en la Pasarela de Pago
          correspondiente y serán utilizados directamente por el
          Feekspot en la misma, donde se informará de las condiciones
          de seguridad y privacidad en las que se realiza el pago.
          <br />
          <br />
          Una vez facilitada la información necesaria para la
          utilización de los Servicios, Feek validará el nombre de
          usuario y la contraseña para acceder y/o utilizar los
          Servicios, tanto si es un Feeker como un Feekspot.
          <br />
          <br />
          Los usuarios pueden, además de la información obligatoria y
          opcional requerida en el momento de la creación de la
          cuenta, proporcionar voluntariamente otros datos personales
          relacionados con su individualización en el momento de crear
          su propio perfil dentro del uso de los Servicios.
          <br />
          <br />
          El uso de la cuenta de los usuarios es personal e
          intransferible, por lo que no tienen derecho a asignar datos
          de validación para el acceso a los Servicios. En caso de
          olvido o mal uso de los datos de validación, los usuarios
          están obligados a informar a Feek a través de la opción
          ”Olvidé mi contraseña” o a través de una comunicación
          enviada a la dirección de correo electrónico:{' '}
          <a href="mailto:support@feek.app">support@feek.app</a>
          <br />
          <br />
          Las cuentas de los usuarios serán administradas por Feek a
          su criterio o por la persona que ésta designe, teniendo
          plenos poderes para mantener o no la cuenta, cuando la
          información proporcionada por los usuarios no sea veraz,
          completa o segura; o cuando exista incumplimiento de las
          obligaciones de los usuarios en virtud de los presente
          Términos.
          <br />
          <br />
          Los usuarios son responsables de cualquier actividad que se
          produzca en su cuenta. Es importante que mantenga su cuenta
          segura. Una forma de hacerlo es seleccionar una contraseña
          fuerte que no utilice para ninguna otra cuenta. Si cree que
          alguien ha accedido a su cuenta, póngase en contacto
          inmediatamente con el servicio de asistencia enviando un
          correo electrónico a:{' '}
          <a href="mailto:privacy@feek.app">privacy@feek.app</a>.
          Cualquier software que le proporcionemos puede descargar e
          instalar automáticamente mejoras, actualizaciones u otras
          nuevas funciones. Es posible que pueda ajustar estas
          descargas automáticas a través de la configuración de su
          dispositivo. Usted se compromete a no crear ninguna cuenta
          si previamente le hemos eliminado o prohibido el acceso a su
          cuenta de cualquiera de nuestros Servicios, a menos que
          consintamos lo contrario.
          <br />
          <br />
          Al crear una cuenta, los usuarios aceptan expresa e
          inequívocamente estos Términos y Condiciones de uso de los
          Servicios.
          <br />
          <br />
          Al aceptar estos Términos y Condiciones, los usuarios
          autorizan expresamente el uso de Cookies por parte de Feek
          en todas las actividades realizadas utilizando los
          Servicios.
        </Text>
        <Text
          fontSize={20}
          lineHeight={24}
          fontWeight="bold"
          id="terms-of-use-1.5"
        >
          1.5. Uso de la aplicación móvil ”Feek App”
        </Text>
        <Text fontSize={20} lineHeight={24}>
          Esta sección aplica única y exclusivamente a los Feekers.
          <br />
          <br />
          Feek proporciona y opera la aplicación móvil ”Feek App”, que
          está destinada a su uso por cada individuo que pueda
          utilizar nuestros servicios de acuerdo con estos términos
          (véase la{' '}
          <b>sección 1.2 Quién puede utilizar nuestros servicios</b>)
          y complete con éxito nuestro proceso de inicio de sesión. El
          servicio de la aplicación móvil ”Feek App” puede descargarse
          gratuitamente en los dispositivos móviles a través de las
          tiendas virtuales de aplicaciones de Apple (”App Store”) y
          Google (”Google Play”), y su uso es gratuito, los Feekers
          podrán acceder a los servicios de la aplicación móvil ”Feek
          App” realizando los siguientes pasos:
          <br />
          <ol>
            <li>
              <b>Crear una cuenta Feeker (nuevos usuarios)</b>: Una
              vez descargada la ”Feek App”, ábrala en su dispositivo
              móvil, lea nuestra bienvenida y pulse ”Continuar”.
              Posteriormente, deberá introducir el código de acceso
              que uno de nuestros colaboradores o usuarios ha
              compartido con usted.
            </li>

            <li>
              <b>Inicia sesión (LogIn)</b>: Después, se le requerirá
              proveer un número de telefonía móvil para hacerle llegar
              un código numérico para poder procesar su solicitud y
              autenticar su registro.
            </li>
            <li>
              <b>Solicitud recibida</b>: Nuestro equipo ha recibido su
              solicitud de ingreso como Feeker e inmediatamente podrá
              acceder completamente a nuestros Servicios.
            </li>
          </ol>
          <br />
          <strong>Dentro de la aplicación Feek</strong>
          <br />
          Una vez que se le ha concedido el acceso a la ”Feek App”,
          verá diferentes botones en la parte inferior de la
          aplicación. Estos botones son secciones en las que se divide
          nuestra app según lo siguiente:
          <ul>
            <li>Home</li>
            <li>Buscar</li>
            <li>Agenda</li>
            <li>Perfil</li>
          </ul>
          <strong>Sección Home.</strong>
          <br />
          Vaya a nuestra Página de Inicio para encontrar y reservar
          las mejores promociones en su zona. Estas promociones son
          campañas lanzadas por Feekspots donde puede adquirir todo
          tipo de productos y servicios (los ”Feekmoment” o
          ”Feekmoments”) mismas que se despliegan al hacer clic en el
          botón “Promociones”.
          <br />
          <br />
          Los Feekmoments están organizados en la página de inicio de
          la ”Feek App” para ayudarle a encontrar una promoción que le
          gustaría reservar y utilizar. Las secciones en las que se
          distribuyen los Feekmoments son:
          <ul>
            <li>
              Nuevas: Aquí encontrará los Feekmoments más recientes
              publicados por los Feekspots.
            </li>
            <li>
              Más cerca: Aquí encontrará los Feekspots más cercanos a
              usted dependiendo de su geolocalización.
            </li>
            <li>
              Últimos lugares: Aquí encontrará los Feekmoments que
              están próximos a terminarse y que aún puede reservar
              antes de que finalicen las promociones y ofertas. ¡Dese
              prisa!
            </li>
          </ul>
          Para más información sobre cómo reservar una de las
          promociones ofrecidas por los Feekspots, vea la sección
          1.5.1. de estos términos y condiciones.
          <br />
          <br />
          Nuestro botón “Mapa” le permite a usted ubicar los Feekspots
          más cercanos en un mapa digital en dónde también podrá
          consultar las promociones ofrecidas por cada Feekspot.
          <br />
          <br />
          Podrá también ver y/o buscar los Feekspots al hacer clic en
          alguna de las opciones ofrecidas en la sección de “Explorar
          por categoría”, mismas que desplegarán y/o filtrarán los
          Feekspots dependiendo la categoría seleccionada.
          <br />
          <br />
          En la sección “Feekers Activos” podrá visualizar el
          contenido que nuestros Feekers han compartido al estar
          completando los Feekmoments y asistiendo a sus Feekspots
          favoritos.
          <br />
          <br />
          La sección “Feekspots más activos” muestra las promociones y
          el establecimiento que las ofrece más demandados en tiempo
          real.
          <br />
          <br />
          Por otro lado, también encontrará en nuestra página de
          inicio el botón “Recompensas”. Esta sección muestra nuestro
          programa de recompensas para nuestros usuarios. Las
          recompensas son regalos otorgados por Feek, gratuitos, y que
          contienen cada una de ellas ciertas bases para poder ser el
          ganador (en adelante la/s “Feekexperience/s”).
          <br />
          <br />
          Para más información sobre las Feekexperiences consulte la
          sección 1.5.2. de estos términos y condiciones
          <br />
          <br />
          <strong>Sección Buscar.</strong>
          <br />
          En esta sección podrás utilizar nuestro buscador para
          encontrar tus Feekspots favoritos y los Feekmoments
          ofrecidos por cada uno de ellos, así como utilizar nuestros
          filtros y otras opciones para hacer tu búsqueda más
          sencilla.
          <br />
          <br />
          <strong>Sección Agenda.</strong>
          <br />
          En esta sección encontrará un resumen histórico de sus
          Feekmoments clasificados de la siguiente manera:
          <ul>
            <li>
              Activas: Aquí encontrará los Feekmoments actuales y
              pendientes para su asistencia y validación.
            </li>
            <li>
              Completadas: En esta sección podrá ver todos los
              Feekmoments a los que ha asistido y validado
              anteriormente.
            </li>
            <li>
              Canceladas: En esta sección se muestran los Feekmoments
              que ha cancelado previamente.
            </li>
          </ul>
          <br />
          <strong>Sección Perfil.</strong>
          <br />
          Esta sección tiene como propósito mostrarle sus Feekpoints,
          sus recompensas (Feekexperiences), y a acceder a la
          configuración de su cuenta.
          <br />
          <br />
          Asimismo, encontrará información como sus Feekspots
          favoritos, sus strikes, sus “me gusta” y opciones para
          invitar y referir amigos a que descarguen y utilicen la
          “Feek App”.
          <br />
          <br />
          <strong>1.5.1. ¿Cómo reservar un Feekmoment?</strong>
          <br />
          Para reservar uno de los Feekmoments que aparecen en la
          página de inicio, tendrás que seguir estos pasos:
          <ol>
            <li>
              Haga clic en el Feekmoment al que le gustaría asistir.
            </li>
            <li>
              Mire los requisitos, la descripción y las sugerencias
              del Feekmoment.
            </li>
            <li>
              Haga clic en el botón Continuar y luego confirme su
              reservación en el botón Aceptar.
            </li>
          </ol>
          <strong>¿Cómo validar su Feekmoment reservado?</strong>
          <br />
          Para validar un Feekmoment, diríjase a la sección “Activas”
          en el apartado de Agenda, y siga estos pasos:
          <ol>
            <li>
              <b>Asista a su Feekmoment reservado</b>. El primer paso
              para validar un Feekmoment es asistir al Feekspot y
              mencionar que tiene un Feekmoment reservado allí al
              personal de los Feekspots.
            </li>
            <li>
              <b>Cree su contenido</b>. Una vez que asista al
              Feekspot, utilice su aplicación móvil de Instagram para
              capturar su experiencia en el Feekspot y publíquela en
              su historia de Instagram (NOTA: no aplicable a perfiles
              privados de Instagram). Sea casual y natural con el
              contenido que publique. No olvide que es imprescindible
              que mencione en su historia de Instagram la cuenta de
              Instagram de los Feekspots que visite. Por otra parte,
              si su perfil de Instagram es privado, la “Feek App” le
              permitirá subir su contenido directamente a nuestra
              plataforma de conformidad con lo mencionado en el punto
              inmediato siguiente.
            </li>
            <li>
              <b>Valida el Feekmoment</b>. Vuelva a la sección de
              Agenda y seleccione el Feekmoment al que está asistiendo
              o al que acaba de asistir. Vaya a la sección “Historia
              principal“ y haga clic en “Validar“ para elegir la
              historia que acaba de publicar en su cuenta de
              Instagram. En caso de tener su perfil privado, al dar
              clic en “Validar” se abrirá la cámara de su celular para
              que pueda tomar una foto y crear su contenido,
              exclusivamente del Feekspot que está visitando, mismo
              que se subirá automáticamente a la aplicación.{' '}
              <u>
                <b>Aviso (solo aplicable para perfiles públicos)</b>:
                Es imprescindible dejar que la historia cumpla su
                curso determinado temporal de acuerdo con Instagram,
                que es de 24 (veinticuatro) horas, bajo pena de que si
                se llegara a eliminar la historia antes de esas 24
                (veinticuatro) horas, Feek estará en su derecho de
                prohibirle el acceso a la “Feek App” de forma temporal
                e incluso permanente.
              </u>
            </li>
            <li>
              <b>Validación y recepción de Feekpoints</b>. Una vez
              elegida la historia que ha publicado en su cuenta de
              Instagram o que haya subido a la “Feek App”, la opción
              de validación mostrará una marca de verificación y Feek
              le proporcionará una cantidad determinada de Feekpoints.
            </li>
            <li>
              <b>Feekpoints extra</b>. Puede validar otra historia
              siguiendo las instrucciones que le proporcione cada
              Feekspot en el Feekmoment para ganar Feekpoints extra en
              el apartado “Historias extra“.
            </li>
            <li>
              <b>Complete su Feekmoment</b>. Una vez que haya subido
              sus historias y haya validado el Feekmoment completando
              las secciones “Historia principal“ e “Historias extra“
              (opcional) debe hacer clic en el botón “Completar“.
              Posteriormente, la “Feek App” le proporcionará un cupón
              único, personal, intransferible, consumible en el
              momento exacto de su emisión, para que la promoción se
              le haga efectiva.
            </li>
            <li>
              <b>Evidencia de Asistencia al Feekspot</b>. Después de
              haber completado su Feekmoment, requerimos que tome una
              foto y la suba a nuestra plataforma del ticket de
              consumo del Feekspot al que asistió.{' '}
            </li>
          </ol>
          <strong>¿Qué son los Feekpoints? </strong>
          <br />
          Los Feekpoints son nuestro sistema de recompensas. Cuantos
          más Feekpoints adquiera, más subirá de nivel en nuestra
          aplicación móvil ”Feek App”, lo que le permitirá descubrir
          experiencias exclusivas.
          <br />
          <br />
          <strong>¿Qué son los Strikes (X)?</strong>
          <br />
          La ”Feek App” es un servicio en el que pretendemos que todos
          y cada uno de los Feekers tengan acceso a disfrutar de los
          Feekmoments que proporcionan los Feekspots. Como existe un
          límite establecido por los Feekspots de las promociones
          habilitadas por ellos, le pedimos que en caso de programar
          un Feekmoment, cumpla efectivamente con su asistencia.
          <br />
          <br />
          Usted puede cancelar su asistencia a un Feekmoment con 2
          (dos) horas de antelación sin recibir un strike. Si cancela
          un Feekmoment dentro de las 2 (dos) horas previas a las que
          programó su asistencia a uno de los Feekspots o no asiste al
          Feekmoment, será acreedor a un strike.
          <br />
          <br />
          Si un Feeker acumula 3 (tres) strikes en un periodo inferior
          a 30 (treinta) días, será inhabilitado para programar
          Feekmoments por un periodo de hasta 30 (treinta) días.
          <br />
          <br />
          Si un Feekmoment fue total o parcialmente interrumpido por
          una acción específica del Feeker y/o sus acompañantes y que
          no cumpla con las reglas de cada Feekspot, el Feeker también
          será acreedor a un strike.
          <br />
          <br />
          En caso de que un Feekmoment no pueda ser completado debido
          al agotamiento de los productos y/o servicios ofrecidos por
          los Feekspots y/o debido a un caso fortuito o de fuerza
          mayor, los Feekers no recibirán un strike.
          <br />
          <br />
          <strong>
            Exenciones de responsabilidad para Feek aplicables a los
            ”Feekmoments”.
          </strong>
          <br />
          Feek no se hace responsable en caso de que alguno de los
          Feekspots se niegue a validar alguna de las promociones y/o
          ofertas anunciadas en los Feekmoments habilitados en la
          ”Feek App”. Sin embargo, Feek solicitará información al
          Feekspot para verificar el motivo por el cual la promoción u
          oferta no le fue aplicada, y dependiendo del caso, se le
          eximirá de recibir un strike.
          <br />
          <br />
          Es posible que se apliquen restricciones a los Feekmoments
          que haya reservado. Recomendamos a los Feekers a que sigan
          las normas e instrucciones de cada uno de los Feekspots que
          planean visitar (por ejemplo, códigos de vestimenta, zonas
          libres de humo y/o normas generales de comportamiento),
          indicando que Feek no será responsable si el Feekmoment fue
          total o parcialmente interrumpido debido a una acción
          realizada por el Feeker y/o sus acompañantes que constituya
          una de las restricciones impuestas por el Feekspot.
          <br />
          <br />
          La comisión de cualquier delito o infracción administrativa
          por parte de un Feeker durante la ejecución de un Feekmoment
          dará lugar a la eliminación definitiva de su cuenta y al
          baneo de cualquiera de los Servicios, presentes y futuros,
          prestados por Feek.
          <br />
          <br />
          Asimismo, Feek no se hace responsable de los cargos de
          Mensajería y Datos Móviles en los que el Feeker pueda
          incurrir por el uso de nuestros Servicios (Ver sección 1.8.
          Cargos de Datos y Teléfonos Móviles). Si un Feekmoment no
          puede ser validado debido a la falta de Datos o de conexión
          a Internet del Feeker, ni Feek ni el Feekspot serán
          responsables si las ofertas anunciadas a través de los
          Feekmoments no pueden ser aplicadas, provocando que el
          Feeker sea acreedor a un strike.
          <br />
          <br />
          <strong>1.5.2. ¿Qué son las Feekexperiencies?</strong>
          <br />
          Es nuestro programa de recompensas para nuestros usuarios
          más activos y que completen la mayor cantidad de Feekmoments
          en un periodo de tiempo determinado, según las bases de cada
          una de las Feekexperiences, encontradas en la sección
          “Recompensas”.
          <br />
          <br />
          En cada Feekexperience puede el usuario escoger participar
          en la misma y ver su ranking para efectos de saber su
          situación respecto a otros Feekers que están compitiendo por
          la recompensa ofrecida.
          <br />
          <br />
          <strong>Clasificación de las Feekexperiences.</strong>
          <br />
          <ul>
            <li>In Progress: la Feekexperience está en curso.</li>
            <li>
              Cancelled: la Feekexperience fue cancelada por Feek ya
              sea porque no hubo inscripción por parte de los Feekers
              o porque ninguno de ellos cumplió con las bases.
            </li>
            <li>
              Finished: Terminó la vigencia y las bases no fueron
              completadas por ningún Feeker.
            </li>
            <li>
              Completed: Todos los lugares ofrecidos han sido ocupados
              o la vigencia según las bases concluyó.
            </li>
            <li>
              Coming Soon: Feekexperiences que se lanzarán
              próximamente.
            </li>
          </ul>
          <strong>
            Disposiciones generales aplicables a las Feekexperiences.
          </strong>
          <br />
          Es indispensable consultar las bases de cada una de las
          Feekexperiences ofrecidas en nuestra sección de
          “Recompensas”.
          <br />
          <br />
          Independientemente de las bases de cada una de las
          Feekexperiences, estarán sujetas en general a lo siguiente:
          <ul>
            <li>
              Las Feekexperiences estarán limitadas a una cantidad
              específica de lugares.
            </li>
            <li>
              Tendrán una vigencia para la participación dentro de las
              mismas.
            </li>
            <li>
              Deberán incluir la fecha y hora del evento con el que se
              estará recompensando y/o la fecha y hora para reclamar
              las recompensas.
            </li>
            <li>
              Al publicarse una Feekexperience aparecerá clasificada
              como “Coming Soon” y pasará a “In Progress” llegada la
              fecha de inicio para participar por la recompensa.
            </li>
            <li>
              Por default, se mostrarán solo experiencias en status
              “In Progress”, pero se puede seleccionar la opción para
              mostrar experiencias con status “Coming Soon”, con la
              restricción de que la participación comenzará únicamente
              hasta que tenga el status “In Progress”.
            </li>
            <li>
              Los usuarios recibirán un notificación “push” el día y
              la hora de inicio de las Feekexperiences.
            </li>
            <li>
              Las Feekexperiences pueden ser locales (por estado o
              ciudad) o nacionales (en toda la República Mexicana).
            </li>
            <li>
              En caso de empate en el ranking para ganar una
              Feekexperience, el primer criterio de desempate entre
              Feekers será el número total de Feekmoments cancelados,
              es decir, el que tenga una menor cantidad de Feekmoments
              cancelados tendrá la preferencia para adquirir la
              recompensa, y posteriormente, en caso de seguir
              empatados, el criterio de desempate será la fecha de
              inscripción a la Feekexperience, es decir, tendrá
              preferencia quien se inscribió primero a esta
              Feekexperience para poder ganar la recompensa.
            </li>
            <li>
              El usuario debe cumplir con un mínimo de Feekmoments
              completados para poder ser acreedor a las
              Feekexperiences.
            </li>
          </ul>
          <strong>Condiciones para participar.</strong>
          <ul>
            <li>
              Cualquier Feeker puede participar, siempre y cuando,
              tenga su perfil efectivamente completado.
            </li>
            <li>
              El usuario debe inscribirse a la Feekexperience para
              participar.
            </li>
            <li>
              A partir de su fecha de inscripción comenzarán a contar
              sus Feekmoments COMPLETADOS para el ranking de
              participantes.
            </li>
            <li>
              Pueden participar en más de una Feekexperience a la vez,
              pero cada Feekmoment completado sólo podrá acreditarse
              para 1 (una) Feekexperience, a discreción del usuario.
            </li>
            <li>
              Se enviará una notificación “push” a los participantes
              con su resultado, ya sea que hayan ganado, perdido y/o
              no hayan cumplido con la totalidad de los requisitos
              según las bases de cada Feekexperience.
            </li>
            <li>
              El usuario puede ver en la sección Agenda los resultados
              de las Feekexperiences en las que ha participado.
            </li>
          </ul>
          <strong>
            Exenciones de responsabilidad para Feek aplicables a las
            “Feekexperiences”.
          </strong>
          <br />
          Feek no se hace responsable de cualesquier daño, ya sea
          pecuniario, moral, patrimonial, físico y/o psicológico
          ocasionado, antes, durante y después de la ejecución y
          asistencia a alguno de los eventos o por el uso de alguno de
          los productos otorgados como recompensa, ni se hace
          responsable por el robo, secuestro y/o extravío de las
          recompensas que sufra alguno de nuestros usuarios una vez
          que estas han sido efectivamente entregadas a los Feekers.
          <br />
          <br />
          Los traslados y/o cualesquier gasto en general incurrido por
          parte de alguno de nuestros usuarios ya sea para recoger
          alguna de las recompensas, o para asistir a los eventos en
          los que consisten las mismas, correrán única y
          exclusivamente por parte de cada usuario que sea el ganador
          de la recompensa, salvo que las bases especifiquen lo
          contrario.
          <br />
          <br />
          Asimismo, Feek no se hace responsable por la cancelación, ya
          sea total o parcial, de alguno de los eventos con los que se
          recompensó a los Feekers ganadores de las Feekexperiences.
          <br />
          <br />
          Tampoco será responsable Feek por la cancelación de alguna
          de las Feekexperiences si ningún Feeker se inscribió a
          alguna de ellas y si nadie cumplió con las bases
          especificadas en cada una de las recompensas ofrecidas.
          <br />
          <br />
          Recomendamos a los Feekers a que sigan las normas e
          instrucciones de cada uno de los eventos y/o productos a los
          que asistan y/o utilicen en virtud de la consecución de una
          recompensa.
          <br />
          <br />
          La comisión de cualquier delito o infracción administrativa
          por parte de un Feeker durante el aprovechamiento de una
          Feekexperience, o haciendo un uso indebido de los productos
          otorgados como recompensa, no será responsabilidad de Feek y
          además dará lugar a la eliminación definitiva de su cuenta y
          al baneo de cualquiera de los Servicios, presentes y
          futuros, prestados por Feek.
        </Text>
        <Text
          fontSize={20}
          lineHeight={24}
          fontWeight="bold"
          id="terms-of-use-1.6"
        >
          1.6. Uso del sitio web de Feekmanager
        </Text>
        <Text fontSize={20} lineHeight={24}>
          Esta sección aplica única y exclusivamente para los usuarios
          denominados Feekspots.
          <br />
          <br />
          Feek habilita el sitio web{' '}
          <a
            rel="noreferrer"
            target="_blank"
            href="https://www.feekmanager.com"
          >
            https://feekmanager.com
          </a>{' '}
          para su uso por todo tipo de establecimientos tales como
          restaurantes, bares, gimnasios, salones de belleza, tiendas
          de ropa, zapaterías, discotecas, hoteles, museos, dentistas,
          servicios médicos, tiendas de abarrotes, entre otros, para
          que por medio de esta pagina web puedan realizar campañas
          publicitarias y ofertas a los Feekers.
          <br />
          <br />
          Los Feekspots tienen como condición que deben contar con un
          plan comprado y vigente entre las opciones de servicio
          habilitadas por Feek para poder realizar la publicación de
          las campañas publicitarias. Los Feekspots podrán acceder a
          los servicios contratados y prestados por Feek siguiendo
          estos pasos:
          <ol>
            <li>
              <b>Crear una cuenta de Feekspot (usuarios nuevos)</b>.
              Ingresar al sitio web{' '}
              <a
                rel="noreferrer"
                target="_blank"
                href="https://www.feekmanager.com"
              >
                https://feekmanager.com
              </a>{' '}
              a la sección ‘Crear una cuenta’ y escoger alguna de las
              opciones entre correo electrónico o número de teléfono.
              Posteriormente y según la opción elegida, le llegará por
              correo o mensaje SMS al Feekspot un código numérico para
              poder proceder al alta de su cuenta.
            </li>
            <li>
              <strong>Ingresar nombre y domicilio</strong>.
              Posteriormente y de acuerdo con nuestra área de
              cobertura, es importante que los Feekspots señalen el
              nombre de su negocio y su domicilio. (Paso 1 de 3)
            </li>
            <li>
              <strong>Perfil del negocio</strong>. Cuéntanos a qué te
              dedicarás como Feekspot, señalando el giro de tu negocio
              y las categorías con las que se identifica tu negocio.
              (Paso 2 de 3)
            </li>
            <li>
              <strong>
                Felicidades, ahora eres parte de Feek como Feekspot.
              </strong>
              Recibirás un breve mensaje mencionando que ya formas
              parte de nuestra comunidad como Feekspot. (Paso 3 de 3)
            </li>
            <li>
              <strong>Completa tu perfil</strong>. Sigue la guía para
              completar tu perfil de Feekspot al 100% en dónde se te
              pedirá lo siguiente: i. Datos de cuenta, en dónde
              deberás señalar un correo electrónico y un número
              telefónico. ii. Instagram vinculado, en dónde mediante
              el servicio de inicio de sesión de Instagram (servicio
              de third-party) iniciarás sesión con tu cuenta de
              Instagram. iii. Logo del negocio, en dónde deberás
              cargar la imagen de tu logo para que los Feekers te
              identifiquen. iv. Imagen del negocio, en dónde podrás
              cargar una imagen representativa de tu giro de negocio.
              v. Dirección y horario, en dónde deberás señalar el
              horario de servicios y corregir la dirección de tu
              establecimiento. vi. Obtener un plan, en dónde deberás
              seleccionar una de nuestras opciones de servicio y
              proceder al pago del plan que sea más adecuado
              dependiendo de tu negocio.
            </li>
            <li>
              <strong>Procedimiento de pago</strong>. Para proceder
              con el pago de tu plan, deberás llenar los siguientes
              datos: Nombre en tarjeta, Número de tarjeta, Fecha de
              expiración y CVV. Estos datos se encuentran protegidos
              de conformidad con nuestro Aviso de Privacidad.{' '}
            </li>
            <li>
              <strong>Facturación</strong>. En caso de que el
              Feekspot, al momento de realizar el pago de conformidad
              con el punto anterior, requiera factura, deberá señalar
              los siguientes datos: Nombre o razón social, RFC,
              Domicilio fiscal, Régimen fiscal y correo electrónico.
            </li>
            <li>
              <strong>Estás listo para crear campañas</strong>. En
              caso de que hayas completado los pasos anteriores, o que
              ya hayas dado de alta tu cuenta anteriormente y
              seleccionado un plan de pagos, podrás hacer login a la
              cuenta de tu negocio para empezar a crear campañas
              publicitarias.{' '}
            </li>
          </ol>
          <strong>¿Cómo crear una campaña publicitaria?</strong>
          <br />
          Una vez dado de alta como Feekspot, podrás crear campañas
          publicitarias siguiendo estos pasos:
          <ol>
            <li>
              <strong>Crear una campaña</strong>. Para crear una
              campaña deberás buscar el botón ‘Campañas’, y después
              hacer clic en ‘Crear Campaña’.
            </li>
            <li>
              <strong>Tipo de campaña</strong>. Podrás escoger el tipo
              de campaña que quieres ofrecer a los Feekers, ya sea un
              descuento en porcentaje (%), una devolución o descuento
              monetario ($), la adquisición de un producto adicional
              en la compra de otros productos en tu establecimiento,
              entre otras propuestas. (Paso 1 de 4)
            </li>
            <li>
              <strong>Número de asistentes</strong>. Señalar hacia
              quiénes va dirigida la campaña, ya sea de forma
              individual (en la que un Feeker asiste al
              establecimiento), en pareja (el Feeker y un acompañante)
              y en grupo (el Feeker y un grupo de dos o más personas).
              (Paso 2 de 4)
            </li>
            <li>
              <strong>Descripción de la campaña</strong>. En esta
              sección, deberás ponerle un título a tu campaña
              publicitaria, la descripción y el contenido que deberá
              realizar el Feeker al ir a tu negocio. (Paso 3 de 4)
            </li>
            <li>
              <strong>Actividad de la campaña</strong>. Deberás
              establecer el tiempo de duración de tu campaña, los días
              y el horario en el que estarán disponibles tus ofertas a
              los Feekers. También podrás agregar una imagen a tu
              campaña. (Paso 4 de 4).
            </li>
            <li>
              <strong>Historial de campañas</strong>. Una vez
              realizadas tus campañas podrás visualizar el estatus de
              cada una de ellas.
            </li>

            <li>
              <strong>Campaign history.</strong> Once you have
              completed your campaigns you will be able to view the
              status of each one of them.
            </li>
            <ul>
              <li>
                En curso: Significa que tu campaña se encuentra
                vigente.
              </li>
              <li>
                Completada: Significa que los lugares disponibles y
                habilitados en tu campaña han sido reservados por los
                Feekers y/o que la duración de tu campaña a llegado a
                su fin.
              </li>
            </ul>
          </ol>
          <strong>¿Cómo validar un Feekmoment como Feekspot?</strong>
          <br />
          Los Feekers tendrán que seguir una serie de pasos para
          validar las ofertas que has publicado en tus campañas.
          Cuando los Feekers ya han completado todos estos pasos,
          tienes que validar el Feekmoment haciendo lo siguiente:
          <ol>
            <li>
              <strong>Página web de Feekmanager</strong>. Ve a la
              página web{' '}
              <a
                rel="noreferrer"
                target="_blank"
                href="https://www.feekmanager.com"
              >
                https://feekmanager.com
              </a>{' '}
              e inicia sesión. Elige el botón ”Campañas” y revisa tus
              campañas en curso.
            </li>
            <li>
              <strong>Validación</strong>. Haga clic en el botón
              ”Validar Feekmoment” en la campaña que necesitas validar
              y, posteriormente, se solicitará un código de 6 números.
              Este código de 6 números será proporcionado por los
              Feekers cuando hayan completado todos los pasos en la
              aplicación móvil ”Feek App”. La validación puede ser
              habilitada también a través de un código QR que en todos
              los casos será proporcionado por los Feekers cuando
              completen con éxito los requisitos de su campaña.
            </li>
          </ol>
          <strong>Analíticas.</strong>
          <br />
          En este espacio los Feekspots pueden ver los resultados
          estadísticos de sus campañas. Gracias a la meta data
          descargada del API Graph de Instragram, Feek presenta a los
          Feekspots las analíticas más relevantes y de mayor interés
          para los Feekspots y le muestra los resultados de las
          campañas.
          <br />
          <br />
          Puedes utilizar los filtros de las campañas (en curso o
          completadas) o el período para observar las estadísticas de
          ciertas campañas en específico.
          <br />
          <br />
          <strong>Colaboradores.</strong>
          <br />
          Como Feekspot y para administrar tus campañas publicitarias
          podrás integrar parte de tu equipo como colaboradores de tu
          negocio, quienes podrán llevar a cabo diferentes acciones en
          la página{' '}
          <a
            rel="noreferrer"
            target="_blank"
            href="https://www.feekmanager.com"
          >
            https://feekmanager.com
          </a>
          .
          <br />
          <br />
          Para dar de alto un colaborador deberás escoger el tipo de
          colaboración que tendrá de acuerdo con lo siguiente:
          <ul>
            <li>
              Administrador: Tiene el control total de la cuenta,
              puede ver las analíticas y estadísticas de las campañas,
              crear y eliminar campañas, y gestionar a los
              colaboradores.
            </li>
            <li>
              Supervisor: Tiene acceso a las analíticas y estadísticas
              de las campañas, además de que puede crear y eliminar
              campañas.
            </li>
            <li>
              Espectador: Solamente tendrá acceso a las analíticas y
              las estadísticas de las campañas.
            </li>
          </ul>
          Para dar de alta un colaborador, debes señalar su nombre y
          apellido, número de teléfono y su correo electrónico.
          <br />
          <br />
          Los Feekspots tienen como límite dar de alta 3 (tres)
          colaboradores.
          <br />
          <br />
          <strong>Perfil del Feekspot.</strong>
          <br />
          En esta sección podrás encontrar las opciones para tu
          modificar algún dato personal de los ingresados para la
          creación de tu cuenta, así como los ajustes y la opción para
          cerrar tu sesión.
          <br />
          <br />
          <strong>
            Exenciones de responsabilidad para Feek aplicables al
            sitio web de Feekmanager.
          </strong>
          <br />
          Feek se reserva su derecho a suspender los servicios y el
          acceso a los Feekspots a su cuenta en caso de que haya
          vencido el plan contratado por el Feekspot o que al intentar
          hacer un cargo domiciliado mensualmente, el método de pago
          del Feekspot sea rechazado o se determine que dicho método
          de pago no cuenta con fondos suficientes.
          <br />
          <br />
          Los Feekspots son los únicos responsables del contenido de
          las ofertas y/o promociones estipuladas a través de las
          campañas, liberando a Feek de esta responsabilidad.
          <br />
          <br />
          Asimismo, los Feekspots se adhieren a las reglas de las
          ofertas al público que se hagan mediante las campañas
          publicitarias publicadas en uso del sitio web{' '}
          <a
            rel="noreferrer"
            target="_blank"
            href="https://www.feekmanager.com"
          >
            https://feekmanager.com
          </a>{' '}
          de conformidad y en cumplimento con lo estipulado en los
          artículos 1860 al 1881 del Código Civil Federal de la
          República Mexicana y al Capítulo IV de la Ley Federal de
          Protección al Consumidor.
          <br />
          <br />
          Los Feekspots deberán apegarse en todo momento a las tarifas
          de los productos y/o servicios ofrecidos a los Feekers tal y
          como son ofrecidos al público en general, absteniéndose de
          implementar cualquier inflación, costo oculto o incremento
          en sus precios de manera que al momento de realizar alguna
          promoción u oferta alteren el precio de algún producto y/o
          servicio haciendo que en realidad las promociones y ofertas
          publicadas en sus campañas no tengan un valor cuantitativo
          real para el Feeker. Los Feekspots deberán seguir estas
          instrucciones de acuerdo con lo establecido en los artículos
          57 y 58 de la Ley Federal de Protección al Consumidor so
          pena de incurrir en la suspensión temporal y/o total de su
          cuenta Feekspot.
          <br />
          <br />
          Asimismo, todos los productos y servicios ofrecidos a través
          de las campañas publicadas por Feekspots están sujetos a
          disponibilidad en el establecimiento. Pueden aplicarse
          restricciones a todas las ofertas y campañas. Además, todos
          los productos y servicios ofrecidos a través de las campañas
          están limitados a la cobertura geográfica estipulada por
          cada Feekspot.
          <br />
          <br />
          Ni Feek ni los Feekspots serán responsables del
          incumplimiento de las ofertas publicadas en las campañas
          cuando dicho incumplimiento se derive de algún caso fortuito
          o de fuerza mayor.
          <br />
          <br />
          Los Feekspots, al crear su cuenta y proveer en su
          información de contacto datos como: nombre del
          establecimiento, domicilio, logos y fotografías del
          establecimiento y/o de los productos y/o servicios ofrecidos
          por el Feekspot, están de acuerdo en que Feek se encuentra
          en todo su derecho de promocionar, publicitar, promover y/o
          pautar mediante campañas de mercadotecnia digital y de redes
          sociales dicha información (para más información, ver el
          <Link to={ROUTES.POLICY}> Aviso de Privacidad</Link> de
          Feek.)
        </Text>
        <Text
          fontSize={20}
          lineHeight={24}
          fontWeight="bold"
          id="terms-of-use-1.7"
        >
          1.7. Seguridad
        </Text>
        <Text fontSize={20} lineHeight={24}>
          Nos esforzamos por mantener nuestros Servicios como un lugar
          seguro para todos los usuarios. Pero no podemos
          garantizarlo. Ahí es donde entra usted. Al utilizar los
          Servicios, usted acepta que siempre cumplirá con estos
          Términos y cualquier otra política que Feek ponga a
          disposición para mantener la seguridad de los Servicios.
          <br />
          <br />
          Si no cumple, nos reservamos el derecho de eliminar
          cualquier contenido ofensivo, cancelar o limitar la
          visibilidad de su cuenta, y notificar a terceros -incluyendo
          a las autoridades- y proporcionar a esos terceros
          información relacionada con su cuenta. Esta medida puede ser
          necesaria para proteger la seguridad de nuestros usuarios y
          de otros, para investigar, remediar y hacer cumplir posibles
          violaciones de los Términos, y para detectar y resolver
          cualquier fraude o problema de seguridad.
          <br />
          <br />
          También nos preocupa su seguridad física mientras utiliza
          nuestros Servicios. Por ello, no utilice nuestros Servicios
          de forma que le impida obedecer las leyes de tráfico o de
          seguridad. Por ejemplo, nunca utilice los Servicios mientras
          conduce, y nunca se ponga en peligro a sí mismo o a otros
          mientras utiliza nuestros Servicios.
        </Text>
        <Text
          fontSize={20}
          lineHeight={24}
          fontWeight="bold"
          id="terms-of-use-1.8"
        >
          1.8. Tarifas de datos y teléfonos móviles
        </Text>
        <Text fontSize={20} lineHeight={24}>
          Usted es responsable de las tarifas de telefonía móvil en
          las que pueda incurrir por el uso de nuestros Servicios,
          incluidos los mensajes de texto (como SMS, MMS o futuros
          protocolos o tecnologías de este tipo) y las tarifas de
          datos. Si no está seguro de cuáles pueden ser esos cargos,
          debe preguntar a su proveedor de servicios antes de utilizar
          los Servicios.
          <br />
          <br />
          Al proporcionarnos su número de teléfono móvil, usted acepta
          recibir mensajes SMS de Feek relacionados con los Servicios,
          incluyendo sobre promociones, su cuenta y su relación con
          Feek. Estos mensajes SMS pueden ser realizados a su número
          de teléfono móvil incluso si su número de teléfono móvil
          está registrado en cualquier tipo de lista de ”No llamar”, o
          equivalente internacional.
          <br />
          <br />
          Si cambia o desactiva el número de teléfono móvil que
          utilizó para crear una cuenta, deberá actualizar la
          información de su cuenta a través de la configuración para
          evitar que enviemos mensajes destinados a usted a otra
          persona
        </Text>
        <Text
          fontSize={20}
          lineHeight={24}
          fontWeight="bold"
          align="center"
        >
          2. CONDICIONES DE SERVICIO
        </Text>

        <a href="#terms-of-service-2.1">
          <Text fontSize={20} lineHeight={24} fontWeight="bold">
            2.1. Acerca de los servicios
          </Text>
        </a>
        <a href="#terms-of-service-2.2">
          <Text fontSize={20} lineHeight={24} fontWeight="bold">
            2.2. Derechos que le concedemos
          </Text>
        </a>
        <a href="#terms-of-service-2.3">
          <Text fontSize={20} lineHeight={24} fontWeight="bold">
            2.3. Derechos que usted nos concede
          </Text>
        </a>
        <a href="#terms-of-service-2.4">
          <Text fontSize={20} lineHeight={24} fontWeight="bold">
            2.4. Privacidad
          </Text>
        </a>
        <a href="#terms-of-service-2.5">
          <Text fontSize={20} lineHeight={24} fontWeight="bold">
            2.5. Respeto de los servicios y de los derechos de Feek
          </Text>
        </a>
        <a href="#terms-of-service-2.6">
          <Text fontSize={20} lineHeight={24} fontWeight="bold">
            2.6. Servicios de terceros
          </Text>
        </a>
        <a href="#terms-of-service-2.7">
          <Text fontSize={20} lineHeight={24} fontWeight="bold">
            2.7. Modificación de los servicios y terminación
          </Text>
        </a>
        <a href="#terms-of-service-2.8">
          <Text fontSize={20} lineHeight={24} fontWeight="bold">
            2.8. Indemnización
          </Text>
        </a>
        <a href="#terms-of-service-2.9">
          <Text fontSize={20} lineHeight={24} fontWeight="bold">
            2.9. Descargo de responsabilidad
          </Text>
        </a>
        <a href="#terms-of-service-2.10">
          <Text fontSize={20} lineHeight={24} fontWeight="bold">
            2.10. Limitación de la responsabilidad
          </Text>
        </a>
        <br />
        <Text
          fontSize={20}
          lineHeight={24}
          fontWeight="bold"
          id="terms-of-service-2.1"
        >
          2.1. Acerca de los servicios
        </Text>
        <Text fontSize={20} lineHeight={24}>
          Feek ofrece sus Servicios a los usuarios a través de la
          aplicación móvil ”Feek App” y la página web{' '}
          <a
            rel="noreferrer"
            target="_blank"
            href="https://www.feekmanager.com"
          >
            https://feekmanager.com
          </a>
          . La finalidad de nuestros Servicios es conectar a los
          Feekers con los Feekspots, creando una relación en la que
          los Feekers obtienen experiencias únicas habilitadas por los
          Feekspots mediante la publicación de campañas para la
          adquisición de todo tipo de productos y/o servicios
          (”Feekmoments”).
          <br />
          <br />
          Los Feekmoments se muestran en la ”Feek App” para los
          Feekers y son creados por los Feekspots en la página web{' '}
          <a
            rel="noreferrer"
            target="_blank"
            href="https://www.feekmanager.com"
          >
            https://feekmanager.com
          </a>
          <br />
          <br />
          Cualquier Feeker que acceda y/o utilice la ”Feek App”
          reconoce que los Servicios de Feek no incluyen la venta de
          productos o servicios, la entrega a domicilio y/o los
          servicios logísticos, ya que la relación comercial a la hora
          de adquirir los productos y servicios anunciados en los
          Feekmoments se establece directamente entre el Feeker y el
          Feekspot, que actúa de forma independiente y no puede
          entenderse en ningún caso como un empleado de Feek o sus
          filiales.
          <br />
          <br />
          Cualquier Feekspot que acceda y/o utilice la web{' '}
          <a
            rel="noreferrer"
            target="_blank"
            href="https://www.feekmanager.com"
          >
            https://feekmanager.com
          </a>{' '}
          reconoce que los Servicios de Feek sólo incluyen la
          adquisición de planes para publicar campañas, mientras que
          la relación comercial derivada de la adquisición de los
          productos y/o servicios anunciados en los Feekmoments se
          establecerá directamente con el Feeker.
          <br />
          <br />
          Salvo que Feek lo acuerde en un contrato escrito
          independiente con los usuarios, los Servicios se ponen a su
          disposición únicamente para su uso personal y no comercial.
        </Text>
        <Text
          fontSize={20}
          lineHeight={24}
          fontWeight="bold"
          id="terms-of-service-2.2"
        >
          2.2. Derechos que le concedemos
        </Text>
        <Text fontSize={20} lineHeight={24}>
          Entre usted y nosotros, Feek (y sus licenciatarios) es el
          propietario de los Servicios, incluyendo todo el contenido
          de propiedad, información, material, software, imágenes,
          texto, gráficos, ilustraciones, logotipos, patentes, marcas
          comerciales, marcas de servicio, derechos de autor,
          fotografías, audio, vídeo, música, y ”look and feel” de los
          Servicios, y todos los derechos de propiedad intelectual
          relacionados. Feek le concede una licencia mundial, libre de
          derechos, no cedible, no exclusiva, revocable y no
          sublicenciable para utilizar los Servicios. Esta licencia es
          con el único propósito de usar y disfrutar de los Servicios
          de una manera que estos Términos y nuestras políticas
          permiten, como el uso privado de la API. Usted no puede
          utilizar los Servicios de forma no autorizada por estos
          Términos. Tampoco puede ayudar a nadie a hacerlo.
        </Text>
        <Text
          fontSize={20}
          lineHeight={24}
          fontWeight="bold"
          id="terms-of-service-2.3"
        >
          2.3. Derechos que usted nos concede
        </Text>
        <Text fontSize={20} lineHeight={24}>
          Muchos de nuestros Servicios le permiten crear, cargar,
          publicar, enviar, recibir y almacenar contenidos. Al
          hacerlo, usted conserva los derechos de propiedad de ese
          contenido que tenía al principio. Sin embargo, nos concede
          una licencia para utilizar ese contenido. La amplitud de esa
          licencia depende de los Servicios que utilice y de la
          Configuración que haya seleccionado.
          <br />
          <br />
          Para todo el contenido que envíe a los Servicios, usted
          concede a Feek y a nuestros afiliados una licencia mundial,
          libre de derechos, sublicenciable y transferible para
          alojar, almacenar, guardar en caché, utilizar, mostrar,
          reproducir, modificar, adaptar, editar, publicar, analizar,
          transmitir y distribuir ese contenido. Esta licencia tiene
          por objeto operar, desarrollar, proporcionar, promover y
          mejorar los Servicios e investigar y desarrollar otros
          nuevos. Esta licencia incluye el derecho a que pongamos su
          contenido a disposición de los proveedores de servicios con
          los que tenemos relaciones contractuales relacionadas con la
          prestación de los Servicios, y a que les transmitamos estos
          derechos, con el único fin de prestar dichos Servicios.
          <br />
          <br />
          En la medida en que la ley lo permita, usted renuncia
          irrevocablemente -o acepta no hacer valer frente a Feek o
          sus afiliados- a cualquier derecho moral o equivalente que
          pueda tener sobre el contenido que comparta al utilizar
          nuestros Servicios.
          <br />
          <br />
          Feek, nuestras filiales y nuestros socios externos pueden
          colocar publicidad en los Servicios, incluida la publicidad
          personalizada -con su consentimiento, cuando sea necesario-,
          basándose en la información que usted nos proporcione, que
          recopilemos o que obtengamos sobre usted. En ocasiones, la
          publicidad puede aparecer cerca, entre, sobre o en su
          contenido.
          <br />
          <br />
          Siempre nos gusta escuchar a nuestros usuarios. Si nos da su
          opinión o sus sugerencias (véase la{' '}
          <strong>sección 3.5 Cómo Contactarnos</strong>), sepa que
          podemos utilizarlas sin compensarle y sin ninguna
          restricción u obligación para usted. Usted acepta que
          poseamos todos los derechos sobre cualquier material o
          artículo que desarrollemos basándonos en dichos comentarios
          o sugerencias.
        </Text>
        <Text
          fontSize={20}
          lineHeight={24}
          fontWeight="bold"
          id="terms-of-service-2.4"
        >
          2.4. Privacidad
        </Text>
        <Text fontSize={20} lineHeight={24}>
          Su privacidad nos importa. Puede saber cómo se maneja su
          información cuando utiliza nuestros Servicios mirando el
          Centro de Privacidad de Feek, que es un centro amigable para
          el usuario que explica los elementos clave de la Privacidad
          en Feek, o leyendo nuestra Política de Privacidad.
          <br />
          <br />
          La recopilación y el uso de información personal por parte
          de Feek en relación con los Servicios se realiza de acuerdo
          con la Política de Privacidad de Feek, disponible en
          <Link to={ROUTES.POLICY}>
            {' '}
            https://www.feekmanager.com{ROUTES.POLICY}
          </Link>
          . Feek puede proporcionar a un procesador de reclamaciones o
          a una aseguradora cualquier información necesaria
          (incluyendo su información de contacto) si hay quejas,
          disputas o conflictos, que pueden incluir un incumplimiento
          de contrato, que le involucran a usted y a un tercero y
          dicha información o datos son necesarios para resolver la
          queja, disputa o conflicto.
        </Text>
        <Text
          fontSize={20}
          lineHeight={24}
          fontWeight="bold"
          id="terms-of-service-2.5"
        >
          2.5. Respeto de los servicios y de los derechos de Feek
        </Text>
        <Text fontSize={20} lineHeight={24}>
          También debe respetar los derechos de Feek, lo que
          significa, entre otras cosas, que no puede hacer, intentar
          hacer, permitir o alentar a cualquier otra persona a hacer
          lo siguiente:
          <ul>
            <li>
              utilizar la marca, los logotipos, los iconos, los
              elementos de la interfaz de usuario, los diseños, las
              fotografías, los vídeos o cualquier otro material que
              Feek ponga a disposición a través de los Servicios,
              salvo que esté explícitamente permitido por estos
              Términos u otras directrices de marca publicadas por
              Feek o nuestros afiliados;
            </li>
            <li>
              violar o infringir los derechos de autor, marcas
              registradas u otros derechos de propiedad intelectual de
              Feek o de nuestros afiliados;
            </li>
            <li>
              copiar, modificar, archivar, descargar, cargar, revelar,
              distribuir, vender, arrendar, sindicar, emitir,
              ejecutar, mostrar, poner a disposición, hacer derivados
              de, o utilizar de otra manera los Servicios o el
              contenido de los Servicios, aparte de los archivos
              temporales que son automáticamente almacenados en caché
              por su navegador web para fines de visualización, como
              se permite expresamente en estos Términos, como se
              permite expresamente por nosotros por escrito, o como lo
              permite la funcionalidad prevista del Servicio;
            </li>
            <li>
              crear más de una cuenta para usted, crear otra cuenta si
              ya hemos desactivado su cuenta, intentar acceder a los
              Servicios a través de aplicaciones de terceros no
              autorizadas, solicitar credenciales de inicio de sesión
              a otros usuarios, o comprar, vender, alquilar o arrendar
              el acceso a su cuenta o nombre de usuario;
            </li>
            <li>
              realizar ingeniería inversa, duplicar, descompilar,
              desensamblar o descodificar los Servicios (incluida
              cualquier idea o algoritmo subyacente), o extraer de
              cualquier otra forma el código fuente del software del
              Servicio;
            </li>
            <li>
              utilizar cualquier robot, araña, rastreador, scraper u
              otro medio o interfaz automatizado para acceder a los
              Servicios o extraer la información de otros usuarios;
            </li>
            <li>
              utilizar o desarrollar cualquier aplicación de terceros
              que interactúe con los Servicios o con el contenido o la
              información de otros usuarios sin nuestro consentimiento
              por escrito;
            </li>
            <li>
              utilizar los Servicios de forma que pueda interferir,
              interrumpir, afectar negativamente o impedir que otros
              usuarios disfruten plenamente de los Servicios, o que
              pueda dañar, inutilizar, sobrecargar o perjudicar el
              funcionamiento de los Servicios;
            </li>
            <li>
              cargar virus u otros códigos maliciosos o comprometer,
              evitar o eludir la seguridad de los Servicios;
            </li>
            <li>
              intentar eludir cualquier técnica de filtrado de
              contenidos que empleemos, o intentar acceder a áreas o
              funciones de los Servicios a las que no esté autorizado
              a acceder;
            </li>
            <li>
              sondear, escanear o probar la vulnerabilidad de nuestros
              Servicios o de cualquier sistema o red;
            </li>
            <li>
              violar cualquier ley o reglamento aplicable en relación
              con su acceso o uso de los Servicios; o
            </li>
            <li>
              acceder o utilizar los Servicios de cualquier forma no
              permitida expresamente por estos Términos.
            </li>
          </ul>
        </Text>
        <Text
          fontSize={20}
          lineHeight={24}
          fontWeight="bold"
          id="terms-of-service-2.6"
        >
          2.6. Servicios de terceros
        </Text>
        <Text fontSize={20} lineHeight={24}>
          Algunos Servicios pueden mostrar, incluir o poner a
          disposición contenidos, datos, información, aplicaciones,
          funciones o materiales de terceros (”Materiales de
          terceros”), o proporcionar enlaces a determinados sitios web
          de terceros. Si usted utiliza cualquier Material de Terceros
          disponible a través de nuestros Servicios (incluyendo los
          Servicios que ofrecemos en conjunto con el tercero), los
          términos de cada parte regirán la relación de la parte
          respectiva con usted. Ni Feek ni nuestros afiliados son
          responsables de los términos de un tercero o de las acciones
          realizadas bajo los términos de dicho tercero. Además, al
          utilizar los Servicios, usted reconoce y acepta que Feek no
          es responsable de examinar o evaluar el contenido, la
          exactitud, la integridad, la disponibilidad, la puntualidad,
          la validez, el cumplimiento de los derechos de autor, la
          legalidad, la decencia, la calidad o cualquier otro aspecto
          de dichos materiales o sitios web de terceros. No
          garantizamos ni respaldamos y no asumimos ni tendremos
          ninguna responsabilidad ante usted o cualquier otra persona
          por los servicios de terceros, los Materiales de Terceros o
          los sitios web de terceros, o por cualquier otro material,
          producto o servicio de terceros. Los Materiales de Terceros
          y los enlaces a otros sitios web se proporcionan únicamente
          para su comodidad.
        </Text>
        <Text
          fontSize={20}
          lineHeight={24}
          fontWeight="bold"
          id="terms-of-service-2.7"
        >
          2.7. Modificación de los servicios y terminación
        </Text>
        <Text fontSize={20} lineHeight={24}>
          Mejoramos constantemente nuestros Servicios y creamos otros
          nuevos. Esto significa que podemos añadir o eliminar
          características, productos o funcionalidades, y también
          podemos suspender o detener los Servicios por completo.
          Podemos llevar a cabo cualquiera de estas acciones en
          cualquier momento, y cuando lo hagamos, intentaremos
          notificárselo de antemano, pero esto no siempre será
          posible.
          <br />
          <br />
          Aunque esperamos que siga siendo un usuario de por vida,
          puede rescindir estos Términos en cualquier momento y por
          cualquier motivo eliminando su cuenta de Feek (o, en algunos
          casos, la cuenta asociada a la parte aplicable de los
          Servicios que esté utilizando).
          <br />
          <br />
          Podemos terminar o suspender temporalmente su acceso a los
          Servicios si no cumple con estos Términos o la ley, por
          cualquier razón fuera de nuestro control, o por cualquier
          razón, y sin previo aviso. Esto significa que podemos
          rescindir estos Términos, dejar de prestarle todos o parte
          de los Servicios, o imponerle límites nuevos o adicionales a
          su capacidad para utilizar nuestros Servicios. Y aunque
          intentaremos avisarle con una antelación razonable, no
          podemos garantizar que el aviso sea posible en todas las
          circunstancias. Por ejemplo, podemos desactivar su cuenta
          debido a una inactividad prolongada, y podemos reclamar su
          nombre de usuario en cualquier momento y por cualquier
          motivo.
        </Text>
        <Text
          fontSize={20}
          lineHeight={24}
          fontWeight="bold"
          id="terms-of-service-2.8"
        >
          2.8. Indemnización
        </Text>
        <Text fontSize={20} lineHeight={24}>
          Usted acepta, en la medida permitida por la ley, indemnizar,
          defender y eximir de responsabilidad a Feek, nuestros
          afiliados, directores, funcionarios, accionistas, empleados,
          licenciatarios y agentes de y contra cualquier queja, cargo,
          reclamación, daño, pérdida, costo, responsabilidad y gasto
          (incluyendo los honorarios de los abogados) debido a, que
          surja de, o relacionado de alguna manera con: (a) su acceso
          o uso de los Servicios; (b) su contenido, incluidas las
          reclamaciones por infracción relacionadas con su contenido;
          (c) su incumplimiento de estos Términos o de cualquier ley o
          reglamento aplicable; o (d) su negligencia o mala conducta
          intencionada.
        </Text>
        <Text
          fontSize={20}
          lineHeight={24}
          fontWeight="bold"
          id="terms-of-service-2.9"
        >
          2.9. Descargo de responsabilidad
        </Text>
        <Text fontSize={20} lineHeight={24}>
          Nos esforzaremos por mantener los Servicios en
          funcionamiento y libres de molestias. Pero no prometemos que
          lo consigamos.
          <br />
          <br />
          <strong>
            Los Servicios se proporcionan ”tal cual” y ”como están
            disponibles” y, en la medida en que lo permita la ley y
            salvo lo indicado anteriormente, sin garantías de ningún
            tipo, ya sean expresas o implícitas, incluidas las
            garantías implícitas, las condiciones u otros términos
            relacionados con (i) la comerciabilidad, la calidad
            satisfactoria, la idoneidad para un fin determinado, la
            titularidad, el disfrute tranquilo, la no infracción, o
            (ii) que surjan de un curso de operaciones. Además, aunque
            Frengers, Inc. intenta proporcionar una buena experiencia
            al usuario, no declaramos ni garantizamos que (a) los
            Servicios serán siempre totalmente seguros, libres de
            errores u oportunos; (b) los Servicios funcionarán siempre
            sin retrasos, interrupciones o imperfecciones; o (c)
            cualquier contenido o información que usted obtenga a
            través de los Servicios será siempre oportuno o preciso.
            <br />
            <br />
            SI LA LEY DEL PAÍS DONDE USTED VIVE NO PERMITE LAS
            EXCLUSIONES PREVISTAS EN ESTA CLÁUSULA, DICHAS EXCLUSIONES
            NO SE APLICARÁN EN LA MEDIDA EN QUE ESTÉN PROHIBIDAS.
            <br />
            <br />
            En la medida en que lo permita la ley, Frengers. Inc. y
            nuestros afiliados no asumen ninguna responsabilidad por
            cualquier contenido que usted, otro usuario o un tercero
            cree, cargue, publique, envíe, reciba, vea o almacene en
            nuestros Servicios o a través de ellos, y usted entiende y
            acepta que puede estar expuesto a contenido que podría ser
            ofensivo, ilegal, engañoso o de otro modo inapropiado,
            ninguno de los cuales Frengers. Inc. ni nuestros afiliados
            serán responsables.
            <br />
            <br />
            Nada de lo dispuesto en estos Términos excluirá o limitará
            la responsabilidad que podamos tener de eliminar
            contenidos si así lo exige la legislación del país en el
            que vives.
          </strong>
        </Text>
        <Text
          fontSize={20}
          lineHeight={24}
          fontWeight="bold"
          id="terms-of-service-2.10"
        >
          2.10. Limitación de la responsabilidad
        </Text>
        <Text fontSize={20} lineHeight={24}>
          <strong>
            Frengers. Inc, y nuestros afiliados, directores,
            funcionarios, accionistas, empleados, licenciatarios,
            proveedores y agentes no serán responsables de ningún daño
            indirecto, incidental, especial, consecuente, punitivo o
            múltiple, o de cualquier pérdida de beneficios o ingresos,
            ya sea incurrida directa o indirectamente, o de cualquier
            pérdida de datos, uso, fondo de comercio u otras pérdidas
            intangibles, que resulten de (a) su uso de los Servicios o
            la imposibilidad de usarlos; (b) su acceso o imposibilidad
            de acceder a los Servicios; (c) la conducta o el contenido
            de otros usuarios o terceros en los Servicios o a través
            de ellos; o (d) el acceso, uso o alteración no autorizados
            de su contenido. Excepto en la medida en que se
            especifique lo contrario en cualquier otro término
            aplicable de Frengers Inc. o de nuestros afiliados, en
            ningún caso la responsabilidad agregada de Frengers, Inc.
            o de nuestros afiliados por todas las reclamaciones
            relacionadas con los Servicios excederá el mayor de los
            siguientes importes: (a) 100.00 (CIEN DÓLARES
            ESTADOUNIDENSES 00/100), y (b) la cantidad que usted pagó
            a Frengers, Inc. en los últimos 3 (Tres) meses por
            cualquier Servicio.
            <br />
            <br />
            Nada en estos Términos (o para evitar dudas, cualquier
            otro término al que usted esté sujeto con respecto a la
            prestación de Servicios por parte de Frengers, Inc. o
            nuestros afiliados) excluirá o limitará la responsabilidad
            de Frengers, Inc. o nuestros afiliados por: (a) la muerte
            o los daños personales derivados de su propia intención o
            negligencia; (b) el fraude o la tergiversación
            fraudulenta; o (c) cualquier otra responsabilidad en la
            medida en que dicha responsabilidad no pueda ser excluida
            o limitada por ley.
            <br />
            <br />
            Además, ninguno de estos Términos afecta a sus derechos
            legales como consumidor.
            <br />
            <br />
            SI LA LEY DEL PAÍS DONDE USTED VIVE NO PERMITE NINGUNA
            LIMITACIÓN DE RESPONSABILIDAD PREVISTA EN ESTA CLÁUSULA,
            DICHA LIMITACIÓN NO SE APLICARÁ EN LA MEDIDA EN QUE ESTÉ
            PROHIBIDA.
          </strong>
        </Text>
        <Text
          fontSize={20}
          lineHeight={24}
          fontWeight="bold"
          align="center"
        >
          3. MISCELÁNEOS
        </Text>
        <a href="#miscelaneous-3.1">
          <Text fontSize={20} lineHeight={24} fontWeight="bold">
            3.1. Jurisdicción
          </Text>
        </a>
        <a href="#miscelaneous-3.2">
          <Text fontSize={20} lineHeight={24} fontWeight="bold">
            3.2. Legislación Aplicable
          </Text>
        </a>
        <a href="#miscelaneous-3.3">
          <Text fontSize={20} lineHeight={24} fontWeight="bold">
            3.3. Divisibilidad
          </Text>
        </a>
        <a href="#miscelaneous-3.4">
          <Text fontSize={20} lineHeight={24} fontWeight="bold">
            3.4. Condiciones finales
          </Text>
        </a>
        <a href="#miscelaneous-3.5">
          <Text fontSize={20} lineHeight={24} fontWeight="bold">
            3.5. Contacto
          </Text>
        </a>
        <br />
        <Text
          fontSize={20}
          lineHeight={24}
          fontWeight="bold"
          id="miscelaneous-3.1"
        >
          3.1. Jurisdicción
        </Text>
        <Text fontSize={20} lineHeight={24}>
          En la medida en que estos Términos le permiten a usted o a
          Feek iniciar un litigio en un tribunal, tanto usted como
          Feek aceptan que todas las reclamaciones y controversias (ya
          sean contractuales o de otro tipo), que surjan de o se
          relacionen con los Términos o el uso de los Servicios serán
          litigadas exclusivamente en los tribunales de Los Ángeles,
          California, Estados Unidos de América, a menos que esto esté
          prohibido por las leyes del país donde usted reside. Usted y
          Feek aceptan la jurisdicción exclusiva de dichos tribunales.
        </Text>
        <Text
          fontSize={20}
          lineHeight={24}
          fontWeight="bold"
          id="miscelaneous-3.2"
        >
          3.2. Legislación Aplicable
        </Text>
        <Text fontSize={20} lineHeight={24}>
          Las leyes de México rigen estos Términos y cualquier reclamo
          y/o litigio (ya sea contrato, agravio o de otro tipo) que
          surja de o se relacione con estos Términos o su objeto. Los
          tribunales de algunos países pueden no aplicar las leyes de
          México a algunos litigios relacionados con estos Términos.
          Si usted reside en uno de esos países, las leyes de su país
          de origen pueden aplicarse a esos litigios.
        </Text>
        <Text
          fontSize={20}
          lineHeight={24}
          fontWeight="bold"
          id="miscelaneous-3.3"
        >
          3.3. Divisibilidad
        </Text>
        <Text fontSize={20} lineHeight={24}>
          Si alguna de las disposiciones de estos Términos se
          considera inaplicable o no ejecutable, dicha disposición se
          separará de estos Términos y no afectará a la validez y
          aplicabilidad de las disposiciones restantes.
        </Text>
        <Text
          fontSize={20}
          lineHeight={24}
          fontWeight="bold"
          id="miscelaneous-3.4"
        >
          3.4. Condiciones finales
        </Text>
        <Text fontSize={20} lineHeight={24}>
          Estos Términos constituyen el acuerdo total entre usted y
          Feek y sustituyen cualquier acuerdo anterior. Estos Términos
          no crean ni confieren ningún derecho de beneficiario a
          terceros. Si no hacemos cumplir una disposición de estos
          Términos, no se considerará una renuncia. Nos reservamos el
          derecho de transferir nuestros derechos bajo estos Términos
          y proveer los Servicios usando otra entidad, siempre y
          cuando dicha entidad mantenga estos Términos. Usted no podrá
          transferir ninguno de sus derechos u obligaciones en virtud
          de estos Términos sin nuestro consentimiento. Nos reservamos
          todos los derechos que no se le hayan concedido
          expresamente.
        </Text>
        <Text
          fontSize={20}
          lineHeight={24}
          fontWeight="bold"
          id="miscelaneous-3.5"
        >
          3.5. Contacto
        </Text>
        <Text fontSize={20} lineHeight={24}>
          Frengers. Inc. agradece los comentarios, preguntas, dudas o
          sugerencias. Puede ponerse en contacto con nosotros o
          recibir asistencia enviando un correo electrónico a:{' '}
          <a href="mailto:support@feek.app">support@feek.app</a>
          <br />
          <br />
          La empresa responsable de los servicios es Frengers, Inc:
          651 N Broad Street, Suite 206, Middletown, DE 19709.
        </Text>
      </div>
    </div>
  );
}

export default TermsAndConditions;
