/** @jsxImportSource @emotion/react */
import { useState } from 'react';
import PropTypes from 'prop-types';
import { css } from '@emotion/react';

import Text from 'components/Text';
import { useFormikContext } from 'formik';
import Button from 'components/buttons/Button';
import { WHITE, BLUE_FEEK } from 'styles/colors';
import TextInput from 'components/inputs/TextInput';

const styles = css`
  display: flex;
  flex-direction: column;
  margin: 0;
  padding: 0;
  border: none;
  box-sizing: border-box;

  .title {
    margin: 0 0 10px 0;
    padding: 0;
    cursor: default;
  }

  .inputsListContainer {
    display: flex;
    flex-direction: column;
    row-gap: 6px;
  }

  .inputsWrapper {
    margin: 0;

    .error-text {
      display: none;
    }
  }

  .input {
    margin: 0;
    padding-inline: 1rem;
    height: 45px;
    border-radius: 8px;
    font-size: 14px;
  }

  .modifyInputsCountContainer {
    display: grid;
    grid-template-columns: 1fr 1fr;
    grid-template-areas: 'left right';
    justify-content: space-between;
    justify-items: start;
  }
  .inputsCountButton {
    user-select: none;
    color: ${BLUE_FEEK};
    border: none;
    background: none;
    margin: 0;
    padding: 0.6rem;
  }
  .inputsCountButton.right {
    grid-area: right;
    justify-self: end;
  }
  .inputsCountButton:is(:focus, :hover) {
    outline: 1px solid ${BLUE_FEEK};
    border-radius: 1em;
  }
  .inputsCountButton:active {
    background-color: ${BLUE_FEEK};
    color: ${WHITE};
    border-radius: 1em;
  }
`;

function ExpansibleInputList({
  title,
  onChange,
  value = [],
  className = '',
  maxLength = 70,
  maxInputsCount = 5,
  classNameInput = '',
  initialInputsCount = 2,
  classNameInputWrapper = '',
  ...restProps
}) {
  const [inputsCount, setInputsCount] = useState(
    Math.max(initialInputsCount, value?.length || 0),
  );
  const [isFocused, setIsFocused] = useState(false);

  const parsedValues = Array(inputsCount)
    .fill(null)
    .map((_, i) => value[i] || '');

  const handleBlur = (e) => {
    setIsFocused(false);
    restProps.onBlur?.(e);
  };

  const handleFocus = () => {
    if (!isFocused) {
      setIsFocused(true);
      restProps.onFocus?.();
    }
  };

  const handleChange = (text, index) => {
    onChange?.(
      parsedValues.map((item, i) => (index === i ? text : item)),
    );
  };

  const addInputs = () => {
    handleFocus();
    setInputsCount((crr) => crr + 1);
  };

  const subtractInputs = () => {
    onChange?.(parsedValues.slice(0, parsedValues.length - 1));
    setInputsCount((crr) => crr - 1);
  };

  return (
    <div role="group" css={styles} className={className}>
      {title &&
        (typeof title === 'string' ? (
          <Text className="title" fontSize={16} fontWeight={500}>
            {title}
          </Text>
        ) : (
          title
        ))}
      <div className="inputsListContainer">
        {Array(inputsCount)
          .fill(undefined)
          .map((_, i) => (
            <TextInput
              {...restProps}
              key={i}
              onBlur={handleBlur}
              onFocus={handleFocus}
              maxLength={maxLength}
              value={value[i] || ''}
              inputClassName={`input ${classNameInput}`}
              autoFocus={isFocused && i === inputsCount - 1}
              onChange={(e) => handleChange(e.target.value, i)}
              className={`inputsWrapper ${classNameInputWrapper}`}
            />
          ))}
        <div className="modifyInputsCountContainer">
          {inputsCount < maxInputsCount && (
            <Button onClick={addInputs} className="inputsCountButton">
              + Agregar otra
            </Button>
          )}
          {inputsCount > initialInputsCount && (
            <Button
              onClick={subtractInputs}
              className={'inputsCountButton right'}
            >
              - Eliminar otra
            </Button>
          )}
        </div>
      </div>
    </div>
  );
}

export const FormExpansibleInputList = (props) => {
  const { name, ...restProps } = props;
  const { getFieldMeta, handleBlur, setFieldValue } =
    useFormikContext();
  const { value, error, touched } = getFieldMeta(name);

  return (
    <ExpansibleInputList
      value={value}
      {...restProps}
      onBlur={handleBlur(name)}
      error={touched && !!error && error}
      onChange={(newValue) => setFieldValue(name, newValue)}
    />
  );
};

FormExpansibleInputList.propTypes = {
  name: PropTypes.string,
};

ExpansibleInputList.propTypes = {
  value: PropTypes.arrayOf(PropTypes.string),
  title: PropTypes.oneOfType([PropTypes.string, PropTypes.element]),
  className: PropTypes.string,
  classNameInput: PropTypes.string,
  classNameInputWrapper: PropTypes.string,
  initialInputsCount: PropTypes.number,
  maxInputsCount: PropTypes.number,
  maxLength: PropTypes.number,
  onChange: PropTypes.func,
};

export default ExpansibleInputList;
