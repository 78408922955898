/** @jsxImportSource @emotion/react */
import { css } from '@emotion/react';
import { useEffect, useState } from 'react';
import { Navigate } from 'react-router-dom';
import { useDispatch, useSelector } from 'react-redux';

import APIClient from 'redux/api';
import { ROUTES } from 'constants';
import { setRoles } from 'redux/entities';
import {
  CollaboratorList,
  CollaborationCreationSuccess,
  CollaborationCreationSelection,
} from 'screens/Collaborators/components';

const styles = css`
  box-sizing: border-box;
  display: flex;
  flex-direction: row;
  width: 100%;
  flex: 1;
`;

function Collaborators() {
  const dispatch = useDispatch();
  const [index, setIndex] = useState(0);
  const selectedCustomerId = useSelector(
    (state) => state.app.selectedCustomerId,
  );
  const customers = useSelector((state) => state.entities.customers);
  const selectedCustomer = customers[selectedCustomerId]?.customer;

  useEffect(() => {
    APIClient.getRoles()
      .then(({ data }) => dispatch(setRoles(data)))
      .catch((e) => console.error(e));
  }, []);

  useEffect(() => {
    setIndex(0);
  }, [selectedCustomerId]);

  const steps = [
    CollaboratorList,
    CollaborationCreationSelection,
    CollaborationCreationSuccess,
  ];

  const CurrentStep = steps[index];

  const handleIndex = (i) => {
    setIndex(i);
  };

  return customers[selectedCustomerId]?.role === 'customerOwner' ||
    customers[selectedCustomerId]?.role === 'customerAdmin' ? (
    <div css={styles}>
      <CurrentStep
        handleIndex={handleIndex}
        collaborators={selectedCustomer?.collaborators || []}
        collaboratorsTotalPlaces={
          selectedCustomer?.collaborators_total_places || 0
        }
      />
    </div>
  ) : (
    <Navigate to={ROUTES.DASHBOARD} replace />
  );
}
export default Collaborators;
