/** @jsxImportSource @emotion/react */
import PropTypes from 'prop-types';
import { css } from '@emotion/react';
import { createPortal } from 'react-dom';
import Button from 'components/buttons/Button';
import { MEDIA_QUERIES } from 'styles/constants';
import { BLACK, GRADIENT_PURPLE, WHITE } from 'styles/colors';

const styles = css`
  display: flex;
  justify-content: center;
  align-items: center;
  width: 100vw;
  height: 100vh;
  position: fixed;
  top: 0;
  left: 0;
  z-index: 100;
  background: ${BLACK}99;

  &,
  & :where(*) {
    box-sizing: border-box;
  }

  .modalMain {
    cursor: initial;
    width: auto;
    max-width: calc(100vw - 80px);
    height: auto;
    max-height: calc(100vh - 80px);
    margin: 40px;
    border-radius: 16px;
    background: ${WHITE};
    overflow: auto;
    scrollbar-width: thin;

    ::-webkit-scrollbar {
      width: 6px;
      height: 6px;
    }
    ::-webkit-scrollbar-thumb {
      background: ${GRADIENT_PURPLE};
      border-radius: 8px;
    }
    ::-webkit-scrollbar-track {
      background: transparent;
    }
  }

  .modalMain.overflow {
    overflow: unset;
  }
`;

function Modal({
  children,
  visible,
  onRequestClose,
  outsideOutlet = false,
  overlayProps = {},
  className,
  ...restProps
}) {
  const wrapperEl = document.getElementById('modal');

  const mobileStyles = css`
    ${MEDIA_QUERIES.mobile} {
      display: block;
      z-index: 45;
      padding-block-start: ${outsideOutlet ? '0' : '62px'};

      .modalMain {
        display: flex;
        max-width: none;
        max-height: none;
        justify-content: center;
        align-items: flex-start;
        height: ${outsideOutlet ? '100vh' : 'calc(100vh - 62px)'};
        width: 100vw;
        margin: 0;
        padding: 0;
        border-radius: 0;

        > :where(div:first-of-type:last-of-type) {
          height: auto;
          min-height: 100%;
          min-width: auto;
          width: 100%;
          padding: 40px 24px;
        }
      }
    }
  `;

  const { onClick, ...restOverlayProps } = overlayProps;

  const handleOverlayClick = (e) => {
    if (e.target === e.currentTarget) {
      if (onClick) onClick();
      if (onRequestClose) onRequestClose();
    }
  };

  return visible
    ? createPortal(
        <Button
          {...restOverlayProps}
          css={[styles, mobileStyles]}
          onClick={handleOverlayClick}
        >
          <section
            className={`modalMain${className ? ` ${className}` : ''}`}
            {...restProps}
          >
            {children}
          </section>
        </Button>,
        wrapperEl,
      )
    : null;
}

export default Modal;

Modal.propTypes = {
  children: PropTypes.oneOfType([
    PropTypes.arrayOf(PropTypes.element),
    PropTypes.element,
  ]),
  visible: PropTypes.bool,
  onRequestClose: PropTypes.func,
  outsideOutlet: PropTypes.bool,
  overlayProps: PropTypes.object,
  className: PropTypes.string,
};
