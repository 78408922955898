import ROUTES from 'constants/routes';

const rolesSelectValues = [
  {
    label: 'Administrador',
    value: 'customerAdmin',
  },
  {
    label: 'Supervisor',
    value: 'customerSupervisor',
  },
  {
    label: 'Espectador',
    value: 'customerViewer',
  },
];

const hoursSelectValues = [
  { value: '0:00', label: '0:00' },
  { value: '1:00', label: '1:00' },
  { value: '2:00', label: '2:00' },
  { value: '3:00', label: '3:00' },
  { value: '4:00', label: '4:00' },
  { value: '5:00', label: '5:00' },
  { value: '6:00', label: '6:00' },
  { value: '7:00', label: '7:00' },
  { value: '8:00', label: '8:00' },
  { value: '9:00', label: '9:00' },
  { value: '10:00', label: '10:00' },
  { value: '11:00', label: '11:00' },
  { value: '12:00', label: '12:00' },
  { value: '13:00', label: '13:00' },
  { value: '14:00', label: '14:00' },
  { value: '15:00', label: '15:00' },
  { value: '16:00', label: '16:00' },
  { value: '17:00', label: '17:00' },
  { value: '18:00', label: '18:00' },
  { value: '19:00', label: '19:00' },
  { value: '20:00', label: '20:00' },
  { value: '21:00', label: '21:00' },
  { value: '22:00', label: '22:00' },
  { value: '23:00', label: '23:00' },
];

const roles = {
  customerOwner: 'Propietario',
  customerAdmin: 'Administrador',
  customerSupervisor: 'Supervisor',
  customerViewer: 'Espectador',
};

const weekDays = {
  monday: 'Lunes',
  tuesday: 'Martes',
  wednesday: 'Miércoles',
  thursday: 'Jueves',
  friday: 'Viernes',
  saturday: 'Sábado',
  sunday: 'Domingo',
};

const campaignStatusAbbreviation = {
  FULL_ACCOUNT: 'Cuenta completa',
  DISCOUNT_PERCENTAGE: 'Porcentaje',
  MONETARY_DISCOUNT: 'Descuento',
  GIFT: 'Regalo',
};

export {
  roles,
  ROUTES,
  weekDays,
  rolesSelectValues,
  hoursSelectValues,
  campaignStatusAbbreviation,
};
