import moment from 'moment';

import { ReactComponent as Gift } from 'assets/icons/Gift.svg';
import { ReactComponent as MagicWand } from 'assets/icons/MagicWand.svg';
import { ReactComponent as ArrowDiscount } from 'assets/icons/ArrowDiscount.svg';
import { ReactComponent as ArrowPercentage } from 'assets/icons/ArrowPercentage.svg';

export const constants = {
  daysOfTheWeek: [
    'Lunes',
    'Martes',
    'Miércoles',
    'Jueves',
    'Viernes',
    'Sabado',
    'Domingo',
  ],
  statusOfCampaignOptions: [
    {
      label: 'Todas',
      value: 'all',
    },
    {
      label: 'Completadas',
      value: 'completed',
    },
    {
      label: 'En curso',
      value: 'in_progress',
    },
  ],
  datesOptions: [
    {
      label: 'Últimos 7 días',
      value: [
        moment(new Date()).subtract(7, 'days').toDate(),
        new Date(),
      ],
    },
    {
      label: 'Últimos 30 días',
      value: [
        moment(new Date()).subtract(30, 'days').toDate(),
        new Date(),
      ],
    },
    {
      label: 'Últimos 90 días',
      value: [
        moment(new Date()).subtract(90, 'days').toDate(),
        new Date(),
      ],
    },
  ],
  campaignTypeIcons: {
    '61b577fa1e87dc21e2b19cfa': Gift,
    '61b5780c1e87dc21e2b19d00': MagicWand,
    '61b577ff1e87dc21e2b19cfc': ArrowDiscount,
    '61b578061e87dc21e2b19cfe': ArrowPercentage,
  },
};
