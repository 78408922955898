import React from 'react';
import PropTypes from 'prop-types';
import { Navigate } from 'react-router-dom';

import { ROUTES } from 'constants';

function PrivateRoute({ children }) {
  const token = localStorage.getItem('token');

  const redirect = <Navigate to={ROUTES.LOGIN} replace />;

  if (!token) {
    return redirect;
  }

  return children ? children : redirect;
}

function PublicRoute({ children }) {
  const token = localStorage.getItem('token');

  const redirect = <Navigate to={ROUTES.HOME} replace />;

  if (token) {
    return redirect;
  }

  return children ? children : redirect;
}

export { PublicRoute, PrivateRoute };

PrivateRoute.propTypes = {
  children: PropTypes.node,
};

PublicRoute.propTypes = {
  children: PropTypes.node,
};
