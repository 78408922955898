/** @jsxImportSource @emotion/react */
import { useState } from 'react';
import PropTypes from 'prop-types';
import { css } from '@emotion/react';

import {
  RED,
  WHITE,
  GRAY_LIGHT,
  PURPLE_FEEK,
  GRAY_DEFAULT,
  GRAY_LIGHTER,
  GRAY_LIGHTEST_PLUS,
  PURPLE_INACTIVE_LIGHT,
} from 'styles/colors';
import Text from 'components/Text';
import Icon from 'components/Icon';
import Avatar from 'components/Avatar';
import DropDown from 'components/DropDown';
import Button from 'components/buttons/Button';
import { countNotCanceledMoments } from 'utils/campaigns';
import LinearProgressBar from 'components/LinearProgressBar';
import CampaignConstrains from 'screens/Campaigns/CampaignConstrains';
import { ReactComponent as ArrowDown } from 'assets/icons/ArrowDown.svg';
import CampaignBookingOverviewModal from 'screens/Campaigns/CampaignBookingOverviewModal';

const stylesDropDown = css`
  .content {
    width: max-content;
    background-color: ${GRAY_LIGHTEST_PLUS};
    border: 1px solid ${GRAY_DEFAULT};
    box-shadow: none;
    padding: 5px 0;

    .button {
      padding: 5px 15px;

      &:hover {
        background-color: ${GRAY_LIGHTER};
      }
    }
  }
`;

const styles = css`
  .bottomCampaignCardContainer {
    display: flex;
    flex-direction: column;
    align-items: center;
    gap: 20px;
    padding: 20px;
    width: 100%;

    .campaignFeekersContainer {
      display: flex;
      flex-direction: row;
      align-items: center;
      width: 100%;

      .campaignFeekersImgs {
        display: flex;
        flex-direction: row-reverse;
        width: fit-content;
        margin-inline: 15px;

        .avatarFeeker {
          border: 1px solid ${GRAY_LIGHTER};
          margin-left: -15px;
        }
      }

      .campaignFeekersLoad {
        width: 100%;

        .placesWrapper {
          display: flex;
          justify-content: space-between;
          flex-wrap: wrap;
          column-gap: 5px;
          margin-top: 8px;
        }
      }
    }

    .validateInfoContainer {
      .validateInfoTitle {
        position: relative;
        margin-inline: auto;
        margin-block-end: -10px;
        z-index: 1;
        padding: 10px 20px;
        border-radius: 15px;
        border: 1px solid ${GRAY_LIGHT};
        background-color: ${PURPLE_INACTIVE_LIGHT};
        width: fit-content;
      }

      .validateInfoText {
        padding: 20px;
        border-radius: 12px;
        border: 1px solid ${GRAY_LIGHTER};
        background-color: ${WHITE};
      }
    }

    .feekerNone {
      display: flex;
      align-items: center;
      min-height: 40px;
    }
  }
`;

function CampaignCard({
  campaign,
  handleEditCampaign,
  handleDeleteCampaign,
  showValidateInfo,
  handleCloneAndEditCampaign,
}) {
  const [isDropdownOpen, setIsDropdownOpen] = useState(false);
  const [
    isDeleteConfirmationVisible,
    setIsDeleteConfirmationVisible,
  ] = useState(false);
  const [isCampaignBookingModalOpen, setIsCampaignBookingModalOpen] =
    useState(false);

  const handleIsCampaignBookingModalToggle = () => {
    setIsCampaignBookingModalOpen((crr) => !crr);
  };

  const notCanceledMomentsCount = countNotCanceledMoments(
    campaign.moments,
  );

  return (
    <div css={styles} className="campaignCardContainer">
      {isDeleteConfirmationVisible && (
        <>
          <Button
            className="deleteWarningContainerBackground"
            onClick={() => setIsDeleteConfirmationVisible(false)}
          />
          <div className="deleteWarningContainer"></div>
          <Button
            className="confirmDeleteCampaignButton"
            onClick={() => {
              handleDeleteCampaign(campaign._id);
              setIsDeleteConfirmationVisible(false);
            }}
          >
            Eliminar campaña
          </Button>
        </>
      )}

      <CampaignBookingOverviewModal
        moments={campaign.moments}
        isOpen={isCampaignBookingModalOpen}
        campaignName={campaign.title}
        campaignStatus={campaign.status}
        campaignEndTime={campaign.end_time}
        campaignTypeIcon={campaign.type.icon}
        campaignTypeName={campaign.type.name}
        campaignStartDate={campaign.start_date}
        campaignStartTime={campaign.start_time}
        handleClose={handleIsCampaignBookingModalToggle}
      />

      <div className="topCampaignCardContainer">
        <div className="statusAndDropDownContainer">
          <Text
            fontSize={12}
            fontWeight="500"
            color={
              campaign.status === 'Completada' ? WHITE : PURPLE_FEEK
            }
            className={
              'statusCard' +
              (campaign.status === 'Completada' ? ' completed' : '')
            }
          >
            {campaign.status}
          </Text>

          <DropDown
            isOpen={isDropdownOpen}
            onOpen={() => setIsDropdownOpen(true)}
            onClose={() => setIsDropdownOpen(false)}
            icon={
              window.innerWidth > 520 ? (
                '•••'
              ) : (
                <Icon
                  icon={ArrowDown}
                  color={PURPLE_FEEK}
                  size={12}
                />
              )
            }
            alignment="right"
            className="dropDown"
            childrenWrapperCSS={stylesDropDown}
          >
            <Button
              disabled={
                notCanceledMomentsCount >= campaign.total_places
              }
              onClick={() => handleEditCampaign(campaign._id)}
              className="button"
            >
              <Text fontSize={14} lineHeight={16.5}>
                Editar campaña
              </Text>
            </Button>
            <Button
              onClick={() =>
                handleCloneAndEditCampaign(campaign._id, 3)
              }
              className="button"
            >
              <Text fontSize={14} lineHeight={16.5}>
                Clonar campaña
              </Text>
            </Button>
            <Button
              onClick={() => handleCloneAndEditCampaign(campaign._id)}
              className="button"
            >
              <Text fontSize={14} lineHeight={16.5}>
                Clonar y editar campaña
              </Text>
            </Button>
            <Button
              disabled={campaign.moments?.length > 0}
              onClick={() => {
                setIsDropdownOpen(false);
                setIsDeleteConfirmationVisible(true);
              }}
              className="button"
            >
              <Text fontSize={14} lineHeight={16.5} color={RED}>
                Eliminar campaña
              </Text>
            </Button>
          </DropDown>
        </div>

        <Text
          className="campaignTitle"
          fontSize={24}
          fontWeight="600"
          lineHeight={28.8}
        >
          {campaign.title}
        </Text>
        <CampaignConstrains
          endTime={campaign.end_time}
          typeIcon={campaign.type.icon}
          typeName={campaign.type.name}
          startDate={campaign.start_date}
          startTime={campaign.start_time}
        />
      </div>
      <div className="bottomCampaignCardContainer">
        {campaign.moments?.length > 0 ? (
          <div className="campaignFeekersContainer">
            <div className="campaignFeekersImgs hideOnMobile">
              {campaign.moments
                ?.slice(0, 3)
                .map(({ user }, index) => {
                  return (
                    user.profile?._id && (
                      <Avatar
                        key={index}
                        image={user.profile?.instagram.avatar}
                        size={40}
                        className="avatarFeeker"
                      />
                    )
                  );
                })}
            </div>

            <div className="campaignFeekersLoad">
              <LinearProgressBar
                progress={
                  (notCanceledMomentsCount / campaign?.total_places) *
                  100
                }
                backgroundColor={GRAY_LIGHT}
              />

              <div className="placesWrapper">
                <Text fontSize={12} lineHeight={14}>
                  {notCanceledMomentsCount} de {campaign.total_places}{' '}
                  Feekers ya agendaron
                </Text>

                <Button onClick={handleIsCampaignBookingModalToggle}>
                  <Text
                    color={PURPLE_FEEK}
                    fontSize={12}
                    fontWeight="600"
                    lineHeight={14}
                  >
                    Ver detalles
                  </Text>
                </Button>
              </div>
            </div>
          </div>
        ) : (
          <Text align="center" fontSize={14} className="feekerNone">
            Aún no han aplicado a tu campaña
          </Text>
        )}

        {showValidateInfo && (
          <div className="validateInfoContainer">
            <Text
              align="center"
              fontSize={14}
              fontWeight="600"
              className="validateInfoTitle"
            >
              ¡Nuevo método de validación!
            </Text>

            <Text
              align="center"
              fontSize={14}
              className="validateInfoText"
            >
              El cliente <b>mostrará el cupón</b> y validará la
              promoción tomando una <b>foto al ticket final.</b>
            </Text>
          </div>
        )}
      </div>
    </div>
  );
}

CampaignCard.propTypes = {
  handleClick: PropTypes.func,
  campaign: PropTypes.object,
  handleEditCampaign: PropTypes.func,
  handleCloneAndEditCampaign: PropTypes.func,
  handleDeleteCampaign: PropTypes.func,
  showValidateInfo: PropTypes.bool,
};

export default CampaignCard;
