import { useParams } from 'react-router-dom';

async function Share() {
  const { router, param } = useParams();
  const sleep = (delay) =>
    new Promise((resolve) => setTimeout(resolve, delay));

  window.location = `feekapp://${router}${
    param !== undefined ? '/' + param : ''
  }`;
  await sleep(1000);
  window.location = 'https://qrco.de/be57p1';
  await sleep(2000);
  window.location = 'https://www.feek.app';

  return <div>Feek redirect...</div>;
}

export default Share;
