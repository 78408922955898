/** @jsxImportSource @emotion/react */
import { useState } from 'react';
import PropTypes from 'prop-types';
import { css } from '@emotion/react';

import {
  GRAY,
  GREEN_LIGHT,
  YELLOW_LIGHT,
  GRAY_LIGHTER,
  GRAY_LIGHTEST,
} from 'styles/colors';
import Icon from 'components/Icon';
import Text from 'components/Text';
import { ReactComponent as ArrowDown } from 'assets/icons/ArrowDown.svg';
import { ReactComponent as WarningCircle } from 'assets/icons/WarningCircle.svg';
import { ReactComponent as CheckCircleDouble } from 'assets/icons/CheckCircleDouble.svg';

import Button from 'components/buttons/Button';

const AccordionStatus = ({ title, content, status, className }) => {
  const [isActive, setIsActive] = useState(false);

  const styles = css`
    display: grid;
    grid-template-columns: ${status !== undefined
      ? 'auto 1fr auto'
      : '1fr auto'};
    grid-template-rows: 45px;
    grid-template-areas: ${isActive
      ? `"${status !== undefined ? 'statusIcon ' : ''}title chevron"
      "${status !== undefined ? '. content ."' : 'content content"'}`
      : `"${
          status !== undefined ? 'statusIcon ' : ''
        }title chevron"`};
    justify-items: start;
    align-items: center;
    column-gap: 12px;
    background: ${GRAY_LIGHTEST};
    border-radius: 8px;
    border: 1px ${GRAY_LIGHTER} solid;
    padding: 0 13px;
    box-sizing: border-box;
    position: relative;

    & :where(*) {
      box-sizing: border-box;
    }

    .summary {
      display: contents;
    }

    .summaryTouch {
      position: absolute;
      height: ${isActive ? '45px' : '100%'};
      width: 100%;
      top: 0;
    }

    .statusIcon {
      grid-area: statusIcon;
      justify-self: self-start;
    }

    .title {
      grid-area: title;
      font-size: 14px;
      color: ${GRAY};
      justify-self: self-start;
    }

    .chevron {
      grid-area: chevron;
      transform: ${isActive ? 'rotate(-180deg)' : 'rotate(0deg)'};
      transition: transform ease-in-out 0.2s;
      justify-self: self-end;
    }

    .content {
      grid-area: content;
      padding-block-end: 13px;
    }
  `;

  return (
    <div css={styles} className={className}>
      <Button
        className="summary"
        onClick={() => setIsActive(!isActive)}
      >
        <Button
          className="summaryTouch"
          onClick={() => setIsActive(!isActive)}
        ></Button>
        {status !== undefined ? (
          status ? (
            <Icon
              className="statusIcon"
              icon={CheckCircleDouble}
              color={GREEN_LIGHT}
              size={32}
            />
          ) : (
            <Icon
              className="statusIcon"
              icon={WarningCircle}
              color={YELLOW_LIGHT}
              size={32}
            />
          )
        ) : null}

        <Text className="title" fontSize={14}>
          {title}
        </Text>

        <Icon className="chevron" icon={ArrowDown} size={12} />
      </Button>

      {isActive && <div className="content">{content}</div>}
    </div>
  );
};

AccordionStatus.propTypes = {
  title: PropTypes.string,
  content: PropTypes.node,
  status: PropTypes.bool,
  className: PropTypes.string,
};
export default AccordionStatus;
